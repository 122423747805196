
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserRegistrationService } from '../user-registration/user-registration.service';



@Injectable({ providedIn: 'root' })
export class PatientAuthGurad implements CanActivate {

  constructor(private router: Router, private login: UserRegistrationService) { }

  canActivate() {

    if (this.login.isPatient ) { 
      return true;
    
    }

    this.router.navigate(['/login']);
    return false;
  }
}
