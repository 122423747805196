import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PatientListService {

  baseUrl: any;
  constructor(private http: HttpClient) { 
    this.baseUrl = environment.url;
  }
  getPatientList(){
    return this.http.get(this.baseUrl+'api/Doctor/get_patient_list');
  }
  loadDocuments(userID,dependentID){
    return this.http.get(this.baseUrl+'api/Doctor/get_document_details?UserID='+userID+'&DependentID='+dependentID+'');
  }
  getDicomListByUserID(UserID){

    return this.http.get(this.baseUrl+'api/Doctor/get_dicom_by_userid?UserID='+UserID);
  }
  getQuestionnaireReportByUserID(UserID){
 
    return this.http.get(this.baseUrl+'api/Doctor/get_questionnaire_report?UserID='+UserID);
  }
}
