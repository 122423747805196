import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TodayAppointmentService } from './today-appointment.service';
import { HttpClient } from '@angular/common/http';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { TodayAppointmentDetailsComponent } from '../today-appointment-details/today-appointment-details.component';
import {MatDialog} from '@angular/material/dialog';
import { CalendarOptions } from '@fullcalendar/angular';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-today-appointment',
  templateUrl: './today-appointment.component.html',
  styleUrls: ['./today-appointment.component.css']
})
export class TodayAppointmentComponent implements OnInit {

  appointmentList:any[]=[];
  doctorNumber:any;
  communicationList:any[]=[];
  loading:boolean=false;

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridDay',
    dateClick: this.handleDateClick.bind(this), // bind is important!
    eventClick:this.handleEventClick.bind(this),
    selectable: true,
    headerToolbar:{
      left:'prev,next today',
      right:'dayGridDay,listweek'
    },
    displayEventTime:true
  };
  calendarOptionList:calendarOptionList[]=[];

  constructor(private service: TodayAppointmentService,private http: HttpClient,private _bottomSheet: MatBottomSheet,public dialog: MatDialog) { }
  

  ngOnInit() {
    this.GetAppsettings(); 
    this.getCommunicationTypes();
    this.getTodayAppointments(0);
  }
  openBottomSheet(patientID): void {

    // this._bottomSheet.open(TodayAppointmentDetailsComponent,{
    //   data:patientID,
    // });

    const dialogRef = this.dialog.open(TodayAppointmentDetailsComponent, {
      width: '900px',
      disableClose: false,
              data:patientID
    });

    dialogRef.afterClosed().subscribe(result => {
     
      
    });
  }

  getTodayAppointments(communicationTypeID){
    this.loading=true;
    this.appointmentList=[];
    this.calendarOptionList=[];
    this.service.getTodayAppointments(communicationTypeID).subscribe((res:any)=>{
      this.appointmentList.push(...res.list);
      this.appointmentList.forEach(element => {
        element.textToshare='Name : '+element.firstName +' ' +element.middleName+ ' '+element.lastName + '. Click https://wa.me/'+element.mobileNumber+'/?text=Hi '+element.firstName +' ' +element.middleName+ ' '+element.lastName;

        this.calendarOptionList.push({title:' From :'+this.FormatTime(element.appointmentFrom)+ ' To :'+ this.FormatTime(element.appointmentTo) +' - '+ element.firstName+ '  (Patient ID) :'+element.patientID ,
        date:this.FormatDate(element.appointmentFrom)  ,color: '#1da2b2'});


      });

      this.calendarOptions.events =  this.calendarOptionList;

      this.loading=false;
    });
  }
  GetAppsettings(){
    this.service.getAppsettings().subscribe((res:any)=>{

      this.doctorNumber=res.data.whatsAppNumber;

    });

  }
  getCommunicationTypes(){

    this.communicationList=[];
    this.service.getCommunicationTypes().subscribe((res:any)=>{

      res.communicationTypeList.forEach(element => {
        if(element.is_Active){
          this.communicationList.push(element);
        }
      });

     
    });

  }
  goToWhatsApp(textToshare){
    window.open('https://api.whatsapp.com/send?phone=doctorNumber&text='+textToshare+'', '_blank')
  }

  handleDateClick(arg) {

   
  }
  handleEventClick(info){

  }
  FormatDate(value: string) {
    var datePipe = new DatePipe("en-US");
    value = datePipe.transform(value, 'yyyy-MM-dd');
    return value;
 }
 FormatTime(value: string) {
  var datePipe = new DatePipe("en-US");
  value = datePipe.transform(value, 'hh:mm a');
  return value;
}
}
export class CommunicationTypeModel{
  communicationTypeID:number;

}   

export interface calendarOptionList{

  title:string;
  date:string;
  color:string;
}
