import { Component, OnInit,ViewChild } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { FormGroup, FormBuilder, Validators,FormGroupDirective } from '@angular/forms';
import { AddDependentService } from './add-dependent.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-dependent',
  templateUrl: './add-dependent.component.html',
  styleUrls: ['./add-dependent.component.css']
})
export class AddDependentComponent implements OnInit {


 

  DependentForm: FormGroup;
  DocumentForm:FormGroup;

  ngForm: FormGroupDirective;

  files: File[] = [];
  filesToSubmit:string[];
  DocumentAccess:any;
  UploadFlag:any;
  mySelfProgress:number;
  loading:any;
  UserID:any;
  constructor(private formBuilder: FormBuilder,private service:AddDependentService,
        private router: Router,private toastr: ToastrService,public _snackBar:MatSnackBar) { 

  }

  ngOnInit() {
   
    this.UserID=localStorage.getItem('UserID');
    this.DocumentAccess=true;
    this.UploadFlag=false;
    this.files=[];
    this.filesToSubmit=[];
    this.mySelfProgress=0;

    this.DependentForm = this.formBuilder.group({
      UserID:[this.UserID],
      Relationship: ['', Validators.required],
      FirstName: ['', Validators.required],
      LastName: [''],
      MiddleName:[''],
      Age:[''],
      Gender:['M',Validators.required],
      UploadFlag:[]
    });
   
    this.DocumentForm = this.formBuilder.group({
      UserID:[this.UserID],
      DocumentName: ['', Validators.required],
      ReportDate: ['', Validators.required],
      DocumentAccess: [true],
      ValidFrom:['',Validators.required],
      ValidTo:['',Validators.required],
    });
  }
 
  
  onDropzoneSelect(event) {
    this.filesToSubmit=[];

    this.files.push(...event.addedFiles);
    
    this.uploadFileToServer();
  }
   
  onDropzoneRemove(event) {
      this.filesToSubmit=[];
      
      this.files.splice(this.files.indexOf(event), 1);
      this.uploadFileToServer();
  }
  uploadFileToServer(){

    const formData = new FormData(); 
    for(var i=0;i<this.files.length;i++){
      formData.append("file[]",this.files[i]);
    }
    
    this.mySelfProgress=50;
    formData.append('UserID',this.UserID);  
    
      this.service.UploadDocuments(formData).subscribe(
        (response:any) => {                           //Next callback
         
          console.log('File upload status '+response.message);
          if (response !== null) {
           
            if(response.status==1){
              this.mySelfProgress=80;
              for(var i=0;i<response.documentPath.length;i++){
                this.filesToSubmit.push(response.documentPath[i])
              }
              this.mySelfProgress=0;
            }
          }
        },
        (error) => {                              //Error callback
          
          
        }
    );
    
  
  }
  FormatDate(value: string) {
    var datePipe = new DatePipe("en-US");
     value = datePipe.transform(value, 'dd/MM/yyyy');
     return value;
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  DependentSubmit(form:FormGroup,formDirective: FormGroupDirective){

    const body = {
      UserID:form.value.UserID,
      Relationship: form.value.Relationship,
      FirstName: form.value.FirstName,
      LastName: form.value.LastName,
      MiddleName: form.value.MiddleName,
      Age: form.value.Age,
      Gender: form.value.Gender,
    };
    
  
    if(body.Relationship==''||body.Relationship==null||body.FirstName==''||body.FirstName==null){
     
      return;
    }
 
      if(form.value.UploadFlag==true){

        this.DocumentForm.get('DocumentName').markAsTouched();
        this.DocumentForm.get('ReportDate').markAsTouched();
  
        if(this.DocumentForm.get('DocumentName').value==''|| this.DocumentForm.get('DocumentName').value==null){
  
          this.openSnackBar('Please Enter Document Name','Required');
          // this.toastr.error('Please enter DocumentName');
          return;
        }
        if(this.DocumentForm.get('ReportDate').value==''|| this.DocumentForm.get('ReportDate').value==null){
          this.openSnackBar('Please Enter Report Date','Required');
          // this.toastr.error( 'Please enter ReportDate');
          return;
        }
        if(!this.DocumentAccess){
          
          this.DocumentForm.get('ValidFrom').markAsTouched();
          this.DocumentForm.get('ValidTo').markAsTouched();
          if(this.DocumentForm.get('ValidFrom').value==''||this.DocumentForm.get('ValidFrom').value==null){
            this.openSnackBar('Please Enter From Date','Required');
            // this.toastr.error( 'Please enter From Date');
            return;
          }
          else if(this.DocumentForm.get('ValidTo').value==''||this.DocumentForm.get('ValidTo').value==null){
            this.openSnackBar('Please Enter To Date','Required');
            // this.toastr.error( 'Please enter To Date');
            return;
          }
        }
        if(this.files.length>0){
          this.uploadFileToServer();
        }
      }
  
     
      this.loading=true;
      this.service.AddDependent(body).subscribe(
        (response:any) => {                           //Next callback
         
         
          if (response !== null) {

            if(response.status==1){

              if(form.value.UploadFlag==true){

                
                this.SaveDependentDocuments(response.dependentID,formDirective);
              }
              else{
                this.loading=false;

                formDirective.resetForm();
                this.openSnackBar(response.message,'');
                // this.toastr.success(response.message);



              }
            }
          }
          
        },
        (error) => {                              //Error callback
          
          
        }
      )

   
   

  }
  SaveDependentDocuments(DependentFK,formDirective: FormGroupDirective){

  
    const body={
      UserID:this.UserID,
      UserType:'Dependent',
      DependentFK:DependentFK,
      DocumentName: this.DocumentForm.get('DocumentName').value,
      ReportDate: this.FormatDate(this.DocumentForm.get('ReportDate').value),
      DocumentAccess:(this.DocumentAccess==true)? 'A':'C',
      ValidFrom:(this.DocumentForm.get('ValidFrom').value=="")?"": this.FormatDate(this.DocumentForm.get('ValidFrom').value),
      ValidTo:(this.DocumentForm.get('ValidTo').value=="")?"":this.FormatDate(this.DocumentForm.get('ValidTo').value),
      documentPath:(this.filesToSubmit!=null)?this.filesToSubmit:[],
     } ;

      this.service.SaveUserDocuments(body).subscribe((resp: any) => {
        
        
        if(resp!=null){
          
          if(resp.status==1){
            // this.toastr.success("New Dependent successfully added");
            this.openSnackBar("New Dependent successfully added",'');
            formDirective.resetForm();
          }
        }
        this.loading=false;
        setTimeout(() => {
          
        }, 1000);
        
      }, error => {
        this.loading=false;
      });

  
    // this.dialogRef.close();
  }

  clearForm(){
    
    this.DependentForm.reset();
  }
}
