import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {

  baseUrl: any;
  constructor(private http: HttpClient) { 
    this.baseUrl = environment.url;
  }

  // LoadQuestionnaire(id) {
  //   return this.http.get('http://94-237-73-65.sg-sin1.upcloud.host:82/WebView/GetQuestionnaires?WorkOrderCategoryId='+id+' &lang=en');
  // }

  LoadQuestionnaire(id,sectionNumber, UserID, PatientID) {
    return this.http.get(this.baseUrl + 'api/Questionnaire/getQuestions?FormID='+id +'&SectionNumber='+sectionNumber+ '&UserID=' + UserID + '&PatientID=' + PatientID);
  }
  UploadDocuments(data:any){

    return this.http.post(this.baseUrl+'api/DocumentUpload/upload_document',data).pipe(
      catchError(errorRes=>{
        const errorMessage='An Error Occured';
        if (!errorRes.error || !errorRes.error.error) {
          
          return throwError(errorMessage);

        }
      })
    )
  }
  SaveQuestionnaire(data: any) {

    return this.http.post(this.baseUrl+'api/Questionnaire/saveQuestionnaire',data).pipe(
      catchError(errorRes=>{
        
        const errorMessage='An Error Occured';
        if (!errorRes.error || !errorRes.error.error) {
          
          return throwError(errorMessage);

        }
      }),
    );
   
  }
  getLastSavedQuestionSection(FormID, UserID, PatientID) {
    return this.http.get(this.baseUrl + 'api/Questionnaire/get_last_saved_section?FormID=' + FormID + '&UserID=' + UserID + '&PatientID=' + PatientID);
  }
  GetUserProfile(id) {
    return this.http.get(this.baseUrl + `api/user/get_user_profile?UserID=`+id);
  }
}
