import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders ,HttpParams} from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import {of,throwError} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AddTimeslotedService {

  baseUrl: any;
  constructor(private http: HttpClient) { 
    this.baseUrl = environment.url;
  }
  getCommunicationTypes(){
    return this.http.get(this.baseUrl+'api/Patient/get_communication_types?Is_Active=false');
  }
  addTimeSlote(data:any){

    return this.http.post(this.baseUrl+'api/Booking/add_timeslotes',data).pipe(catchError(errorRes=>{

      const errorMessage='An Error Occured';
      if (!errorRes.error || !errorRes.error.error) {
          
        return throwError(errorMessage);

      }
    }))

  }
  updateCommunicationMedium(data:any){

    return this.http.post(this.baseUrl+'api/Doctor/update_communicationtype',data).pipe(catchError(errorRes=>{
      const errorMessage='An Error Occured';
      if (!errorRes.error || !errorRes.error.error) {
        return throwError(errorMessage);
      }

    }))
  }
  addCommunicationMedium(data:any){

    return this.http.post(this.baseUrl+'api/Doctor/add_communicationtype',data);
  }
  getDatewiseTimeslote(communicationTypeID,date){
    return this.http.get(this.baseUrl+'api/Doctor/get_datewise_timeslots?CommunicationTypeID='+communicationTypeID+'&date='+date);
  }
  getAllocatedTimeSlots(){
    return this.http.get(this.baseUrl+'api/Doctor/get_allocated_timeslots');
  }
  getAllocatedTimeSlotDetails(eventDate,communicationType){

    return this.http.get(this.baseUrl+"api/Doctor/get_allocated_timeslot_details?AvailableDate='"+eventDate+"'&CommunicationType='"+communicationType+"'");
  }
}
