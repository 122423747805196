import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProfileService } from './profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SearchCountryField, TooltipLabel, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  DependentForm:FormGroup;
  profile :{};
  UserID: any;
  loading:any;
  name:any;
  img_uri:any;
  _remark:any;

  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];



  constructor(private formBuilder: FormBuilder,private service:ProfileService,private route: ActivatedRoute,
    private router: Router,private toastr: ToastrService) { 

    this.DependentForm = this.formBuilder.group({
      UserID:[this.UserID],
      FirstName: [''],
      MiddleName: [''],
      LastName: [''],
      Email:[''],
      Age:[''],
      MobileNumber:[''],
      OfficialNumber:[''],
      WhatsAppNumber:[''],
      Address:[''],
      CityName:[''],
      StreetName:[''],
      Pincode:[''],
      Remarks:['']
    });
  }
 

  ngOnInit() {
    this.UserID=localStorage.getItem('UserID');
    this.GetUserProfile();
  }
  
  GetUserProfile(){
    
    this.loading=true;
    this.service.GetUserProfile(this.UserID).subscribe((res: any) => {
     
      if(res!=null){
        console.log('Profile get status '+res.message);
        if(res.status==1){
         
          this.name=res.data.name;
          this.img_uri=res.data.imageURL;
          if(this.img_uri==''){
            if(res.data.gender=='F'){
              this.img_uri="./assets/img/faces/female.png";
            }
            else {
              this.img_uri="./assets/img/faces/male.png";
              
            }
          }
          
          this.DependentForm.setValue({
            UserID:res.data.userID,
            FirstName: res.data.firstName,
            MiddleName: res.data.middleName,
            LastName: res.data.lastName,
            Email:res.data.email,
            Age:(res.data.age==0)?'':res.data.age,
            MobileNumber:res.data.mobileNumber,
            OfficialNumber:res.data.officialNumber,
            WhatsAppNumber:res.data.whatsAppNumber,
            Address:res.data.address,
            CityName:res.data.cityName,
            StreetName:res.data.streetName,
            Pincode:res.data.pincode,
            Remarks:res.data.remarks,
            });
            this._remark=res.data.remarks;
          this.loading=false;
        }
      }
      

      
    });
  }

  UpdateProfile(form:FormGroup){

    const body = {
      UserID:this.UserID,
      MiddleName: form.value.MiddleName,
      LastName: form.value.LastName,
      Age: form.value.Age,
      MobileNumber: (form.value.MobileNumber!=null)?form.value.MobileNumber.internationalNumber:'',
      OfficialNumber: form.value.OfficialNumber,
      WhatsAppNumber: form.value.WhatsAppNumber,
      Address: form.value.Address,
      CityName: form.value.CityName,
      StreetName: form.value.StreetName,
      Pincode: form.value.Pincode,
      Remarks: form.value.Remarks,
    };


    this.loading=true;
      this.service.UpdateProfile(body).subscribe(
        (response:any) => {                           //Next callback
         
          if (response !== null) {

            if(response.status==1){
              this.ngOnInit();
              this.toastr.success('Profile successfully updated');
              this.loading=false;
             
            }
          }
          
        },
        (error) => {                              //Error callback
          
          
        }
      )

  }
 
}
