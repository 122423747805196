import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {delay} from 'rxjs/operators'
import {of,throwError} from 'rxjs'
import { catchError, tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AddAppointmentService {

  
  baseUrl: any;
  constructor(private http: HttpClient) { 
    this.baseUrl = environment.url;
  }
  getUserForList(id) {
    return this.http.get(this.baseUrl + `api/user/get_dependent?UserID=`+id);
  }
  getCommunicationTypeList() {
    return this.http.get(this.baseUrl + `api/patient/get_communication_types`);
  }
  getUnavailableDates(id) {
    return this.http.get(this.baseUrl + `api/booking/get_doctor_unavailable_date?CommunicationTypeID=`+id);
  }
  getAvailableTimeslots(date,id) {
    return this.http.get(this.baseUrl + `api/booking/get_doctor_available_time?date=`+date+`&CommunicationTypeID=`+id);
  }

  SaveAppointment(data: any) {

    return this.http.post(this.baseUrl+'api/patient/add_appointment',data).pipe(
      catchError(errorRes=>{
        
        const errorMessage='An Error Occured';
        if (!errorRes.error || !errorRes.error.error) {
          
          return throwError(errorMessage);

        }
      }),
    );
   
  }


  getData(month:number,year:number)
  {
    if (month==2 && year==2020)
      return of([
        {day:'2020-02-06',data:'one'},
        {day:'2020-02-16',data:'two'},
        {day:'2020-02-22',data:'three'}
      ]).pipe(delay(500))
    if (month==3 && year==2020)
      return of([
        {day:'2020-03-02',data:'three'},
        {day:'2020-03-11',data:'four'},
      ]).pipe(delay(500))

    return of([
        {day:year+'-'+('00'+month).slice(-2)+'-'+('00'+Math.floor(Math.random()*29)).slice(-2),data:'fool'},
        {day:year+'-'+('00'+month).slice(-2)+'-'+('00'+Math.floor(Math.random()*29)).slice(-2),data:'fool2'},
      ]).pipe(delay(500))

  }
}
