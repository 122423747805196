import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES_Patient: RouteInfo[] = [
    { path: '/secondopinion/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    { path: '/secondopinion/add_dependent', title: 'Dependent',  icon:'person', class: '' },
    { path: '/secondopinion/upload_document', title: 'Document upload',  icon:'content_paste', class: '' },
    { path: '/secondopinion/mydocuments', title: 'My Documents',  icon:'content_paste', class: '' },
    { path: '/secondopinion/appointment', title: 'Appointment',  icon:'library_books', class: '' },
    { path: '/secondopinion/dependencycircle', title: 'Dependent Circle',  icon:'person', class: '' },
    { path: '/secondopinion/bookinghistory', title: 'Booking History',  icon:'library_books', class: '' },
    { path: '/secondopinion/profile', title: 'Profile',  icon:'person', class: '' },


];

export const ROUTES_Doctor: RouteInfo[] = [
  { path: '/doctor/_dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
  // { path: '/doctor/viewdicom', title: 'View Dicom',  icon: 'dashboard', class: '' },
  { path: '/doctor/_patientlist', title: 'Patient List',  icon: 'dashboard', class: '' },
  { path: '/doctor/_addtimeslote', title: 'Add timeslot',  icon: 'dashboard', class: '' },
  { path: '/doctor/_todayappointment', title: "Today's Appointments",  icon: 'dashboard', class: '' },
  { path: '/doctor/_newappointment', title: 'New Appointments',  icon: 'dashboard', class: '' },
  { path: '/doctor/_requesthistory', title: 'Request History',  icon: 'dashboard', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }
  isLoggedIn() {
    if (localStorage.getItem("0f1596e5b2017e4879395ed80fe0f8aae26657530") != null) {
      return true;
    }
    else
      return false;
  }
  ngOnInit() {

    if (this.isLoggedIn()) {
      var loginInfo=JSON.parse(localStorage.getItem('0f1596e5b2017e4879395ed80fe0f8aae26657530'));
      if(loginInfo.role=='Patient'){

        this.menuItems = ROUTES_Patient.filter(menuItem => menuItem);
      }
      if(loginInfo.role=='Doctor'){
        this.menuItems = ROUTES_Doctor.filter(menuItem => menuItem);
      }
    }

    
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
