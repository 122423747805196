import { Component, OnInit ,Output, EventEmitter,ViewChild} from '@angular/core';
import { HttpEventType, HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DocumentUploadService } from './document-upload.service';
import { DatePipe } from '@angular/common';

import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {  MatSort } from "@angular/material/sort";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.css']
})
export class DocumentUploadComponent implements OnInit {

  DocumentForm:FormGroup;
  DependentFormGroup:FormGroup;

  files: File[] = [];
  filesToSubmit:string[];
  UploadFor:any;
  DocumentAccess:any;
  NewDependentFlag:any;

  
  @Output() public onUploadFinished = new EventEmitter();
  mySelfProgress: number;
  public message: string;
  loading:any;
  UserID:any;

  displayedColumns: string[] = [ 'name', 'relationship', 'age'];
  _dataSource : any;
  DEPENDENT_LIST: DependentInfo[] = [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  collectionSize: number;
  dependentName:any;
  relationship:any;
  userID_To_Upload:any;

  selectedRowIndex:number=-1;
  constructor(private http: HttpClient,private formBuilder: FormBuilder,
    private service:DocumentUploadService,private toastr: ToastrService,
    public _snackBar:MatSnackBar) { }

  ngOnInit() {


    
    this.UserID=localStorage.getItem('UserID');
    this.files=[];
    this.filesToSubmit=[];
    this.UploadFor=true;
    this.DocumentAccess=true;
    this.NewDependentFlag=false;
    this.mySelfProgress=0;

    this.DocumentForm = this.formBuilder.group({
      UserID:this.UserID,
      DocumentName: ['', Validators.required],
      ReportDate: ['', Validators.required],
      DocumentAccess:[true],
      ValidFrom:[''],
      ValidTo:[''],
      UserType:['']
    });
    this.DependentFormGroup = this.formBuilder.group({

      Relationship:['',Validators.required],
      FirstName:['',Validators.required],
      MiddleName:[''],
      LastName:[''],
      Age:[''],
      Gender:['M']
    });
    this.GetMyDependentList();
  }
  highlight(row){
    console.log(row);
    this.selectedRowIndex = row.userID;
    }

  onDropzoneSelect(event) {
    this.filesToSubmit=[];
    this.files.push(...event.addedFiles);
    this.uploadFileToServer();
  }
   
  onDropzoneRemove(event) {
      this.filesToSubmit=[];
      console.log(event);
      this.files.splice(this.files.indexOf(event), 1);
      this.uploadFileToServer();
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  uploadFileToServer(){

    const formData = new FormData(); 
    for(var i=0;i<this.files.length;i++){
      formData.append("file[]",this.files[i]);
    }
    this.mySelfProgress=50;
    formData.append('UserID',this.UserID);  
    
      this.service.UploadDocuments(formData).subscribe(
        (response:any) => {                           //Next callback
         
          console.log(response);
          if (response !== null) {
           
            if(response.status==1){
              this.mySelfProgress=80;
              for(var i=0;i<response.documentPath.length;i++){
                this.filesToSubmit.push(response.documentPath[i])
              }
              this.mySelfProgress=0;
            }
          }
        },
        (error) => {                              //Error callback
          
          
        }
    );
  
  }
  FormatDate(value: string) {
    var datePipe = new DatePipe("en-US");
     value = datePipe.transform(value, 'dd/MM/yyyy');
     return value;
  }
  showNewDependentCreation(){
    this.NewDependentFlag=true;
  
  }
  showDependentList(){
 
    this.NewDependentFlag=false;
  }
  GetMyDependentList(){
    this.service.GetMyDependentList(this.UserID).subscribe((res: any) => {
      this.DEPENDENT_LIST=[];
      console.log(res);
      if(res!=null){
        if(res.status==1){
          this.DEPENDENT_LIST.push(...res.dependentList);
          this._dataSource = new MatTableDataSource(this.DEPENDENT_LIST);
    
          this._dataSource.paginator = this.paginator;
          this._dataSource.sort = this.sort;
          this.collectionSize = this.DEPENDENT_LIST.length;
        }
      }

    });
  }
  selectUser(userID,firstName,relationship){

   
    this.dependentName=firstName;
    this.relationship=relationship;
    this.userID_To_Upload=userID;
  }
  DocumentSubmit(form:FormGroup){

    if(!this.UploadFor){
      
      if(!this.NewDependentFlag){

        if(this.userID_To_Upload==null||this.userID_To_Upload==""){
          this.openSnackBar('Please select a dependent','Required');
          
          return;
        }
      }
      else{

        if(this.DependentFormGroup.status=="VALID"){
          this.SaveDependent(form);
        }
        console.log(this.DependentFormGroup.status);
        return;
      }
     
    }
  
    const body={
      UserID:(this.UploadFor)? this.UserID:this.userID_To_Upload,
      UserType:(this.UploadFor==true)? 'S' : 'D',
      DocumentName:form.value.DocumentName,
      ReportDate:this.FormatDate(form.value.ReportDate) ,
      DocumentAccess:(this.DocumentAccess==true)? 'A':'C',
      ValidFrom:(this.DocumentAccess==true) ?'':this.FormatDate(form.value.ValidFrom) ,
      ValidTo:(this.DocumentAccess==true) ?'':this.FormatDate(form.value.ValidTo) ,
      documentPath:(this.filesToSubmit!=null)?this.filesToSubmit:[],
    }
    
    if(body.DocumentName==''|| body.DocumentName==null){
      this.openSnackBar('Please enter document name','Required');
      
      return;
    }
    else if(body.ReportDate==''|| body.ReportDate==null){
      this.openSnackBar('Please enter report date','Required');
      return;
    }
    else if(!this.DocumentAccess){

      if(body.ValidFrom==''||body.ValidFrom==null){
        this.openSnackBar('Please enter from date','Required');
        return;
      }
      else if(body.ValidTo==''||body.ValidTo==null){
        this.openSnackBar('Please enter to date','Required');
        
        return;
      }
      else if(form.value.ValidFrom>form.value.ValidTo){
        this.openSnackBar('From date should not greater than to date','Required');
        return;
      }
    }
   
    this.SaveUserDocuments(body);
   
  }

  SaveUserDocuments(body){


      this.loading=true; 
      this.service.SaveUserDocuments(body).subscribe((resp: any) => {
        
        console.log(resp);
        this.loading=false;
        if(resp!=null){
          if(resp.status==1){

            this.toastr.success(resp.message);
            this.ngOnInit();
            return;
          }
        }
        setTimeout(() => {
         
        }, 1000);
        
      }, error => {
        this.loading=false;
      });
  }
  SaveDependent(form){

    const body={
      UserID:0,
      UserType:'D',
      DocumentName:form.value.DocumentName,
      ReportDate:this.FormatDate(form.value.ReportDate) ,
      DocumentAccess:(this.DocumentAccess==true)? 'A':'C',
      ValidFrom:(this.DocumentAccess==true) ?'':this.FormatDate(form.value.ValidFrom) ,
      ValidTo:(this.DocumentAccess==true) ?'':this.FormatDate(form.value.ValidTo) ,
      documentPath:(this.filesToSubmit!=null)?this.filesToSubmit:[],
    }
    
    if(body.DocumentName==''|| body.DocumentName==null){
      this.openSnackBar('Please enter document name','Required');
      
      return;
    }
    else if(body.ReportDate==''|| body.ReportDate==null){
      this.openSnackBar('Please enter report date','Required');
      
      return;
    }
    else if(!this.DocumentAccess){

      if(body.ValidFrom==''||body.ValidFrom==null){
        this.openSnackBar('Please enter from date','Required');
        return;
      }
      else if(body.ValidTo==''||body.ValidTo==null){
        this.openSnackBar('Please enter to date','Required');
        
        return;
      }
      else if(form.value.ValidFrom>form.value.ValidTo){
        this.openSnackBar('From date should not greater than to date','Required');
        
        return;
      }
    }
  
    const Dep_Form=this.DependentFormGroup.value;
   
    const Dep_body = {
      UserID:this.UserID,
      Relationship: Dep_Form.Relationship,
      FirstName: Dep_Form.FirstName,
      LastName: Dep_Form.LastName,
      MiddleName: Dep_Form.MiddleName,
      Age: Dep_Form.Age,
      Gender: Dep_Form.Gender,
    };
  
    this.loading=true;
    this.service.AddDependent(Dep_body).subscribe(

      (response:any) => { 
        this.loading=false;
        
        if (response !== null) {

          if(response.status==1){
            body.UserID=response.dependentID;
            this.SaveUserDocuments(body);
          }
        }
      }

    );
  }
  clearForm(){
    this.ngOnInit();
  }
}
export interface DependentInfo {

  userID: number;
  dependentID: number;
  firstName: string;
  gender: string;
  age: string;
  relationship: string;
  isSelfDependent:boolean;
}
