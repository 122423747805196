import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, tap, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RequestHistoryService {

  baseUrl:any;
  constructor(private http:HttpClient) {
    this.baseUrl=environment.url;
   }
  
  getAppointmentHistory(){
    return this.http.get(this.baseUrl+'api/Doctor/get_today_appointments?CommunicationTypeID=0 &SearchType=0');
  }
  getConsultationStatus(){
    return this.http.get(this.baseUrl+'api/Doctor/get_consultation_status');
  }
  UploadDocuments(data:any){

    return this.http.post(this.baseUrl+'api/DocumentUpload/upload_document',data).pipe(
      catchError(errorRes=>{
        const errorMessage='An Error Occured';
        if (!errorRes.error || !errorRes.error.error) {
          
          return throwError(errorMessage);

        }
      })
    )
  }
  AddConsultaionDetails(data:any){

    return this.http.post(this.baseUrl+'api/Doctor/add_consultation_details',data).pipe(
      catchError(errorRes=>{
        const errorMessage='An Error Occured';
        if (!errorRes.error || !errorRes.error.error) {
          
          return throwError(errorMessage);

        }
      })
    )
  }
  getPatientDetails(PatientID){
    return this.http.get(this.baseUrl+'api/Doctor/get_today_appointments?CommunicationTypeID=0 &SearchType=0&PatientID='+PatientID);
  }
}
