import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ConfirmQuestionService } from './confirm-question.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
@Component({
  selector: 'app-confirm-question',
  templateUrl: './confirm-question.component.html',
  styleUrls: ['./confirm-question.component.css']
})
export class ConfirmQuestionComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmQuestionComponent>, 
    private service :ConfirmQuestionService,public _snackBar:MatSnackBar,private router: Router) { }
    loading:any;

    isMobileWindow:any;
  ngOnInit() {  
   this.isMobileWindow=this.detectMobile();
   console.log(this.isMobileWindow);
  }
  detectMobile(){
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i];

      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
 
   }
  closeModal(){
    this.dialogRef.close();
  }

  SubmitQuestionnaire(){

    
    this.loading=true;
    const body=JSON.parse(localStorage.getItem('Q_FormToSubmit'));

    this.service.SaveQuestionnaire(body).subscribe(
      (response:any) => { 
        console.log(response);
        console.log(this.isMobileWindow);
        if(response.status){
          this.dialogRef.close();
          this.loading=false;

          this.openSnackBar('Succesfully saved','');
          if(this.isMobileWindow){
            this.router.navigate(['thanks-mob']);
          }
          else{
            this.router.navigate(['thanks']);
          }
        }
        else{
          this.openSnackBar('Failed to save','');
          this.loading=false;
        }
        
        
    },
    (error=>{
      this.loading=false;
    })

    )
   
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
