import { Component, OnInit,ElementRef } from '@angular/core';

declare function PostMessageToReact() : any;

@Component({
  selector: 'app-thanks-mobile',
  templateUrl: './thanks-mobile.component.html',
  styleUrls: ['./thanks-mobile.component.css']
})
export class ThanksMobileComponent implements OnInit  {

  OS:any='';
  
  category: Category = new Category();
  messageToPost: messageToPost = new messageToPost();
  

  constructor(el: ElementRef) { 
    el.nativeElement.style.backgroundColor = 'yellow';
  }

  ngOnInit() {
    PostMessageToReact();
  }


}
export class messageToPost
{
  status:1;
  message:string;
}
export class Category {
  name: string;
  description: string;
}
