import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AddDependentService {

  baseUrl: any;
  constructor(private http: HttpClient) { 
    this.baseUrl = environment.url;
  }
  getDicomUploadedPatients(){
    return this.http.get(this.baseUrl+'api/Doctor/get_dicom_uploaded_user');
  }
  downloadFile(id:any) {
    return this.http.get(this.baseUrl + 'api/download/'+id);
  }
  public download(id: any): void {
    //this.http.get(this.baseUrl + 'api/download/' + id, { responseType: 'blob' }).subscribe(res => {
    //  //window.open(window.URL.createObjectURL(res));
    //  return window.URL.createObjectURL(res);
    //});
  }

}
