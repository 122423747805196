import { Component, OnInit,ViewChild } from '@angular/core';
import { PatientListService} from './patient-list.service';

import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import { DoctorDicomViewerComponent } from '../doctor-dicom-viewer/doctor-dicom-viewer.component';
import {TodayAppointmentDetailsComponent  } from '../today-appointment-details/today-appointment-details.component';
import { ViewQuestionnaireReportComponent } from '../view-questionnaire-report/view-questionnaire-report.component';

declare const cornerstone;
declare const cornerstoneWADOImageLoader;
@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit {

  constructor(private service:PatientListService,public dialog: MatDialog) { }
  
  patientList:any[]=[];
  documentList:any[]=[];
  name:any;
  showDocHeader:boolean=true;

  tableView:boolean=true;

  displayedColumns: string[] = ['patientid','name','email', 'mobileNumber','appointmentFrom','consultationStatus','button3','button1','button2'];
  _dataSource : any;
  collectionSize: number;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
 
  pageOfItems: Array<any>;
  items = [];
  ngOnInit() {
    
    this.getPatientList();

    cornerstoneWADOImageLoader.external.cornerstone = cornerstone; // inicializa WADO Image loader

    // configura codecs e web workers
    cornerstoneWADOImageLoader.webWorkerManager.initialize({
      maxWebWorkers:navigator.hardwareConcurrency||1,
      startWebWorkersOnDemand:true,
        webWorkerPath: './assets/cornerstone/webworkers/cornerstoneWADOImageLoaderWebWorker.js',
        taskConfiguration: {
            'decodeTask': {
              initializeCodecOnStartup:false,
                codecsPath: '../codecs/cornerstoneWADOImageLoaderCodecs.js'
            }
        }
    });
  }
  getPatientList(){
    
    this.patientList=[];
    this.items=[];
    this.service.getPatientList().subscribe((res:any)=>{

     
      this.patientList.push(...res.list);

      this.items = this.patientList;

      this._dataSource = new MatTableDataSource(this.patientList);

      this._dataSource.paginator = this.paginator;
      this._dataSource.sort = this.sort;
      this.collectionSize = this.patientList.length;
    });
  }
  loadDocuments(userID,dependentID,firstName){
    this.showDocHeader=false;
    this.name=firstName;
    this.documentList=[];
    this.service.loadDocuments(userID,dependentID).subscribe((res:any)=>{

      this.documentList.push(...res.list);

console.log(   this.documentList);

      this.documentList.forEach(element => {
        element.documentDetails.forEach(element => {

          if(element.fileName!=null){
          
           var ExtensionDetails=(/[.]/.exec(element.fileName)) ? /[^.]+$/.exec(element.fileName) : undefined;

            if(ExtensionDetails!=undefined){
              element.extension= element.fileName.split('?')[0].split('.').pop();
            }
            else{
              element.extension='DCM';
            }
          }
          
          
        });
      });

  
    });

  
  }
  selectPatient(el){

    this.loadDocuments(el.userID,el.dependentID,el.firstName);
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this._dataSource.filter = filterValue.trim().toLowerCase();
  }
  viewDicomClick(el){


    const dialogRef = this.dialog.open(DoctorDicomViewerComponent, {
      width: '900px',
      disableClose: false,
              data:{  
                userID:el.userID,
                userDocumentDetailID:0
              }
    });

    dialogRef.afterClosed().subscribe(result => {
     
      
    });
  }
  viewDicomClickById(userDocumentDetailID){

    const dialogRef = this.dialog.open(DoctorDicomViewerComponent, {
      width: '1400px',
      disableClose: false,
              data:{  
                userID:0,
                userDocumentDetailID:userDocumentDetailID
              }
    });

    dialogRef.afterClosed().subscribe(result => {
     
      
    });
  }

  viewQuestionnaire(el){

    const dialogRef = this.dialog.open(ViewQuestionnaireReportComponent, {
      width: '900px',
      disableClose: false,
              data:{  
                userID:el.userID
              }
    });

    dialogRef.afterClosed().subscribe(result => {
     
      
    });
  }
  openDetailBox(patientID){


    const dialogRef = this.dialog.open(TodayAppointmentDetailsComponent, {
      width: '900px',
      disableClose: false,
              data:patientID
    });

    dialogRef.afterClosed().subscribe(result => {
     
      
    });
  }
}
