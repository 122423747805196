import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {delay} from 'rxjs/operators'
import {of} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class MyDocumentsService {

  baseUrl: any;
  constructor(private http: HttpClient) { 
    this.baseUrl = environment.url;
  }

  getDependentList(id) {
    return this.http.get(this.baseUrl + `api/user/get_dependent?UserID=`+id);
  }
  GetDocumentList(UserID) {
    return this.http.get(this.baseUrl + `api/DocumentUpload/get_patient_documents?UserID=`+UserID);
  }
}
