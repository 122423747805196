import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AddDependentService {

  baseUrl: any;
  constructor(private http: HttpClient) { 
    this.baseUrl = environment.url;
  }

  AddDependent(data: any) {

    return this.http.post(this.baseUrl+'api/User/add_dependent',data).pipe(
      catchError(errorRes=>{
        
        const errorMessage='An Error Occured';
        if (!errorRes.error || !errorRes.error.error) {
          
          return throwError(errorMessage);

        }
      }),
    );
   
  }
  UploadDocuments(data:any){

    return this.http.post(this.baseUrl+'api/DocumentUpload/upload_document',data).pipe(
      catchError(errorRes=>{
        const errorMessage='An Error Occured';
        if (!errorRes.error || !errorRes.error.error) {
          
          return throwError(errorMessage);

        }
      })
    )
  }
  SaveUserDocuments(data:any){
    return this.http.post(this.baseUrl+'api/DocumentUpload/save_documents',data).pipe(
      catchError(errorRes=>{
        const errorMessage='An Error Occured';
        if (!errorRes.error || !errorRes.error.error) {
          
          return throwError(errorMessage);

        }
      })
    )
  }

}
