import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class TodayAppointmentService {

  baseUrl: any;
  constructor(private http: HttpClient) { 
    this.baseUrl = environment.url;
  }
  getTodayAppointments(communicationTypeID){
    return this.http.get(this.baseUrl+'api/Doctor/get_today_appointments?CommunicationTypeID='+communicationTypeID+'&SearchType=1');
  }
  getAppsettings(){
    return this.http.get(this.baseUrl+'api/Doctor/get_app_settings');
  }
  getCommunicationTypes(){
    return this.http.get(this.baseUrl+'api/Patient/get_communication_types');
  }
}
