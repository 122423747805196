import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserRegistrationService } from './user-registration.service';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UserRegistrationDialogComponent } from '../user-registration-dialog/user-registration-dialog.component';
import Swal from 'sweetalert2';
import { SearchCountryField, TooltipLabel, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

declare var $: any;

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css']
})
export class UserRegistrationComponent implements OnInit {

  animal: string;
  name: string;


  loginForm: FormGroup;
  registrationForm:FormGroup;
  forgotForm:FormGroup;
  error: any;
  flag:any;
  loading:any;
  passwordMatch:any;
  lblPasswordError:any;
  
  // emailPattern ="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  emailPattern = "[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$";
  // emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";


  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];


  constructor(private formBuilder: FormBuilder,private service:UserRegistrationService,
    private router: Router,public dialog: MatDialog) { }

  ngOnInit(): void {
    this.flag = true;
    const signUpButton = document.getElementById('signUp');
    const signInButton = document.getElementById('signIn');
    const container = document.getElementById('main-container');

    signInButton.addEventListener('click', () => {
        container.classList.add("right-panel-active");
    });

    signUpButton.addEventListener('click', () => {
        container.classList.remove("right-panel-active");
    });

    this.loginForm = this.formBuilder.group({
      
      username: ['', [Validators.required, Validators.email,Validators.pattern(this.emailPattern)]],
      password: ['', Validators.required]

    });
    this.registrationForm = this.formBuilder.group({
      firstname:['',Validators.required],
      lastname:[''],
      middlename:[''],
      age:[''],
      gender:['M'],
      email: ['', [Validators.required, Validators.email,Validators.pattern(this.emailPattern)]],
      password: ['', Validators.required],
      re_password:['',Validators.required],
      mobileNumber:['']
    });
    this.registrationForm.get('re_password').disable();

    this.forgotForm=this.formBuilder.group({
      username:['', Validators.required]
    });

    this.passwordMatch=false;
    this.lblPasswordError='';
  }
  
  get f() { return this.registrationForm.controls; }
  get l() { return this.loginForm.controls; }

  LoginSubmit(L_form: FormGroup){

    if(L_form.status!="INVALID"){
      const body = {
        email: L_form.value.username,
        password: L_form.value.password
      };
     
      this.loading=true;
      this.error='';
  
      this.service.VerifyLoginCreditials(body)
        .subscribe(
          (response:any) => {                           //Next callback

            console.log('Login status'+response.message);
            if (response !== null) {
              this.loading=false;
                if(response.status==0){
                  
                  this.error=response.message;
                  this.opensweetalertdng(this.error);
                }
                else{
                  
                  localStorage.setItem('UserID', response.data.id);
                  localStorage.setItem('user_name', response.data.fullname);

                  if(this.service.isPatient){
                    this.router.navigate(['secondopinion/dashboard']);
                  }
                  else if(this.service.isDoctor){
                    this.router.navigate(['doctor/_dashboard']);
                  }
                  else{
                    this.router.navigate(['login']);
                  }
                  
                }
            }
            else{
              this.error=response.message;
            }
          },
          (error) => {                              //Error callback
            this.loading=false;
            this.error ='An Error Occured';
          }
        )
    }
    

  }
  RegistrationSubmit(R_form:FormGroup){
    
    if(R_form.status!="INVALID"){
      const body = {
        firstname: R_form.value.firstname,
        lastname:R_form.value.lastname,
        middlename:R_form.value.middlename,
        age:R_form.value.age,
        gender:R_form.value.gender,
        email: R_form.value.email,
        password: R_form.value.password,
        mobileNumber: (R_form.value.mobileNumber!=null)?R_form.value.mobileNumber.internationalNumber:''
      };

      if(this.passwordMatch){
  
        this.loading=true;
        this.service.AddUser(body)
          .subscribe(
            (response:any) => {                           //Next callback
              this.loading=false;
              console.log('Register status'+ response.message);
              if (response !== null) {
                console.log(response);
                if(response.status==1){
                  localStorage.setItem('user_name', response.data.fullname);
                  localStorage.setItem('UserID', response.data.id);
                  this.openDialog();
                }
                else{
                  if(response.message=="Username already exists"){

                    this.opensweetalertdng(response.message);
                    return;
                  }
                }
              }
              
            },
            (error) => {                              //Error callback
              this.loading=false;
              this.error ='An Error Occured';
            }
          )
  
      }
    }
  }
  EnableRetypePass(){
    if(this.registrationForm.get('password').value!=''){
      this.registrationForm.get('re_password').enable();
    }
    else{
      this.registrationForm.get('re_password').disable();
    }
    
  }
  ValidateRetypePass(){
    
    if(this.registrationForm.get('password').value!=this.registrationForm.get('re_password').value)
    {
      this.passwordMatch=false;
      this.lblPasswordError='Passwords missmatch';
      return;
    }

    this.lblPasswordError='';
    this.passwordMatch=true;
  }
  ForgotPasswordSubmit(F_form:FormGroup){

  }

  openDialog(): void {

    const dialogRef = this.dialog.open(UserRegistrationDialogComponent, {
      width: '800px',
      data: {name: this.name, animal: this.animal},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
    });
  }
  opensweetalertdng(msg)
  {
   Swal.fire('Oops...', msg, 'error')
  }
  redirectPrivacyPolicy(){
    this.router.navigate(["privacypolicy"]);
  }
}
