import { Component, OnInit,Inject,ViewChild } from '@angular/core';
import { DICOMViewerComponent } from 'ng-dicomviewer';
import { Router } from '@angular/router';

import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { PatientListService } from '../patient-list/patient-list.service';
import { environment } from '../../../environments/environment';

declare  var $ :any;

declare const cornerstone;
declare const cornerstoneWADOImageLoader;

@Component({
  selector: "app-doctor-dicom-viewer",
  templateUrl: "./doctor-dicom-viewer.component.html",
  styleUrls: ["./doctor-dicom-viewer.component.css"],
})
export class DoctorDicomViewerComponent implements OnInit {
  userID: any;
  userDocumentID: number;
  userDocumentDetailID: number;
  documentInfoList: DicomInfo[] = [];

  documentPathList: DocumentList[] = [];
  documentPathListCopy: DocumentList[] = [];

  DicomList: any = [];
  @ViewChild(DICOMViewerComponent, { static: true })
  viewPort: DICOMViewerComponent;

  constructor(
    public dialogRef: MatDialogRef<DoctorDicomViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private service: PatientListService
  ) {
    this.userID = data.userID;
    this.userDocumentDetailID = data.userDocumentDetailID;
  }

  ngOnInit() {
    if (this.userDocumentDetailID > 0) {
      this.ViewDicom(this.userDocumentDetailID);
      $(".image-canvas-wrapper").css({ display: "block" });
    } else {
      this.getDicomListByUserID();
    }
  }

  getDicomListByUserID() {
    this.service.getDicomListByUserID(this.userID).subscribe((res: any) => {
      this.documentPathList = [];
      this.documentPathListCopy = [];
      res.list.forEach((element) => {
        this.documentInfoList.push(element.documentDetails);
        this.documentPathList.push(element.documentPath);
        this.DicomList.push(element.documentPath[0]);
      });

      this.userDocumentID = this.documentPathList[0][0].userDocumentID;
      this.userDocumentDetailID =
        this.documentPathList[0][0].userDocumentDetailID;
      this.documentPathListCopy = this.documentPathList;

      this.ViewDicom(this.documentPathList[0][0].userDocumentDetailID);
      // this.ViewDicomImages();
    });
  }

  dicomLoadedFlag: boolean = false;
  ViewDicom(userDocumentDetailID) {
    const element = document.querySelector(".image-canvas");
    cornerstone.enable(element);

    this.http
      .get(environment.url + "api/download/" + userDocumentDetailID, {
        responseType: "blob",
      })
      .subscribe((res) => {
        //console.log(res);
        cornerstone
          .loadImage("wadouri:" + window.URL.createObjectURL(res))
          .then((image) => {
            this.dicomLoadedFlag = true;
            this.viewPort.resetAllTools();
            this.viewPort.enableViewerTools = true;
            this.viewPort.enablePlayTools = true;
            this.viewPort.enableZoom();
            this.viewPort.enableAngle();
            this.viewPort.enableElliptical();
            this.viewPort.enablePan();
            this.viewPort.enableProbe();
            this.viewPort.enableRectangle();
            this.viewPort.enableScroll();
            this.viewPort.enableWindowing();
            this.viewPort.enableZoom();
            //this.viewPort.loadStudyImages
            this.viewPort.maxImagesToLoad = 0;

            cornerstone.displayImage(element, image);
            this.viewPort.playClip();
          })
          .catch((error) => {
            console.log(error);
          });
      });
  }
  blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  fileList = [];

  getFileList(id,fileName){
    this.http
            .get(
              environment.url +
                "api/download/" +
                id,
              { responseType: "blob" }
            )
            .subscribe((res) => {
              this.fileList.push(this.blobToFile(res, fileName));
            });
        
  }
  downloadFile(id,fileName,extension) {
    // console.log(fileName);
    this.http
            .get(
              environment.url +
                "api/download/" +
                id,
              { responseType: "blob" }
            )
            .subscribe((res) => {
              const blob = this.blobToFile(res, fileName);
              const url= window.URL.createObjectURL(blob);
              window.open(url);
            });   
  }
  ViewDicomImages() {
    localStorage.setItem("debug", "cornerstoneTools");
    let imageList = [];
    this.fileList=[];
    const element = document.querySelector(".image-canvas");
    cornerstone.enable(element);
    if (this.DicomList.length > 0) {
      this.DicomList.forEach((pathelement) => {
        console.log(pathelement.userDocumentDetailID);
        if (pathelement.userDocumentDetailID) {
          this.getFileList(pathelement.userDocumentDetailID,pathelement.fileName);
          // this.http
          //   .get(
          //     environment.url +
          //       "api/download/" +
          //       pathelement.userDocumentDetailID,
          //     { responseType: "blob" }
          //   )
          //   .subscribe((res) => {
          //     this.fileList.push(this.blobToFile(res, pathelement.fileName));
          //   });
        }
      // fileList.push(pathelement.documentPath);
      });
      // console.log(fileList);
      cornerstoneWADOImageLoader.wadouri.fileManager.purge();
      // cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.purge();
      for (let i = 0; i < this.fileList.length; i++) {
        const dicomFile: File = this.fileList[i];
        const imageId =
        cornerstoneWADOImageLoader.wadouri.fileManager.add(dicomFile);
          // cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.load(dicomFile);
        imageList.push(imageId);
      }
      console.log(imageList);
      this.dicomLoadedFlag = true;
      this.viewPort.resetAllTools();
      this.viewPort.enableViewerTools = true;
      this.viewPort.enablePlayTools = true;
      this.viewPort.maxImagesToLoad = 0;
      this.viewPort.loadStudyImages(imageList);
      this.viewPort.playClip();
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }
  documentSelect(event) {
    this.documentPathList = [];
    this.documentPathListCopy.forEach((element) => {
      if (element[0].userDocumentID == event.value) {
        this.documentPathList.push(element);
      }
    });
  }

  /**
   * Load selected DICOM images
   *
   * @param files list of selected dicom files
   */
  loadDICOMImages(files: FileList) {
    if (files && files.length > 0) {
      let imageList = [];
      const fileList: Array<File> = Array.from(files);
      fileList.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (b.name > a.name) return -1;
        return 0;
      });
      //cornerstoneWADOImageLoader.wadouri.fileManager.purge();
      cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.purge();

      // loop thru the File list and build a list of wadouri imageIds (dicomfile:)
      for (let i = 0; i < fileList.length; i++) {
        const dicomFile: File = fileList[i];
        const imageId =
          cornerstoneWADOImageLoader.wadouri.fileManager.add(dicomFile);
        imageList.push(imageId);
      }

      this.viewPort.resetAllTools();

      // now load all Images, using their wadouri
      this.viewPort.loadStudyImages(imageList);
    } else alert("Escolha imagens DICOM a exibir.");
  }
}
export interface DicomInfo{
  documentName:string;
  reportDate:string;
}
export interface DocumentList{
  documentPath:string;
  fileName:string;
  userDocumentID:number;
  userDocumentDetailID:number;
}
