import { Component } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ClientApp';
  constructor(private router: Router,private activatedRoute: ActivatedRoute) { }

  isLoggedIn() {
    if (localStorage.getItem("0f1596e5b2017e4879395ed80fe0f8aae26657530") != null) {
      return true;
    }
    else
      return false;
  }
  ngOnInit() {

    
    // var uri=window.location.pathname;
    var uri=window.location.hash;

    console.log(uri);
    
    var uriQuestionnaireExist=uri.includes('questionnaire');
 
    var uriPaypalWebviewExist=uri.includes('paypal_');

    if (this.isLoggedIn()) {
      var loginInfo=JSON.parse(localStorage.getItem('0f1596e5b2017e4879395ed80fe0f8aae26657530'));
      if(loginInfo.role=='Patient'){

      
     
        if(uri=='/privacypolicy'){
          this.router.navigate(['privacypolicy']);
        }
        if(uriQuestionnaireExist){
          uri=uri.split("#").pop();
          this.router.navigate([uri.substring(0)]);
          return;
        }
        if(uriPaypalWebviewExist){
          uri=uri.split("#").pop();
          this.router.navigate([uri.substring(0)]);
          return;
        }
        else{
          this.router.navigate(['secondopinion/dashboard']);
        }
        
        
      }
      if(loginInfo.role=='Doctor'){

        if(uri=='/privacypolicy'){
          this.router.navigate(['privacypolicy']);
        }
        if(uriQuestionnaireExist){
          uri=uri.split("#").pop();
          this.router.navigate([uri.substring(0)]);
          return;
        }
        if(uriPaypalWebviewExist){
          uri=uri.split("#").pop();
          this.router.navigate([uri.substring(0)]);
          return;
        }
        else{
          this.router.navigate(['doctor/_dashboard']);
        }
        
      }
    }
    else{
      if(uri=='/privacypolicy'){
        this.router.navigate(['privacypolicy']);
      }
      if(uriQuestionnaireExist){
        uri=uri.split("#").pop();
        this.router.navigate([uri.substring(0)]);
        return;
      }
      if(uriPaypalWebviewExist){

        uri=uri.split("#").pop();
        this.router.navigate([uri.substring(0)]);
        return;
      }
      else{
        this.router.navigate(['login']);
      }
    }
  }
}
