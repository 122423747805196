import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class NewAppointmentService {

  baseUrl:any;
  constructor(private http:HttpClient) {
    this.baseUrl=environment.url;
   }


  getNewAppointments(){
    return this.http.get(this.baseUrl+'api/Doctor/get_today_appointments?CommunicationTypeID=0 &SearchType=2');
  }
}
