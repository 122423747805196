import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserRegistrationService {

  baseUrl: any;
  constructor(private http: HttpClient,private router: Router) { 
    this.baseUrl = environment.url;
  }
 
  VerifyLoginCreditials(data: any) {
    return this.http
      .post(this.baseUrl + 'api/Auth/user_Login', data).pipe(
        catchError(errorRes => {
          const errorMessage = 'An Error Occured';
          if (!errorRes.error || !errorRes.error.error) {
            return throwError(errorMessage);
          }
          return throwError(errorMessage);
        }),
        tap(resData => {
          if (resData !== null) {
            console.log(resData);
            this.handleAuthentication(resData);
          }
        })
      );
  }
  private handleAuthentication(respData: any) {
   
  
    if (respData.status === 1) {

    const userData = { status: respData.status, token: respData.data.token, role: respData.data.role,fullname:respData.data.fullname,UserID:respData.data.id };

      localStorage.setItem('0f1596e5b2017e4879395ed80fe0f8aae26657530', JSON.stringify(userData));


      this.router.navigate(['dashboard']);
    } else {
      console.log('Authentication failed')
      
    }
    
  }
 
  AddUser(data: any) {
    return this.http
      .post(this.baseUrl + 'api/User/add_user', data).pipe(
        catchError(errorRes => {
          const errorMessage = 'An Error Occured';
          if (!errorRes.error || !errorRes.error.error) {
            return throwError(errorMessage);
          }
          return throwError(errorMessage);
        }),
        tap(resData => {
          if (resData !== null) {
            console.log(resData);
            localStorage.setItem('0f1596e5b2017e4879395ed80fe0f8aae26657530', JSON.stringify(resData));
            this.handleRegAuthentication(resData);
          }
        })
      );
  }
  private handleRegAuthentication(respData: any) {
    if (respData.status === 1) {

      const userData = { status: respData.status, token: respData.data.token, role: respData.data.role,fullname:respData.fullname,UserID:respData.id };
  
        localStorage.setItem('0f1596e5b2017e4879395ed80fe0f8aae26657530', JSON.stringify(userData));
        console.log('Authenticated')
  
      } else {
        console.log('Authentication failed')
        
      }
 }
  get isPatient(): boolean {
    if (localStorage.getItem('0f1596e5b2017e4879395ed80fe0f8aae26657530')) {
      const data = JSON.parse(localStorage.getItem('0f1596e5b2017e4879395ed80fe0f8aae26657530'));
    
      if (data.role === Role.patient) {
        return true;
      }
    }
    return false;
  }
  get isDoctor(): boolean {
    if (localStorage.getItem('0f1596e5b2017e4879395ed80fe0f8aae26657530')) {
      const data = JSON.parse(localStorage.getItem('0f1596e5b2017e4879395ed80fe0f8aae26657530'));
    
      if (data.role === Role.doctor) {
        return true;
      }
    }
    return false;
  }
  logout() {
    localStorage.removeItem('0f1596e5b2017e4879395ed80fe0f8aae26657530');
    this.router.navigate(['/login']);
  }
}



export enum Role {
  patient = 'Patient',
  doctor='Doctor'
}
