import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { DashboardService } from './dashboard.service';
import { DatePipe } from '@angular/common';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private router: Router,private service:DashboardService,private datepipe:DatePipe) { }
  
  UserID:any;
 
  dashboardModel:DashboardModel=new DashboardModel();
  bookingList:BookingList[]=[];

  private subscription: Subscription;
  
  public dateNow = new Date();
  public dDay = new Date();
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;

  public timeDifference;
  public secondsToDday=0;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  patientNumber:any;


  private getTimeDifference () {
    this.timeDifference = this.dDay.getTime() - new  Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
}

private allocateTimeUnits (timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
}

  ngOnInit() {
    
    this.UserID=localStorage.getItem('UserID');
    this.getDashboardInfo();
 

  }
  cardClick(uri){

    this.router.navigate(["/secondopinion/"+uri]);
  }

  Dash_Appointment(){
    this.router.navigate(['appointment']);
    
  }
  Dash_MyDocuments(){
    this.router.navigate(['mydocuments']);
  }
  Dash_BookingHistory(){
    this.router.navigate(['bookinghistory']);
  }
  Dash_DependentCircle(){
    this.router.navigate(['dependencycircle']);
  }

  getDashboardInfo(){

    this.bookingList=[];
    this.service.GetDoctorDashboard(this.UserID).subscribe((res:any)=>{

      this.dashboardModel=res.data.dashBoardCardInfo;
      this.bookingList=res.data.bookingList;

      if(this.bookingList.length>0){
        this.dDay=new Date(this.bookingList[0].appointmentDate);

        this.patientNumber=this.bookingList[0].patientNumber;
        this.subscription = interval(1000)
        .subscribe(x => { 
     
          if(this.secondsToDday>=0){
            this.getTimeDifference();
          }
  
         });
  
        this.bookingList.forEach(element=>{
  
          element.appointmentDate=this.convertToDate(element.appointmentDate);
        });
      }



    });
  }

  convertToDate(date){
    return this.datepipe.transform(date, 'yyyy-MM-dd');
   }
}

export class DashboardModel{

  bookingCount:number;
  dependentCount:number;
  documentCount:number;
}
export interface BookingList{

  name:string;
  relationship:string;
  patientID:number;
  patientNumber:string;
  appointmentDate:string;
  appointmentFrom:string;
  appointmentTo:string;
  communicationType:string;
  leftTime:string;
}