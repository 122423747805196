import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DoctorDashboardService } from './doctor-dashboard.service';
import { CalendarOptions } from '@fullcalendar/angular';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './doctor-dashboard.component.html',
  styleUrls: ['./doctor-dashboard.component.css']
})
export class DoctorDashboardComponent implements OnInit {

  dashboardInfo:DashboardInfo=new DashboardInfo();
  calendarOptionList:any[]=[];
  thisWeekConsulationList:any[]=[];
  constructor(private service: DoctorDashboardService) { }

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    // dateClick: this.handleDateClick.bind(this), // bind is important!
    // eventClick:this.handleEventClick.bind(this),
    
  };
 
  ngOnInit() {
    
    this.service.GetDoctorDashboard().subscribe((res:any)=>{
      console.log(res);
      this.dashboardInfo=res.dashboardInfo;

      res.bookedSlotsInfo.forEach(element => {
        this.calendarOptionList.push({title:element.communicationType,date:this.FormatDate(element.appointmentFrom)  ,color: '#1da2b2' });
      });


      console.log(this.calendarOptionList);
      this.calendarOptions.events =  this.calendarOptionList;
      this.thisWeekConsulationList.push(...res.thisWeekConsulationList);
    });
  }
  FormatDate(value: string) {
    var datePipe = new DatePipe("en-US");
    value = datePipe.transform(value, 'yyyy-MM-dd');
    return value;
 }

}
export class DashboardInfo{
  todayCount:number;
  weekCount:number;
  monthCount:number;
}