import { Component, OnInit ,ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import {  AddTimeslotedService} from './add-timeslote.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { CalendarOptions } from '@fullcalendar/angular';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { TimeslotDetailsComponent } from '../timeslot-details/timeslot-details.component';
import { title } from 'process';
declare var $:any;

@Component({
  selector: 'app-add-timeslote',
  templateUrl: './add-timeslote.component.html',
  styleUrls: ['./add-timeslote.component.css']
})
export class AddTimesloteComponent implements OnInit {

  communicationType:CommunicationType[]=[];
  activeCommunicationTypes:CommunicationType[]=[];

  availableTimeModel:AvailableTimeModel=new AvailableTimeModel();

  availableTimeList:AvailableTimeModel[]=[];

  allocatedSlots:any[]=[];
  communicationTypeName:string;
  calendarOptionList:calendarOptionList[]=[];
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this), // bind is important!
    eventClick:this.handleEventClick.bind(this),
    selectable: true,
    headerToolbar:{
      left:'prev,next,today',
      right:'dayGridMonth,dayGridWeek,dayGridDay,listGridweek'
    },
  };

  constructor(private service: AddTimeslotedService,public _snackBar:MatSnackBar,private _bottomSheet: MatBottomSheet,private changeDetectorRef: ChangeDetectorRef) { }
  
 
  ngOnInit() {
    
    
    this.availableTimeModel={communicationTypeID:0,date:this.FormatDateToString(new Date().toString()),fromTime:'',toTime:'',timeslot:''}
    this.getCommunicationTypes();
    this.getAllocatedTimeSlots();
  }
  
  handleDateClick(arg) {

    this.availableTimeList=[];
    this.availableTimeModel.date=this.FormatDateToString(arg.date);

   
  }
  handleEventClick(info){

    this._bottomSheet.open(TimeslotDetailsComponent,{

      data:{  
        eventDate:this.FormatDate(info.event.start),
        communicationType:info.event.title
      }
    });
  }
  getCommunicationTypes(){
    this.communicationType=[];
    this.activeCommunicationTypes=[];
    this.service.getCommunicationTypes().subscribe((res:any)=>{

      this.communicationType.push(...res.communicationTypeList);
      this.communicationType.forEach(element=>{
        if(element.is_Active){
          this.activeCommunicationTypes.push(element);
        }
      });
  
      this.communicationType.push({communicationTypeID:0,communicationType:'',amount:0,symbol:'',is_Active:true});

      this.availableTimeModel.communicationTypeID=this.communicationType[0].communicationTypeID;
      this.SelectCommunicationType(this.availableTimeModel.communicationTypeID,this.communicationType[0].communicationType);
    });
  }

  getAllocatedTimeSlots(){
    this.allocatedSlots=[];
    this.service.getAllocatedTimeSlots().subscribe((res:any)=>{

 
      this.allocatedSlots.push(...res.list);

      res.list.forEach(element => {
        this.calendarOptionList.push({title:element.communicationType,date:this.FormatDate(element.availableDate)  });
      });
   
      this.calendarOptions.events =  this.calendarOptionList;



    });
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  SaveAppointmentTimes(){

    this.availableTimeModel.date=this.FormatDate(this.availableTimeModel.date);
 
    if(this.availableTimeModel.date==''|| this.availableTimeModel.date==null||this.availableTimeModel.date==undefined){

      this.openSnackBar('Please select appointment date','Requried');
      return;
    }
    
    else if(this.availableTimeModel.fromTime==''|| this.availableTimeModel.fromTime==null||this.availableTimeModel.fromTime==undefined){

      this.openSnackBar('Please select appointment from time','Requried');
      return;
    }
    else if(this.availableTimeModel.toTime==''|| this.availableTimeModel.toTime==null||this.availableTimeModel.toTime==undefined){

      this.openSnackBar('Please select appointment to time','Requried');
      return;
    }
    else{

      this.service.addTimeSlote(this.availableTimeModel).subscribe((res:any)=>{

        this.openSnackBar(res.message,'');
      });
    }
   

  }
  addNewRow(){
    var len=this.communicationType.length;
    if(this.communicationType[len-1].communicationTypeID!=0){
      this.communicationType.push({communicationTypeID:0,communicationType:'',amount:0,symbol:'',is_Active:true});
    }



  }
  updateCommunicationType(communicationTypeID,communicationType,symbol,amount,is_Active,duration){

    if(communicationType==''){
      this.openSnackBar('Please enter communication type name','Required');
      return;
    }
    else if(symbol==''){
      this.openSnackBar('Please enter symbol','Required');
      return;
    }
    else if(amount==0){
      this.openSnackBar('Please enter amount','Required');
      return;
    }
    const data={
      communicationTypeID:communicationTypeID,
      communicationType:communicationType,
      symbol:symbol,
      amount:amount,
      Is_Active:is_Active,
      duration
    }

    this.service.updateCommunicationMedium(data).subscribe((res:any)=>{

      if(res.status==1){
        this.openSnackBar('Successfully updated','');
        this.getCommunicationTypes();
        return;
      }

    });
  }
  length:number;
  addCommunicationType(){
   
     var len=this.communicationType.length;
    if(this.communicationType[len-1].communicationType==''){
      this.openSnackBar('Please enter communication type name','Required');
      return;
    }
    else if(this.communicationType[len-1].symbol==''){
      this.openSnackBar('Please enter symbol','Required');
      return;
    }
    else if(this.communicationType[len-1].amount==0){
      this.openSnackBar('Please enter amount','Required');
      return;
    }
    this.service.addCommunicationMedium(this.communicationType[len-1]).subscribe((res:any)=>{

      if(res.status=1){
        this.openSnackBar('Successfully added','');
        this.getCommunicationTypes();
        return;
      }
    });
  }
  FormatDate(value: string) {
    var datePipe = new DatePipe("en-US");
    value = datePipe.transform(value, 'yyyy-MM-dd');
    return value;
 }
 FormatDateToString(value: string) {
  var datePipe = new DatePipe("en-US");
  value = datePipe.transform(value, 'dd MMM yyyy');
  return value;
}
  SelectCommunicationType(communicationTypeID,communicationType){


    this.communicationTypeName=communicationType;
    this.availableTimeModel.communicationTypeID=communicationTypeID;
    this.service.getDatewiseTimeslote(this.availableTimeModel.communicationTypeID,this.FormatDate(this.availableTimeModel.date)).subscribe((res:any)=>{


      this.availableTimeList=[];
      this.availableTimeList.push(...res.list);
  
    });

  }
  selectTimeslot(timeslot){
    this.availableTimeModel.timeslot=timeslot;
  }
  SaveTimeSlote(){


    for(var i=0;i<this.availableTimeList.length;i++){
      if(this.availableTimeList[i].timeslot==this.availableTimeModel.timeslot){
        this.availableTimeModel.fromTime=this.availableTimeList[i].fromTime;
        this.availableTimeModel.toTime=this.availableTimeList[i].toTime;
        break;
      }
    }

 

    const body={
      CommunicationTypeID:this.availableTimeModel.communicationTypeID,
      Date:this.FormatDate(this.availableTimeModel.date.toString()),
      FromTime:this.availableTimeModel.fromTime,
      ToTime:this.availableTimeModel.toTime,
    }

    this.service.addTimeSlote(body).subscribe((res:any)=>{

      this.SelectCommunicationType(this.availableTimeModel.communicationTypeID,this.communicationTypeName);
      
      this.openSnackBar('Successfully added','');
     
      this.allocatedSlots=[];
      this.service.getAllocatedTimeSlots().subscribe((res:any)=>{
  
   
        this.allocatedSlots.push(...res.list);

  
        res.list.forEach(element => {
          this.calendarOptionList.push({title:element.communicationType,date:this.FormatDate(element.availableDate)  });
        });
     
        this.calendarOptions.events =  this.calendarOptionList;
  
        this.changeDetectorRef.detectChanges();
      });
    });
  }
}
export interface CommunicationType{
  communicationTypeID:number;
  communicationType:string;
  amount:number;
  symbol:string;
  is_Active:boolean;
}
export class AvailableTimeModel{
  date:string;
  communicationTypeID:number;
  fromTime:string;
  toTime:string;
  timeslot:string;
}   
export interface calendarOptionList{

  title:string;
  date:string;
}
