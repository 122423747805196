import { Component, OnInit ,ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {  MatSort } from "@angular/material/sort";
import { MyDocumentsService } from './my-documents.service';
import { MatStepper } from '@angular/material/stepper';
import { DoctorDicomViewerComponent } from '../Doctor/doctor-dicom-viewer/doctor-dicom-viewer.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-my-documents',
  templateUrl: './my-documents.component.html',
  styleUrls: ['./my-documents.component.css']
})
export class MyDocumentsComponent implements OnInit {

  UserID:any;


  documentList :any[]=[];

  constructor(private _formBuilder: FormBuilder,private service:MyDocumentsService,public dialog: MatDialog) { 

  }

  ngOnInit() {

    this.UserID=localStorage.getItem("UserID");

    this.GetDocumentList();
  }

  GetDocumentList(){

    // this.medicalDocuments=[];
    this.service.GetDocumentList(this.UserID).subscribe((resp: any) => { 
      
      if(resp!=null){
        if(resp.status==1){
          this.documentList.push(...resp.data);
   console.log(this.documentList);
        }
      }
    });

  }
  viewDicomClickById(userDocumentDetailID){

    const dialogRef = this.dialog.open(DoctorDicomViewerComponent, {
      width: '900px',
      disableClose: false,
              data:{  
                userID:0,
                userDocumentDetailID:userDocumentDetailID
              }
    });

    dialogRef.afterClosed().subscribe(result => {
     
      
    });
  }
}

// export interface Documentinfo{
//   UserID :number;
//   Name:string;
//   Relationship:string;

//   UserDocumentID :string;
//   UserDocumentDetailID :string;
//   DocumentName :string;
//   ReportDate:string;
//   ValidFrom:string;
//   ValidTo :string;
//   FileName :string;
//   DocumentType :string;
//   DocumentPath :string;
// }