import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { SecondOpinionLayoutComponent } from './layouts/second-opinion-layout/second-opinion-layout.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import {ImageMapperComponent } from './image-mapper/image-mapper.component';

import { ThanksMobileComponent } from './common/thanks-mobile/thanks-mobile.component'
import { ThanksComponent } from './common/thanks/thanks.component';
import { BodyMarkerComponent } from './common/body-marker/body-marker.component';
import { FullcalendarComponent } from './fullcalendar/fullcalendar.component';
import { DicomComponent } from './dicom/dicom.component';

import { SECURE_ROUTES } from './_secure/secure.routes';
import { PatientAuthGurad } from './_secure/patient-authguard';
import { DoctorAuthGurad } from './_secure/doctor-authguard';
import { PublicComponent } from './frame/public/public.component';
import { PUBLIC_ROUTES } from './frame/public/public.routes';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';

import { TestComponentComponent } from './test-component/test-component.component';
import { PaypalComponent } from './paypal/paypal.component';
import { PaypalWebviewComponent } from './common/paypal-webview/paypal-webview.component';

const routes: Routes =[
  
  {
    path: 'secondopinion',
    component: SecondOpinionLayoutComponent,
    data: { title: 'secondopinion' }, children: SECURE_ROUTES, canActivate: [PatientAuthGurad]
  },
  {
    path: 'doctor',
    component: SecondOpinionLayoutComponent,
    data: { title: 'doctor' }, children: SECURE_ROUTES, canActivate: [DoctorAuthGurad]
  },
  { 
    path: 'login',
    component: UserRegistrationComponent ,
  }, 
  // { 
  //   path: 'questionnaire/:id',
  //   component: QuestionnaireComponent ,
  // }, 
  // { 
  //   path: 'thanks',
  //   component: ThanksComponent ,
  // },  
  { 
    path: 'thanks-mob',
    component: ThanksMobileComponent ,
  },  
  { 
    path: 'bodyMarker',
    component: BodyMarkerComponent ,
  },
  { 
    path: 'fullcalendar',
    component: FullcalendarComponent ,
  },
  {
    path: 'questionnaire/:id1/:id2/:id3',
    component: QuestionnaireComponent,
  },
  { 
    path: 'questionnaire/:id1',
   component: QuestionnaireComponent 
  },
  {
    path: 'privacypolicy',
    component: PrivacypolicyComponent,
  }
  ,
  {
    path: 'paypal',
    component: TestComponentComponent,
  }
  ,
  {
    path: 'paypal2',
    component: PaypalComponent,
  },
  {
    path: 'paypal_/:id1/:id2',
    component: PaypalWebviewComponent,
  },
  
];

@NgModule({
  imports: [
    // CommonModule,
    // BrowserModule,
    RouterModule.forRoot(routes,{
       useHash: true
    })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

