import { Component, OnInit } from '@angular/core';
import $ from "jquery";

@Component({
  selector: 'app-body-marker',
  templateUrl: './body-marker.component.html',
  styleUrls: ['./body-marker.component.css']
})
export class BodyMarkerComponent implements OnInit {

  constructor() { }

  showFront:any;
  vbox:any;
  sx:any;
  sy:any;
  ngOnInit() {
    localStorage.setItem('spotsconfiga',null);
    localStorage.setItem('spotsconfigb',null);
    this.spotsConfig();
    this.showFront=false;
  }
  spotsConfig(){
    const component = this;
    var spotsconfiga = {
      "pins":[
      // {
      //   "hover": "<u><b>Example 'A'</b></u><br>Add unlimited number of spots<br>anywhere on the diagram and<br>customize its colors and size and<br>link it to any webpage.",//info of the popup
      //   "pos_X": 441,//check the X, Y coordinates guide in the documentation
      //   "pos_Y": 797,
      //   "size": 40,//size of the pin
      //   "outline": "#cc2900",//outline color of the pin
      //   "upColor": "#ff6600",//color of the pin when drawing load
      //   "overColor": "#cc2900",//color of the pin when mouse hover
      //   "url": "",//link to any webpage
      //   "target": "new_window",// use "new_window", "null", "modal", or "none"
      //   "enabled": true//true/false to activate/deactivate this pin
      // },
      // {
      //   "hover": "<u><b>Example 'B'</b></u><br>Add unlimited number of spots<br>anywhere on the diagram and<br>customize its colors and size and<br>link it to any webpage.",
      //   "pos_X": 420, "pos_Y": 797,
      //   "size": 40,
      //   "outline": "#FF0000",
      //   "upColor": "rgba(255, 0, 0, 0.3)", "overColor": "rgba(255, 0, 0, 0.7)",
      //   "url": "", "target": "null",
      //   "enabled": true
      // },
      // {
      //   "hover": "<u><b>Example 'B'</b></u><br>Add unlimited number of spots<br>anywhere on the diagram and<br>customize its colors and size and<br>link it to any webpage.",
      //   "pos_X": 1032, "pos_Y": 1085,
      //   "size": 40,
      //   "outline": "#FF0000",
      //   "upColor": "rgba(255, 0, 0, 0.3)", "overColor": "rgba(255, 0, 0, 0.7)",
      //   "url": "", "target": "null",
      //   "enabled": true
      // },
      
      ]
    };

    var spotsconfigb = {
      "pins":[
      // {
      //   "hover": "<u><b>Example 'C'</b></u><br>Add unlimited number of spots<br>anywhere on the diagram and<br>customize its colors and size and<br>link it to any webpage.",//info of the popup
      //   "pos_X": 319,//check the X, Y coordinates guide in the documentation
      //   "pos_Y": 521,
      //   "size": 40,//size of the pin
      //   "outline": "#cc2900",//outline color of the pin
      //   "upColor": "#ff6600",//color of the pin when drawing load
      //   "overColor": "#cc2900",//color of the pin when mouse hover
      //   "url": "",//link to any webpage
      //   "target": "new_window",// use "new_window", "null", "modal", or "none"
      //   "enabled": true//true/false to activate/deactivate this pin
      // },
      // {
      //   "hover": "<u><b>Example 'D'</b></u><br>Add unlimited number of spots<br>anywhere on the diagram and<br>customize its colors and size and<br>link it to any webpage.",
      //   "pos_X": 939, "pos_Y": 413,
      //   "size": 30,
      //   "outline": "#FF0000",
      //   "upColor": "rgba(255, 0, 0, 0.3)", "overColor": "rgba(255, 0, 0, 0.7)",
      //   "url": "", "target": "null",
      //   "enabled": true
      // },
      ]
    };

    var pins_len = spotsconfiga.pins.length;
    if(pins_len > 0) {
      var xmlns = "http://www.w3.org/2000/svg";
      var tsvg_obj = document.getElementById("anaspotsa");
      var svg_circle;
      for (var i = 0; i < pins_len; i++) {
        svg_circle = document.createElementNS(xmlns, "circle");
        svg_circle.setAttributeNS(null, "cx", spotsconfiga.pins[i].pos_X);
        svg_circle.setAttributeNS(null, "cy", spotsconfiga.pins[i].pos_Y);
        svg_circle.setAttributeNS(null, "r", spotsconfiga.pins[i].size / 2);
        svg_circle.setAttributeNS(null, "fill", spotsconfiga.pins[i].upColor);
        svg_circle.setAttributeNS(null, "stroke", spotsconfiga.pins[i].outline);
        svg_circle.setAttributeNS(null, "stroke-width", 1);
        svg_circle.setAttributeNS(null, "id", "anaspotsa_" + i);
        tsvg_obj.appendChild(svg_circle);
        anaspotsaAddEvent(i);
      } 
    }
    function anaspotsaAddEvent(id) {
      var obj = $("#anaspotsa_" + id);
      if(spotsconfiga.pins[id].enabled === true){
        obj.attr({"cursor": "pointer"});
        obj.hover(function () {
          $("#anatip").show().html(spotsconfiga.pins[id].hover);
          obj.css({"fill":spotsconfiga.pins[id].overColor});
        }, function () {
          $("#anatip").hide();
          obj.css({"fill":spotsconfiga.pins[id].upColor});
        });
        obj.mouseup(function(){
          obj.css({"fill":spotsconfiga.pins[id].overColor});
          if (spotsconfiga.pins[id].target === "new_window"){
            window.open(spotsconfiga.pins[id].url);  
          } else if (spotsconfiga.pins[id].target === "null") {
            window.parent.location.href = spotsconfiga.pins[id].url;
          } else if (spotsconfiga.pins[id].target === "modal") {
            $(spotsconfiga.pins[id].url).modal("show");
          }
        });
        obj.mousemove(function (e) {
          var x = e.pageX + 10, y = e.pageY + 15;
          var tipw =$("#anatip").outerWidth(), tiph =$("#anatip").outerHeight(),
          x = (x + tipw >$(document).scrollLeft() +$(window).width())? x - tipw - (20 * 2) : x ;
          y = (y + tiph >$(document).scrollTop() +$(window).height())? $(document).scrollTop() +$(window).height() - tiph - 10 : y ;
          $("#anatip").css({left: x, top: y});
        });
        if (isTouchEnabled()) {
          obj.on("touchstart", function (e) {
            var touch = e.originalEvent.touches[0];
            var x = touch.pageX + 10, y = touch.pageY + 15;
            var tipw=$("#anatip").outerWidth(), tiph=$("#anatip").outerHeight(),
            x = (x + tipw >$(document).scrollLeft() +$(window).width())? x - tipw -(20 * 2) : x ;
            y =(y + tiph >$(document).scrollTop() +$(window).height())? $(document).scrollTop() +$(window).height() -tiph - 10 : y ;
            $("#anatip").show().html(spotsconfiga.pins[id].hover);
            $("#anatip").css({left:x, top:y});
          });
          obj.on("touchend", function () {
            $("#" + id).css({"fill":spotsconfiga.pins[id].upColor});
            if (spotsconfiga.pins[id].target === "new_window") {
              window.open(spotsconfiga.pins[id].url);
            } else if (spotsconfiga.pins[id].target === "null") {
              window.parent.location.href = spotsconfiga.pins[id].url;
            } else if (spotsconfiga.pins[id].target === "modal") {
              $(spotsconfiga.pins[id].url).modal("show");
            }
          });
        }
      }
    }
  
    var pins_len = spotsconfigb.pins.length;
    if(pins_len > 0) {
      var xmlns = "http://www.w3.org/2000/svg";
      var tsvg_obj = document.getElementById("anaspotsb");
      var svg_circle;
      for (var i = 0; i < pins_len; i++) {
        svg_circle = document.createElementNS(xmlns, "circle");
        svg_circle.setAttributeNS(null, "cx", spotsconfigb.pins[i].pos_X);
        svg_circle.setAttributeNS(null, "cy", spotsconfigb.pins[i].pos_Y);
        svg_circle.setAttributeNS(null, "r", spotsconfigb.pins[i].size / 2);
        svg_circle.setAttributeNS(null, "fill", spotsconfigb.pins[i].upColor);
        svg_circle.setAttributeNS(null, "stroke", spotsconfigb.pins[i].outline);
        svg_circle.setAttributeNS(null, "stroke-width", 1);
        svg_circle.setAttributeNS(null, "id", "anaspotsb_" + i);
        tsvg_obj.appendChild(svg_circle);
        anaspotsbAddEvent(i);
      }
    }
    function anaspotsbAddEvent(id) {
      var obj = $("#anaspotsb_" + id);
      if(spotsconfigb.pins[id].enabled === true){
        obj.attr({"cursor": "pointer"});
        obj.hover(function () {
          $("#anatip").show().html(spotsconfigb.pins[id].hover);
          obj.css({"fill":spotsconfigb.pins[id].overColor});
        }, function () {
          $("#anatip").hide();
          obj.css({"fill":spotsconfigb.pins[id].upColor});
        });
        obj.mouseup(function(){
          obj.css({"fill":spotsconfigb.pins[id].overColor});
          if (spotsconfigb.pins[id].target === "new_window"){
            window.open(spotsconfigb.pins[id].url);  
          } else if (spotsconfigb.pins[id].target === "null") {
            window.parent.location.href = spotsconfigb.pins[id].url;
          } else if (spotsconfigb.pins[id].target === "modal") {
            $(spotsconfigb.pins[id].url).modal("show");
          }
        });
        obj.mousemove(function (e) {
          var x = e.pageX + 10, y = e.pageY + 15;
          var tipw =$("#anatip").outerWidth(), tiph =$("#anatip").outerHeight(),
          x = (x + tipw >$(document).scrollLeft() +$(window).width())? x - tipw - (20 * 2) : x ;
          y = (y + tiph >$(document).scrollTop() +$(window).height())? $(document).scrollTop() +$(window).height() - tiph - 10 : y ;
          $("#anatip").css({left: x, top: y});$
        });
        if (isTouchEnabled()) {
          obj.on("touchstart", function (e) {
            var touch = e.originalEvent.touches[0];
            var x = touch.pageX + 10, y = touch.pageY + 15;
            var tipw=$("#anatip").outerWidth(), tiph=$("#anatip").outerHeight(),
            x = (x + tipw >$(document).scrollLeft() +$(window).width())? x - tipw -(20 * 2) : x ;
            y =(y + tiph >$(document).scrollTop() +$(window).height())? $(document).scrollTop() +$(window).height() -tiph - 10 : y ;
            $("#anatip").show().html(spotsconfigb.pins[id].hover);
            $("#anatip").css({left:x, top:y});
          });
          obj.on("touchend", function () {
            $("#" + id).css({"fill":spotsconfigb.pins[id].upColor});
            if (spotsconfigb.pins[id].target === "new_window") {
              window.open(spotsconfigb.pins[id].url);
            } else if (spotsconfigb.pins[id].target === "null") {
              window.parent.location.href = spotsconfigb.pins[id].url;
            } else if (spotsconfigb.pins[id].target === "modal") {
              $(spotsconfigb.pins[id].url).modal("show");
            }
          });
        }
      }
    }

    this.Interact(spotsconfiga,spotsconfigb);
    function isTouchEnabled() {
      return (("ontouchstart" in window)
        // || (navigator.MaxTouchPoints > 0)
        || (navigator.msMaxTouchPoints > 0));
    }
  }

  Interact(spotsconfiga,spotsconfigb){

    const component = this;
    var anaconfig = {
      "default":{
        "outlineColor": "#ff8080"
      },
    /*==========================================================================================*/
    /*==========================================================================================*/
    /*================ !!! CUSTOMIZATION OF THE MALE FIGURE FRONT SIDE !!!  ====================*/
    /*==========================================================================================*/
    /*==========================================================================================*/
      "ana1":{//male-front-head
        "hover": "HEAD",//info of the popup
        "url": "",//link to any webpage
        // "target": "null",// use "new_window", "same_page", "modal" with bootstrap only, or "none"
        "enabled": true//true/false to activate/deactivate
      },
      "ana2":{//male-front-eye-rt
        "hover": "EYE [RT]",
        "url": "", "target": "null",
        // "enabled": true
      },
      "ana3":{//male-front-eye-lt
        "hover": "EYE [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana4":{//male-front-ear-rt
        "hover": "EAR [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana5":{//male-front-ear-lt
        "hover": "EAR [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana6":{//male-front-nose
        "hover": "NOSE",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana7":{//male-front-mouth
        "hover": "MOUTH",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana8":{//male-front-neck
        "hover": "NECK",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana9":{//male-front-chest
        "hover": "CHEST",
        // "url": "#", "target": "null",
        "enabled": true
      },
      "ana10":{//male-front-abdomen
        "hover": "ABDOMEN",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana11":{//male-front-pelvis
        "hover": "PELVIS",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana12":{//male-front-pubis
        "hover": "PUBIS",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana13":{//male-front-shoulder-rt
        "hover": "SHOULDER [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana14":{//male-front-shoulder-lt
        "hover": "SHOULDER [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana15":{//male-front-arm-rt
        "hover": "ARM [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana16":{//male-front-arm-lt
        "hover": "ARM [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana17":{//male-front-arm-lt
        "hover": "ELBOW [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana18":{//male-front-elbow-lt
        "hover": "ELBOW [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana19":{//male-front-forearm-rt
        "hover": "FOREARM [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana20":{//male-front-forearm-lt
        "hover": "FOREARM [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana21":{//male-front-wrist-rt
        "hover": "WRIST [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana22":{//male-front-wrist-lt
        "hover": "WRIST [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana23":{//male-front-hand-rt
        "hover": "HAND [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana24":{//male-front-hand-lt
        "hover": "HAND [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana25":{//male-front-thigh-rt
        "hover": "THIGH [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana26":{//male-front-thigh-lt
        "hover": "THIGH [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana27":{//male-front-knee-rt
        "hover": "KNEE [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana28":{//male-front-knee-lt
        "hover": "KNEE [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana29":{//male-front-leg-rt
        "hover": "LEG [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana30":{//male-front-leg-lt
        "hover": "LEG [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana31":{//male-front-ankle-rt
        "hover": "ANKLE [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana32":{//male-front-ankle-lt
        "hover": "ANKLE [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana33":{//male-front-foot-rt
        "hover": "FOOT [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana34":{//male-front-foot-lt
        "hover": "FOOT [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
    /*============================================================================================*/
    /*============================================================================================*/
    /*================ !!! CUSTOMIZATION OF THE FEMALE FIGURE FRONT SIDE !!!  ====================*/
    /*============================================================================================*/
    /*============================================================================================*/
      "ana35":{//female-front-head
        "hover": "HEAD",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana36":{//female-front-eye-rt
        "hover": "EYE [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana37":{//female-front-eye-lt
        "hover": "EYE [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana38":{//female-front-ear-rt
        "hover": "EAR [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana39":{//female-front-ear-lt
        "hover": "EAR [LT]",
        "url": "", "target": "null",
        "enabled": true
      },
      "ana40":{//female-front-nose
        "hover": "NOSE",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana41":{//female-front-mouth
        "hover": "MOUTH",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana42":{//female-front-neck
        "hover": "NECK",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana43":{//female-front-chest
        "hover": "CHEST",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana44":{//female-front-breasts
        "hover": "BREASTS",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana45":{//female-front-abdomen
        "hover": "ABDOMEN",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana46":{//female-front-pelvis
        "hover": "PELVIS",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana47":{//female-front-pubis
        "hover": "PUBIS",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana48":{//female-front-shoulder-rt
        "hover": "SHOULDER [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana49":{//female-front-shoulder-lt
        "hover": "SHOULDER [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana50":{//female-front-arm-rt
        "hover": "ARM [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana51":{//female-front-arm-lt
        "hover": "ARM [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana52":{//female-front-elbow-rt
        "hover": "ELBOW [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana53":{//female-front-elbow-lt
        "hover": "ELBOW [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana54":{//female-front-forearm-rt
        "hover": "FOREARM [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana55":{//female-front-forearm-lt
        "hover": "FOREARM [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana56":{//female-front-wrist-rt
        "hover": "WRIST [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana57":{//female-front-wrist-lt
        "hover": "WRIST [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana58":{//female-front-hand-rt
        "hover": "HAND [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana59":{//female-front-hand-lt
        "hover": "HAND [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana60":{//female-front-thigh-rt
        "hover": "THIGH [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana61":{//female-front-thigh-lt
        "hover": "THIGH [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana62":{//female-front-knee-rt
        "hover": "KNEE [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana63":{//female-front-knee-lt
        "hover": "KNEE [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana64":{//female-front-leg-rt
        "hover": "LEG [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana65":{//female-front-leg-lt
        "hover": "LEG [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana66":{//female-front-ankle-rt
        "hover": "ANKLE [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana67":{//female-front-ankle-lt
        "hover": "ANKLE [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana68":{//female-front-foot-rt
        "hover": "FOOT [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana69":{//female-front-foot-lt
        "hover": "FOOT [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
    /*=========================================================================================*/
    /*=========================================================================================*/
    /*================ !!! CUSTOMIZATION OF THE MALE FIGURE BACK SIDE !!!  ====================*/
    /*=========================================================================================*/
    /*=========================================================================================*/
      "ana70":{//male-back-head
        "hover": "HEAD",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana71":{//male-back-neck
        "hover": "NECK",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana72":{//male-back-back
        "hover": "BACK",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana73":{//male-back-loin
        "hover": "LOIN",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana74":{//male-back-buttocks
        "hover": "BUTTOCKS",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana75":{//male-back-shoulder-rt
        "hover": "SHOULDER [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana76":{//male-back-shoulder-lt
        "hover": "SHOULDER [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana77":{//male-back-arm-rt
        "hover": "ARM [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana78":{//male-back-arm-lt
        "hover": "ARM [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana79":{//male-back-elbow-rt
        "hover": "ELBOW [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana80":{//male-back-elbow-lt
        "hover": "ELBOW [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana81":{//male-back-forearm-rt
        "hover": "FOREARM [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana82":{//male-back-forearm-lt
        "hover": "FOREARM [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana83":{//male-back-wrist-rt
        "hover": "WRIST [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana84":{//male-back-wrist-lt
        "hover": "WRIST [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana85":{//male-back-hand-rt
        "hover": "HAND [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana86":{//male-back-hand-lt
        "hover": "HAND [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana87":{//male-back-hamstring-rt
        "hover": "HAMSTRING [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana88":{//male-back-hamstring-lt
        "hover": "HAMSTRING [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana89":{//male-back-knee-rt
        "hover": "KNEE [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana90":{//male-back-knee-lt
        "hover": "KNEE [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana91":{//male-back-calf-rt
        "hover": "CALF [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana92":{//male-back-calf-lt
        "hover": "CALF [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana93":{//male-back-ankle-rt
        "hover": "ANKLE [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana94":{//male-back-ankle-lt
        "hover": "ANKLE [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana95":{//male-back-sole-rt
        "hover": "SOLE [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana96":{//male-back-sole-lt
        "hover": "SOLE [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana97":{//male-back-foot-rt
        "hover": "FOOT [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana98":{//male-back-foot-lt
        "hover": "FOOT [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
    /*===========================================================================================*/
    /*===========================================================================================*/
    /*================ !!! CUSTOMIZATION OF THE FEMALE FIGURE BACK SIDE !!!  ====================*/
    /*===========================================================================================*/
    /*===========================================================================================*/
      "ana99":{//female-back-head
        "hover": "HEAD",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana100":{//female-back-neck
        "hover": "NECK",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana101":{//female-back-back
        "hover": "BACK",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana102":{//female-back-loin
        "hover": "LOIN",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana103":{//female-back-buttocks
        "hover": "BUTTOCKS",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana104":{//female-back-shoulder-rt
        "hover": "SHOULDER [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana105":{//female-back-shoulder-lt
        "hover": "SHOULDER [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana106":{//female-back-arm-rt
        "hover": "ARM [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana107":{//female-back-arm-lt
        "hover": "ARM [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana108":{//female-back-elbow-rt
        "hover": "ELBOW [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana109":{//female-back-elbow-lt
        "hover": "ELBOW [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana110":{//female-back-forearm-rt
        "hover": "FOREARM [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana111":{//female-back-forearm-lt
        "hover": "FOREARM [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana112":{//female-back-wrist-rt
        "hover": "WRIST [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana113":{//female-back-wrist-lt
        "hover": "WRIST [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana114":{//female-back-hand-rt
        "hover": "HAND [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana115":{//female-back-hand-lt
        "hover": "HAND [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana116":{//female-back-hamstring-rt
        "hover": "HAMSTRING [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana117":{//female-back-hamstring-lt
        "hover": "HAMSTRING [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana118":{//female-back-knee-rt
        "hover": "KNEE [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana119":{//female-back-knee-lt
        "hover": "KNEE [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana120":{//female-back-calf-rt
        "hover": "CALF [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana121":{//female-back-calf-lt
        "hover": "CALF [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana122":{//female-back-ankle-rt
        "hover": "ANKLE [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana123":{//female-back-ankle-lt
        "hover": "ANKLE [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana124":{//female-back-sole-rt
        "hover": "SOLE [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana125":{//female-back-sole-lt
        "hover": "SOLE [LT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana126":{//female-back-foot-rt
        "hover": "FOOT [RT]",
        // "url": "", "target": "null",
        "enabled": true
      },
      "ana127":{//female-back-foot-lt
        "hover": "FOOT [LT]",
        // "url": "", "target": "null",
        "enabled": true
      }
    };


    $("#baseb").hide().animate({"opacity":"0"}, 100);
    $(".gob").on("click", function(){
      $("#basea").hide().animate({"opacity":"0"}, 100);
      $("#baseb").show().animate({"opacity":"1"}, 100);
      $('#btnUndoFront').css({'display':'none'});
      $('#btnUndoBack').css({'display':'block'});
      component.showFront=false;
      
    });
    $(".goa").on("click", function(){
      $("#baseb").hide().animate({"opacity":"0"}, 100);
      $("#basea").show().animate({"opacity":"1"}, 100);
    
     
      $('#btnUndoBack').css({'display':'none'});
      $('#btnUndoFront').css({'display':'block'});
    });

    $('#btnUndoFront').on('click',function(){

      
      // spotsconfiga.pins.splice(-1,1);
      // console.log(spotsconfiga);

    });
    $('#btnUndoBack').on('click',function(){

      // console.log(spotsconfigb);
      // spotsconfiga.pins.splice(-1,1);
    });

    $('#btnSubmit').on('click',function(){

      localStorage.setItem('spotsconfiga',JSON.stringify(spotsconfiga.pins));
      localStorage.setItem('spotsconfigb',JSON.stringify(spotsconfigb.pins));

    console.log(spotsconfiga.pins);
    });
 
    $("path[id^=ana]").each(function (i, e) {
      // this.anaaddEvent($(e).attr("id"));
  
      var id=$(e).attr("id");
      var arr = id.split("");
      var _obj = $("#" + id + "," + arr.slice().join(""));
      _obj.attr({"fill":"rgba(255, 0, 0, 0)", "stroke":anaconfig.default.outlineColor});
      _obj.attr({"cursor": "default"});

      if (anaconfig[id].enabled === true) {
        _obj.attr({"cursor": "pointer"});
        _obj.hover(function () {
          $("#anatip").show().html(anaconfig[id].hover);
          _obj.css({"fill":"rgba(255, 0, 0, 0.3)"});
        }, function () {
          $("#anatip").hide();
          $("#" + id).css({"fill":"rgba(255, 0, 0, 0)"});
        });
        if (anaconfig[id].target !== "none") {
          _obj.mousedown(function (e) {
            
            $("#" + id).css({"fill":"rgba(255, 0, 0, 0.7)"});

            var x = e.pageX, y = e.pageY;
            
            // Get the cx and cy coordinates
            

            var parent_Div=$("#" + id).parent().parent().attr('id');
            //Add new spot
            var xmlns = "http://www.w3.org/2000/svg";
            var svg_circle;


            if(parent_Div=="basea"){

              console.log($("#" + id));
              console.log(anaconfig[id].hover); 
              var tsvg_obj = document.getElementById("anaspotsa");

              spotsconfiga.pins.push({enabled:true,outline:'#cc2900',overColor: '#cc2900',
              pos_X: x,pos_Y: y,size: 30,upColor: "#ff6600",front:true,description:anaconfig[id].hover });

              var arr_length=spotsconfiga.pins.length;

              svg_circle = document.createElementNS(xmlns, "circle");
              svg_circle.setAttributeNS(null, "cx", x);
              svg_circle.setAttributeNS(null, "cy", y);
              svg_circle.setAttributeNS(null, "r", spotsconfiga.pins[arr_length-1].size / 2);
              svg_circle.setAttributeNS(null, "fill", spotsconfiga.pins[arr_length-1].upColor);
              svg_circle.setAttributeNS(null, "stroke", spotsconfiga.pins[arr_length-1].outline);
              svg_circle.setAttributeNS(null, "stroke-width", 1);
              svg_circle.setAttributeNS(null, "id", "anaspotsa_" + (arr_length-1));
              tsvg_obj.appendChild(svg_circle);
            }
            else if(parent_Div=="baseb"){

              var tsvg_obj = document.getElementById("anaspotsb");

              spotsconfigb.pins.push({enabled:true,outline:'#cc2900',overColor: '#cc2900',
              pos_X: x,pos_Y: y,size: 30,upColor: "#ff6600",front:false});

              var arr_lengthb=spotsconfigb.pins.length;

              svg_circle = document.createElementNS(xmlns, "circle");
              svg_circle.setAttributeNS(null, "cx", x);
              svg_circle.setAttributeNS(null, "cy", y);
              svg_circle.setAttributeNS(null, "r", spotsconfigb.pins[arr_lengthb-1].size / 2);
              svg_circle.setAttributeNS(null, "fill", spotsconfigb.pins[arr_lengthb-1].upColor);
              svg_circle.setAttributeNS(null, "stroke", spotsconfigb.pins[arr_lengthb-1].outline);
              svg_circle.setAttributeNS(null, "stroke-width", 1);
              svg_circle.setAttributeNS(null, "id", "anaspotsa_" + (arr_length-1));
              tsvg_obj.appendChild(svg_circle);
            }

          });
        }
        _obj.mouseup(function () {
          $("#" + id).css({"fill":"rgba(255, 0, 0, 0.3)"});
          if (anaconfig[id].target === "new_window") {
            window.open(anaconfig[id].url); 
          } else if (anaconfig[id].target === "null") {
            window.parent.location.href = anaconfig[id].url;
          } else if (anaconfig[id].target === "modal") {
            $(anaconfig[id].url).modal("show");
          }
        });
        _obj.mousemove(function (e) {
          var x = e.pageX + 10, y = e.pageY + 15;
          var tipw =$("#anatip").outerWidth(), tiph =$("#anatip").outerHeight(),
          x = (x + tipw >$(document).scrollLeft() +$(window).width())? x - tipw - (20 * 2) : x ;
          y = (y + tiph >$(document).scrollTop() +$(window).height())? $(document).scrollTop() +$(window).height() - tiph - 10 : y ;
          $("#anatip").css({left: x, top: y});
          
        });
        if (isTouchEnabled()) {
          _obj.on("touchstart", function (e) {

            var touch = e.originalEvent.touches[0];
            var x = touch.pageX + 10, y = touch.pageY + 15;
            var tipw =$("#anatip").outerWidth(), tiph =$("#anatip").outerHeight(),
            x = (x + tipw >$(document).scrollLeft() +$(window).width())? x - tipw -(20 * 2) : x ;
            y =(y + tiph >$(document).scrollTop() +$(window).height())? $(document).scrollTop() +$(window).height() -tiph - 10 : y ;
            $("#" + id).css({"fill":"rgba(255, 0, 0, 0.7)"});
            $("#anatip").show().html(anaconfig[id].hover);
            $("#anatip").css({left: x, top: y});
          });
          _obj.on("touchend", function () {
            $("#" + id).css({"fill":"rgba(255, 0, 0, 0)"});
            if (anaconfig[id].target === "new_window") {
              window.open(anaconfig[id].url);
            } else if (anaconfig[id].target === "null") {
              window.parent.location.href = anaconfig[id].url;
            } else if (anaconfig[id].target === "modal") {
              $(anaconfig[id].url).modal("show");
            }
          });
        }
      }
    })

    function isTouchEnabled() {
      return (("ontouchstart" in window)
        // || (navigator.MaxTouchPoints > 0)
        || (navigator.msMaxTouchPoints > 0));
    }
  }
}
