import { Component, OnInit,Inject,Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { UseruserRegistrationDialogService } from './useruser-registration-dialog.service';
import { UserRegistrationService } from '../user-registration/user-registration.service';

import { HttpEventType, HttpErrorResponse,HttpEvent } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { of } from 'rxjs';  
import { DatePipe } from '@angular/common';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-user-registration-dialog',
  templateUrl: './user-registration-dialog.component.html',
  styleUrls: ['./user-registration-dialog.component.css']
})
export class UserRegistrationDialogComponent implements OnInit {

  MySelfForm:FormGroup;

  showFileUpload:any;
  DocumentAccess:any;
  
  UploadFor:any;

  files: File[] = [];
  filesToSubmit:string[];


  DependentFormGroup: FormGroup;
  DependentUploadFormGroup: FormGroup;
  isOptional = false;

  DocumentAccess_Dep:any;

  mySelfProgress: number = 0;
  loading:any;

  UserID:any;
  @Output() public onUploadFinished = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<UserRegistrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,private _formBuilder: FormBuilder,
    private service:UseruserRegistrationDialogService,
    private service2:UserRegistrationService,
    private router: Router) {}

    ngOnInit() {
      this.UserID=localStorage.getItem('UserID');
      this.showFileUpload=false;
      this.UploadFor=true;
      this.DocumentAccess=true;
      

      this.MySelfForm = this._formBuilder.group({
        DocumentName: ['', Validators.required],
        ReportDate: ['',Validators.required],
        DocumentAccess:[true],
        ValidFrom:[''],
        ValidTo:['']
      });
      

      this.DependentFormGroup = this._formBuilder.group({

        Relationship:['',Validators.required],
        FirstName:['',Validators.required],
        MiddleName:[''],
        LastName:[''],
        Age:[''],
        Gender:['M']
      });
      this.DependentUploadFormGroup = this._formBuilder.group({
   
        DependentFK:[],
        DocumentName: ['', Validators.required],
        ReportDate: ['',Validators.required],
        DocumentAccess_Dep:[true],
        ValidFrom:[''],
        ValidTo:[''],
      });

    }
    onDropzoneSelect(event) {
      
      this.filesToSubmit=[];
      this.files.push(...event.addedFiles);
      this.uploadFileToServer();

    }

    onDropzoneRemove(event) {
      this.filesToSubmit=[];
      console.log(event);
      this.files.splice(this.files.indexOf(event), 1);
      this.uploadFileToServer();
      
    }
    uploadFileToServer(){

      const formData = new FormData(); 
      for(var i=0;i<this.files.length;i++){
        formData.append("file[]",this.files[i]);
      }
      this.mySelfProgress=50;
      formData.append('UserID',this.UserID);  
      
        this.service.UploadDocuments(formData).subscribe(
          (response:any) => {                           //Next callback
           
            console.log(response);
            if (response !== null) {
             
              if(response.status==1){
                this.mySelfProgress=80;
                for(var i=0;i<response.documentPath.length;i++){
                  this.filesToSubmit.push(response.documentPath[i])
                }
                this.mySelfProgress=0;
              }
            }
          },
          (error) => {                              //Error callback
            
            
          }
      );
      
    
    }
    FormatDate(value: string) {
      var datePipe = new DatePipe("en-US");
       value = datePipe.transform(value, 'dd/MM/yyyy');
       return value;
   }
    SaveUserDocuments(){

      console.log(this.MySelfForm);
      if(this.MySelfForm.status!="INVALID"){
        const body={
          UserID:this.UserID,
          UserType:'S',
          DependentFK:'',
          DocumentName: this.MySelfForm.get('DocumentName').value,
          ReportDate:this.FormatDate(this.MySelfForm.get('ReportDate').value),
          DocumentAccess:(this.DocumentAccess==true)? 'A':'C',
          ValidFrom:this.FormatDate(this.MySelfForm.get('ValidFrom').value),
          ValidTo:this.FormatDate(this.MySelfForm.get('ValidTo').value),
          documentPath:(this.filesToSubmit!=null)?this.filesToSubmit:[],
         } ;
  
        if(body.DocumentAccess=='C'){
           if(body.ValidFrom==''||body.ValidFrom==null){
             return;
           }
           else if(body.ValidTo==''||body.ValidTo==null){
             return;
           }
           else if(this.MySelfForm.get('ValidFrom').value>this.MySelfForm.get('ValidTo').value){
            return;
          }
        }
        
        this.loading=true; 
        this.service.SaveUserDocuments(body).subscribe((resp: any) => {
          
          
          this.loading=false;
          this.dialogRef.close();

          if(this.service2.isPatient){
            this.router.navigate(['secondopinion/dashboard']);
          }
          else if(this.service2.isDoctor){
            this.router.navigate(['doctor/_dashboard']);
          }
          else{
            this.router.navigate(['login']);
          }
          
          
        }, error => {
          this.loading=false;
        });
      }
       
      
      // this.dialogRef.close();
    }
    
    SaveDependent(form:FormGroup,stepper: MatStepper){

    
      if(form.status=="VALID"){
        const body = {
          UserID:this.UserID,
          Relationship: form.value.Relationship,
          FirstName: form.value.FirstName,
          LastName: form.value.LastName,
          MiddleName: form.value.MiddleName,
          Age: form.value.Age,
          Gender: form.value.Gender,
        };
    
        console.log(body);
        this.loading=true;
        this.service.AddDependent(body).subscribe(
          (response:any) => {                           //Next callback
           
            console.log(response);
            if (response !== null) {
              if(response.status==1){

                this.DependentUploadFormGroup.setValue({
                    DependentFK: response.dependentID,
                    DocumentName: [],
                    ReportDate: [],
                    DocumentAccess_Dep:[true],
                    ValidFrom:[''],
                    ValidTo:[''],
                });
                this.DocumentAccess_Dep=true;
                this.loading=false;
                stepper.next();
              }
              
            }
            
          },
          (error) => {                              //Error callback
            
            this.loading=false;
          }
        )
      }
      
    }
    SaveDependentDocuments(){

      console.log(this.DependentUploadFormGroup);
     
      
      const body={
        UserID:this.UserID,
        UserType:'D',
        DependentFK:this.DependentUploadFormGroup.get('DependentFK').value,
        DocumentName: this.DependentUploadFormGroup.get('DocumentName').value,
        ReportDate: this.FormatDate(this.DependentUploadFormGroup.get('ReportDate').value),
        DocumentAccess:(this.DocumentAccess_Dep==true)? 'A':'C',
        ValidFrom:(this.DependentUploadFormGroup.get('ValidFrom').value=="")?"": this.FormatDate(this.DependentUploadFormGroup.get('ValidFrom').value),
        ValidTo:(this.DependentUploadFormGroup.get('ValidTo').value=="")?"":this.FormatDate(this.DependentUploadFormGroup.get('ValidTo').value),
        documentPath:(this.filesToSubmit!=null)?this.filesToSubmit:[],
       } ;

        if(body.DocumentName==''||body.DocumentName==null){
          return;
        }
        else if(body.ReportDate==''||body.ReportDate==null){
          return;
        }
        else if(body.DocumentAccess=='C'){
           if(body.ValidFrom==''||body.ValidFrom==null){
             return;
           }
           else if(body.ValidTo==''||body.ValidTo==null){
             return;
           }
           else if(body.ValidFrom>body.ValidTo){
            return;
          }
         }
        
        this.loading=true; 
        this.service.SaveUserDocuments(body).subscribe((resp: any) => {
          
          
          this.loading=false;
          if(resp!=null){

            if(resp.status==1){
            
              this.dialogRef.close();
              if(this.service2.isPatient){
                this.router.navigate(['secondopinion/dashboard']);
              }
              else if(this.service2.isDoctor){
                this.router.navigate(['doctor/_dashboard']);
              }
              else{
                this.router.navigate(['login']);
              }
              
            }
          }
         
          
        }, error => {
          this.loading=false;
        });

    
      // this.dialogRef.close();
    }
  onNoClick(): void {

    this.router.navigate(['secondopinion/dashboard']);
    this.dialogRef.close();
  }
  ShowDocumentUpload(){

  }
  onUploadForClick(){
    this.files=[];
    this.filesToSubmit=[];
  }
  // goForward(stepper: MatStepper){

  //   alert(11);
  //   stepper.next();
  // }

}
