import { Component, OnInit,Inject ,ViewEncapsulation} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { PatientListService } from '../patient-list/patient-list.service';
@Component({
  selector: 'app-view-questionnaire-report',
  templateUrl: './view-questionnaire-report.component.html',
  styleUrls: ['./view-questionnaire-report.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewQuestionnaireReportComponent implements OnInit {

  userID:number;
  htmlText:any;
  reportLoaded:boolean=true;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<ViewQuestionnaireReportComponent>,
  private service:PatientListService) {

    this.userID=data.userID;

   }

  ngOnInit(): void {

    this.getQuestionnaireReport();
  }
  getQuestionnaireReport(){

    this.service.getQuestionnaireReportByUserID(this.userID).subscribe((res:any)=>{

      this.reportLoaded=false;
      if(res.status==1){
        this.htmlText=res.report;

      }
    });
  }
  closeDialog(){
    this.dialogRef.close();
  }
}
