import { Component, OnInit ,ViewChild,Renderer2  } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { AddAppointmentService } from './add-appointment.service';
import {  MatSort } from "@angular/material/sort";
import { MatStepper } from '@angular/material/stepper';
import { MatDatepicker,MatDatepickerInputEvent} from '@angular/material/datepicker';

import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router,NavigationEnd} from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FullcalendarComponent } from '../fullcalendar/fullcalendar.component';

import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';

import { QuestionnaireDialogComponent } from '../questionnaire-dialog/questionnaire-dialog.component';
import { element } from 'protractor';
import { environment } from '../../environments/environment';

declare var $: any; 


@Component({
  selector: 'app-add-appointment',
  templateUrl: './add-appointment.component.html',
  styleUrls: ['./add-appointment.component.css']
})
export class AddAppointmentComponent implements OnInit {

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  UserID:any;
  stepper: MatStepper;
  loading:any;

  _name:any;
  _relation:any;

  events: string[] = [];
  dateFilter:any;

  DEPENDENT_LIST: DependentInfo[] = [];
  displayedColumns: string[] = [ 'name', 'relationship', 'age'];
  _dataSource : any;
  users: DependentInfo[] = [];
  collectionSize: number;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  
  communicationTypes :CommunicationType[]=[];
  unAvailableDates :UnAvailableDates[]=[];
  timeSlots:TimeSlot[]=[];

  _AvailableDate:any;
  _CommunicationTypeID:any;

  myFilter:any;

  isSelfDependent:any;
  userID:any; 
  dependentID:any;
  selectedTime:any;
  selectedDate:any='Choose Date';
  remarks:string;

 
  DisabledDate_Temp: Array<number> = [];
  
  
  public payPalConfig?: IPayPalConfig;
  amount:any=0;

  successFlag:boolean=false;

  consultationFor_Id:any;
  patientNumber:string;

  paypalClientId:any;

  constructor(private _formBuilder: FormBuilder,private service:AddAppointmentService,
    private router: Router,private renderer:Renderer2,private toastr: ToastrService,
    public dialog: MatDialog) {
    
      

    this.getUserForList();

  }
    ngOnInit() {

      this.paypalClientId=environment.paypalClientId;


      this.UserID=localStorage.getItem("UserID");
      this.loading=false;
      this.users = [];
      this._CommunicationTypeID=0;
      this.communicationTypes=[];

      this.firstFormGroup = this._formBuilder.group({
      
      });
      this.secondFormGroup = this._formBuilder.group({
        
        doctorAvailabilityDetailID:['',Validators.required],
        CommunicationTypeID:[],
        remarks:[],
        AvailableDate:[{value: '', disabled:true}],
   
      });

    this.getCommunicationTypeList();

    this.DisabledDate_Temp=[];
    this.setUnAvailableDates();

    this.initConfig();
  }

  openDatePicker() {
    if(this._CommunicationTypeID!=0){
      let dialogRef1 = this.dialog.open(FullcalendarComponent, {
        data: this._CommunicationTypeID,
        width: '400px',
          // disableClose: true,
      })
  
      dialogRef1.afterClosed().subscribe(res => {
        if(res!=undefined){

          this._AvailableDate=this.FormatDate(res.data.toString());
          this.selectedDate=this._AvailableDate;
          this.getAvailableTimeslots();
        }
      
      })
    }
 
}

  setUnAvailableDates(){
  
    this.myFilter = (date) => {
      // return [ 5, 10, 21,26].indexOf(+date.getDate()) == -1;
      return this.DisabledDate_Temp.indexOf(+date.getDate()) == -1;
    };
  }

  FormatDate(value: string) {
     var datePipe = new DatePipe("en-US");
     value = datePipe.transform(value, 'dd-MM-yyyy');
     return value;
  }
  getUserForList(){
    this.loading=true;
    this.users = [];
    this.UserID=localStorage.getItem("UserID");
    this.service.getUserForList(this.UserID).subscribe((resp: any) => { 
        console.log('Dependent list get : ' +resp.message);
        
        this.users.push(...resp.dependentList);

        this._dataSource = new MatTableDataSource(this.users);

        this._dataSource.paginator = this.paginator;
        this._dataSource.sort = this.sort;
        this.collectionSize = this.users.length;
        this.loading=false;
      });
  }
  
  getCommunicationTypeList(){
    
    this.service.getCommunicationTypeList().subscribe((resp: any) => { 
      console.log('Communication list get : ' +resp.message);
      
      this.communicationTypes.push(...resp.communicationTypeList);

    });
  }

 
  getUnavailableDates(){
    
    this.selectedTime=null;
    this.selectedDate=null;
    this.timeSlots=[];

    this.DisabledDate_Temp=[];
    this.unAvailableDates=[];
    this.service.getUnavailableDates(this._CommunicationTypeID).subscribe((resp: any) => { 
      console.log('UnAvailable date list get : ' +resp.message);
      
      this.unAvailableDates.push(...resp.unavailableDates);
      
      for(var i=0;i<this.unAvailableDates.length;i++){
    
        this.DisabledDate_Temp.push(Number(this.unAvailableDates[i].date));
      }
      this.setUnAvailableDates();

    });
  }
  getAvailableTimeslots(){

    this.service.getAvailableTimeslots(this._AvailableDate,this._CommunicationTypeID).subscribe((resp: any) => { 
      console.log('Available time list get : ' +resp.message);
      this.timeSlots=[];
      if(resp!=null){

        if(resp.status==1){

          this.timeSlots.push(...resp.availableTimes);
          
        }
      }

    });
  }
  selectUser(isSelfDependent,userID,name,relationship,stepper:MatStepper){
    this._name=name;
    this._relation=relationship;
    this.UserID=userID;

    this.consultationFor_Id=userID;
    localStorage.setItem('consultationFor_Id',this.consultationFor_Id);

    stepper.next();
    
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {

    this._AvailableDate=this.FormatDate(event.value.toString());
    this.getAvailableTimeslots();
  }
  LoadQuestionnaire(){

    let dialogRef1 = this.dialog.open(QuestionnaireDialogComponent, {
      width: '800px',
        // disableClose: true,
    })

    dialogRef1.afterClosed().subscribe(res => {
      if(res!=undefined){
   
      }
    
    })
                  
  }

  paymentID:any='NONETWORK123';
  paymentState:any='approved';
  paymentResponse_type:any='payment';

  consultation_time:any='';
  consultation_date:any='';

  SaveAppointment(){

    var date=new Date();

    if(this.secondFormGroup.status=="VALID"){
      this.loading=true;
      const body={
        PatientType:(this.isSelfDependent==true)?'S':'D',
        ReferenceID:this.consultationFor_Id,
        DoctorAvailabilityDetailID:this.selectedTime,
        Remarks:this.remarks,
        PaymentID:this.paymentID,
        PaymentState:this.paymentState,
        PaymentResponse_type:this.paymentResponse_type,
        PaymentAmount:this.amount,
        PaymentCreate_time:date,
        
      };
   
  
      
      this.service.SaveAppointment(body).subscribe(
        (response:any) => {     
          if(response!=null){
            this.loading=false;

            if(response.status==1){
              this.patientNumber=response.data.patientNumber;
              this.toastr.success(response.message);
              this.successFlag=true;
              this.timeSlots.forEach(element=>{

                if(element.doctorAvailabilityDetailID==this.selectedTime){
                  this.consultation_time=element.timeFrom+' to ' +element.timeTo;
                }
             
              });
          
              this.consultation_date=this.selectedDate;
              this.selectedDate='Choose Date';
              this.selectedTime=null;
              this.timeSlots=[];
            }
          }
      });
    
    }
   

  }
  resetBooking(stepper:MatStepper){

    this.successFlag=false;
    stepper.reset();
    this.dependentID="";
    this.isSelfDependent=true;
    this.selectedTime=0;
    this.remarks="";
    
    this.secondFormGroup = this._formBuilder.group({
      
      doctorAvailabilityDetailID:['',Validators.required],
      CommunicationTypeID:[],
      remarks:[],
      AvailableDate:['']
    });
  }
  changeCommunicationType(amount){

    this.amount=amount.toFixed(2);

    this.selectedDate='Choose Date';
    this.timeSlots=[];
  }


  private initConfig(): void {

    console.log( this.paypalClientId);

    this.payPalConfig = {
    currency: 'INR',
    clientId: this.paypalClientId,
    createOrderOnClient: (data) => <ICreateOrderRequest>{
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code: 'INR',
            value: this.amount,
            breakdown: {
              item_total: {
                currency_code: 'INR',
                value: this.amount
              }
            }
          },
          items: [
            {
              name: 'Enterprise Subscription',
              quantity: '1',
              category: 'DIGITAL_GOODS',
              unit_amount: {
                currency_code: 'INR',
                value: this.amount,
              },
            }
          ]
        }
      ]
    },
    advanced: {
      commit: 'true'
    },
    style: {
      label: 'paypal',
      layout: 'vertical'
    },
    onApprove: (data, actions) => {
      console.log('onApprove - transaction was approved, but not authorized', data, actions);
      actions.order.get().then(details => {
        console.log('onApprove - you can get full order details inside onApprove: ', details);

        this.paymentID=details.id;
        this.paymentState=details.status;
    
        this.SaveAppointment();

      });
    },
    onClientAuthorization: (data) => {
      console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
    //   this.showSuccess = true;
    },
    onCancel: (data, actions) => {
      console.log('OnCancel', data, actions);
    },
    onError: err => {
      console.log('OnError', err);
    },
    onClick: (data, actions) => {
      console.log('onClick', data, actions);
    },
  };
  }


}
export interface DependentInfo {

  userID: number;
  dependentID: number;
  firstName: string;
  gender: string;
  age: string;
  relationship: string;
  isSelfDependent:boolean;
}
export interface Food {
  value: string;
  viewValue: string;
}
export interface TimeSlot {
  doctorAvailabilityDetailID: number;
  timeFrom: string;
  timeTo: string;
}
export interface CommunicationType {

  communicationTypeID: number;
  communicationType: string;
}
export interface UnAvailableDates {
  date: string;
}
