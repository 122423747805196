import { Component, OnInit,ViewChild } from '@angular/core';
import { NewAppointmentService } from './new-appointment.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {TodayAppointmentDetailsComponent  } from '../today-appointment-details/today-appointment-details.component';
import {MatDialog} from '@angular/material/dialog';
import { ViewQuestionnaireReportComponent} from '../view-questionnaire-report/view-questionnaire-report.component';
@Component({
  selector: 'app-new-appointment',
  templateUrl: './new-appointment.component.html',
  styleUrls: ['./new-appointment.component.css']
})
export class NewAppointmentComponent implements OnInit {
  
  appointmentList:any[]=[];
  loading:boolean=false;
  displayedColumns: string[] = ['patientid','name','email', 'mobileNumber','appointmentFrom','consultationStatus','button1','button2'];
  _dataSource : any;
  collectionSize: number;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  tableView:boolean=false;
  constructor(private service:NewAppointmentService,public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getNewAppointments();
  }
  getNewAppointments(){
    this.loading=true;
    this.service.getNewAppointments().subscribe((res:any)=>{
      this.appointmentList=[];
      this.appointmentList.push(...res.list);
      console.log(this.appointmentList);

      this._dataSource = new MatTableDataSource(this.appointmentList);

      this._dataSource.paginator = this.paginator;
      this._dataSource.sort = this.sort;
      this.collectionSize = this.appointmentList.length;

      this.loading=false;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this._dataSource.filter = filterValue.trim().toLowerCase();
  }
  openDetailBox(patientID){


    const dialogRef = this.dialog.open(TodayAppointmentDetailsComponent, {
      width: '900px',
      disableClose: false,
              data:patientID
    });

    dialogRef.afterClosed().subscribe(result => {
     
      
    });
  }
  viewQuestionnaire(el){

    const dialogRef = this.dialog.open(ViewQuestionnaireReportComponent, {
      width: '900px',
      disableClose: false,
              data:{  
                userID:el.userID
              }
    });

    dialogRef.afterClosed().subscribe(result => {
     
      
    });
  }
}
