import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmQuestionService {

  baseUrl: any;
  constructor(private http: HttpClient) { 
    this.baseUrl = environment.url;
  }
  SaveQuestionnaire(data: any) {

    return this.http.post(this.baseUrl+'api/Questionnaire/saveQuestionnaire',data).pipe(
      catchError(errorRes=>{
        
        const errorMessage='An Error Occured';
        if (!errorRes.error || !errorRes.error.error) {
          
          return throwError(errorMessage);

        }
      }),
    );
   
  }
}
