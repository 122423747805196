import { Component, OnInit,ViewChild } from '@angular/core';
import {RequestHistoryService} from './request-history.service';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { RequestHistoryDetailsComponent } from '../../Doctor/request-history-details/request-history-details.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';


import {TodayAppointmentDetailsComponent  } from '../today-appointment-details/today-appointment-details.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-request-history',
  templateUrl: './request-history.component.html',
  styleUrls: ['./request-history.component.css']
})
export class RequestHistoryComponent implements OnInit {

  appointmentList:any[]=[];
  loading:boolean=false;

  displayedColumns: string[] = ['name','email', 'mobileNumber','appointmentFrom','consultationStatus','communicationType','button1','button2'];
  _dataSource : any;
  collectionSize: number;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  cardView:boolean=true;
  items = [];

  constructor(private service:RequestHistoryService,private _bottomSheet: MatBottomSheet,public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAppointmentHistory();
  }
  openBottomSheet(mode,patientID,consultationStatusID,userID): void {

    // this._bottomSheet.open(RequestHistoryDetailsComponent,
    //   {
    //     data:{  
    //             mode:mode,
    //             patientID:patientID,
    //             consultationStatusID:consultationStatusID,
    //             userID:userID
    //           }
    //   });
    //   this._bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(x => {
        
    //     this.getAppointmentHistory();
    //   });


    const dialogRef = this.dialog.open(RequestHistoryDetailsComponent, {
      width: '900px',
      disableClose: false,
              data:{  
                mode:mode,
                patientID:patientID,
                consultationStatusID:consultationStatusID,
                userID:userID
              }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAppointmentHistory();
      
    });
  }
  getAppointmentHistory(){
    this.loading=true;
    this.service.getAppointmentHistory().subscribe((res:any)=>{
      this.appointmentList=[];
      this.appointmentList.push(...res.list);

      this.items=this.appointmentList;
      this.loading=false;

      this._dataSource = new MatTableDataSource(this.appointmentList);

      this._dataSource.paginator = this.paginator;
      this._dataSource.sort = this.sort;
      this.collectionSize = this.appointmentList.length;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this._dataSource.filter = filterValue.trim().toLowerCase();
  }
  editPatient(el){

    this.openBottomSheet('Edit',el.patientID,el.consultationStatusID,el.userID);
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.appointmentList = pageOfItems;
  }
  openDetailBox(patientID){


    const dialogRef = this.dialog.open(TodayAppointmentDetailsComponent, {
      width: '900px',
      disableClose: false,
              data:patientID
    });

    dialogRef.afterClosed().subscribe(result => {
     
      
    });
  }
}
