import { Component, OnInit } from '@angular/core';
import { element } from 'protractor';
import { DependencyCircleService } from './dependency-circle.service';
@Component({
  selector: 'app-dependency-circle',
  templateUrl: './dependency-circle.component.html',
  styleUrls: ['./dependency-circle.component.css']
})
export class DependencyCircleComponent implements OnInit {

  loading:any;
  UserID:any;
  DependencyList:DependentInfo[]=[];
  constructor(private service:DependencyCircleService) { }

  ngOnInit() {
    
    this.UserID=localStorage.getItem('UserID');
    this.getDependentList();
  }
  getDependentList(){
  this.loading=true;
  this.DependencyList=[];  
  this.service.getDependentList(this.UserID).subscribe((resp: any) => { 
  
      console.log('Dependent circle list get : ' +resp.message);
      resp.dependentList.forEach(element=>{

   
        if(!element.isSelfDependent){
          this.DependencyList.push(element);
        }
      });

      this.loading=false;
    });
  }
}
export interface DependentInfo{
  userID:number;
  dependentID:string;
  firstName:string;
  gender:string;
  lastName:string;
  middleName:string;
  relationship:string;
  age:string;
  isSelfDependent:boolean;
}
