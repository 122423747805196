import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FullcalendarService {

  
  baseUrl: any;
  constructor(private http: HttpClient) { 
    this.baseUrl = environment.url;
  }

  getAvailableDates(id) {
    console.log(this.baseUrl + `api/booking/get_doctor_available_date?CommunicationTypeID=`+id);
    return this.http.get(this.baseUrl + `api/booking/get_doctor_available_date?CommunicationTypeID=`+id);
  }
}
