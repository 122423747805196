import { Component, OnInit } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';

declare function PayPalPostMessageToReact(data) : any;

@Component({
  selector: 'app-paypal-webview',
  templateUrl: './paypal-webview.component.html',
  styleUrls: ['./paypal-webview.component.css']
})
export class PaypalWebviewComponent implements OnInit {

  constructor(private routeParams: ActivatedRoute) { }

  
  public payPalConfig?: IPayPalConfig;
  amount:any=0.00;
  currency:string;
  ngOnInit(): void {
    this.currency = this.routeParams.snapshot.params['id1']; 
    this.amount = this.routeParams.snapshot.params['id2']; 
    this.initConfig();

    this.amount=Number(this.amount).toFixed(2);

  }

  private initConfig(): void {
    this.payPalConfig = {
    currency:  this.currency,
    clientId: environment.paypalClientId,
    createOrderOnClient: (data) => <ICreateOrderRequest>{
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code:  this.currency,
            value: this.amount,
            breakdown: {
              item_total: {
                currency_code:  this.currency,
                value:this.amount
              }
            }
          },
          items: [
            {
              name: 'Enterprise Subscription',
              quantity: '1',
              category: 'DIGITAL_GOODS',
              unit_amount: {
                currency_code:  this.currency,
                value: this.amount,
              },
            }
          ]
        }
      ]
    },
    advanced: {
      commit: 'true'
    },
    style: {
      label: 'paypal',
      layout: 'vertical'
    },
    onApprove: (data, actions) => {
      console.log('onApprove - transaction was approved, but not authorized', data, actions);
      actions.order.get().then(details => {
        console.log('onApprove - you can get full order details inside onApprove: ', details);

        PayPalPostMessageToReact(details);
      });
    },
    onClientAuthorization: (data) => {
      console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
      PayPalPostMessageToReact(data);
    },
    onCancel: (data, actions) => {
      console.log('OnCancel', data, actions);
      PayPalPostMessageToReact(data);
    },
    onError: err => {
      console.log('OnError', err);
      PayPalPostMessageToReact(err);
    },
    onClick: (data, actions) => {
      console.log('onClick', data, actions);
      PayPalPostMessageToReact(data);
    },
  };
  }

}
