import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  baseUrl: any;
  constructor(private http: HttpClient) { 
    this.baseUrl = environment.url;
  }
  GetDoctorDashboard(UserID){
    return this.http.get(this.baseUrl+'api/User/get_user_dashboard?UserID='+UserID);
  }
 
}
