import { Component, OnInit } from '@angular/core';
import { BookingHistoryService } from './booking-history.service';
@Component({
  selector: 'app-booking-history',
  templateUrl: './booking-history.component.html',
  styleUrls: ['./booking-history.component.css']
})
export class BookingHistoryComponent implements OnInit {

  UserID:any;
  HistoryList: BookingHistory[] = [];
  consultationList:any[]=[];

  constructor(private service:BookingHistoryService) { }

  ngOnInit() {

    this.UserID=localStorage.getItem('UserID');
    this.HistoryList=[];
    this.getConsultationStatus();
    this.GetBookingHistory();
    
  }
  GetBookingHistory(){

    this.service.GetBookingHistory(this.UserID).subscribe((resp: any) => { 
      console.log('Booking history get : ' +resp.message);
      
      
      this.HistoryList.push(...resp.consulationDetailsList);
      console.log( this.HistoryList);
    });

  }
 getConsultationStatus(){
    this.service.getConsultationStatus().subscribe((res:any)=>{
      this.consultationList=[];
      this.consultationList.push(...res.list);

      console.log(this.consultationList);
    });


  }
}
export interface BookingHistory {

  patientID:number;
  name: string;
  consultationStatus: string;
  relationship:string;
  consultationOn:string;
  communicationType:string;
  documentAvailable:boolean;
}
