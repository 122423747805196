import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class BookingHistoryService {

  baseUrl: any;
  constructor(private http: HttpClient) { 
    this.baseUrl = environment.url;
  }

  GetBookingHistory(id) {
    return this.http.get(this.baseUrl + `api/patient/get_consultation_details?UserID=`+id);
  }
  getConsultationStatus(){
    return this.http.get(this.baseUrl+'api/Doctor/get_consultation_status');
  }
}
