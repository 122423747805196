import { Component, OnInit ,Inject} from '@angular/core';
import { RequestHistoryService } from '../../Doctor/request-history/request-history.service';
import {MAT_BOTTOM_SHEET_DATA,MatBottomSheet} from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

declare var $:any;
@Component({
  selector: 'app-request-history-details',
  templateUrl: './request-history-details.component.html',
  styleUrls: ['./request-history-details.component.css']
})
export class RequestHistoryDetailsComponent implements OnInit {

  consultationList:any[]=[];
  files: File[] = [];
  filesToSubmit:string[];
  consulationInfo:ConsulationInfo=new ConsulationInfo();
  requestHistoryInfo:RequestHistoryInfo=new RequestHistoryInfo();
  consultationStatusID:any;

  constructor(private service:RequestHistoryService,@Inject(MAT_DIALOG_DATA) public data: any,public _snackBar:MatSnackBar,private bottomSheet: MatBottomSheet,
  public dialogRef: MatDialogRef<RequestHistoryDetailsComponent>) { 

    this.requestHistoryInfo=data;

  }

  ngOnInit(): void {
    this.files=[];
    this.filesToSubmit=[];

    this.getConsultationStatus();



  }

  onDropzoneSelect(event) {

    this.filesToSubmit=[];
    this.files.push(...event.addedFiles);
    this.uploadFileToServer();

  }
  onDropzoneRemove(event) {
    this.filesToSubmit=[];
      
    this.files.splice(this.files.indexOf(event), 1);
    this.uploadFileToServer();
  }
  getConsultationStatus(){
    this.service.getConsultationStatus().subscribe((res:any)=>{
      this.consultationList=[];
      this.consultationList.push(...res.list);

    console.log(this.consultationList);

 
    });

    this.consulationInfo.consultationStatusID=this.requestHistoryInfo.consultationStatusID;
    this.consulationInfo.patientID=this.requestHistoryInfo.patientID;
    this.consulationInfo.consultationRemarks='';

  }
  uploadFileToServer(){

    const formData = new FormData(); 
    for(var i=0;i<this.files.length;i++){
      formData.append("file[]",this.files[i]);
    }
    formData.append('UserID',this.requestHistoryInfo.patientID.toString());  

  


      this.service.UploadDocuments(formData).subscribe(
        (response:any) => {                           //Next callback
         console.log(response);
        
          if (response !== null) {

            if(response.status=="1"){
              for(var i=0;i<response.documentPath.length;i++){
                this.filesToSubmit.push(response.documentPath[i])
              }
            }
          }
        },
        (error) => {                              //Error callback
          
          
        }
    );
    
  
  }

  AddConsultationDetails(){
    const body={
      PatientID:this.consulationInfo.patientID,
      ConsultationStatusID:this.consulationInfo.consultationStatusID,
      ConsultationRemarks:this.consulationInfo.consultationRemarks,
      CompleteDate:this.FormatDate(this.consulationInfo.completeDate),
      DocumentPath:this.filesToSubmit
    }

    if(body.ConsultationStatusID==5 && body.CompleteDate==null){

      this.openSnackBar('Please select consulation completed date','');
      return;
    }
    
    this.service.AddConsultaionDetails(body).subscribe((resp: any) => {

      if(resp!=null){
        if(resp.status==1){
          this.openSnackBar('Successfully added','');
        }
      }
      setTimeout(() => {
        console.log(1);
       this.CloseBottomSheet();
      }, 1000); 
    }, error => {
    
    });
 
  }
  CloseBottomSheet(){

    // let sheetRef = this.bottomSheet.open(RequestHistoryDetailsComponent);
    // // closing bottomSheet
    // sheetRef.dismiss();

    this.dialogRef.close();
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  FormatDate(value: string) {
    var datePipe = new DatePipe("en-US");
    value = datePipe.transform(value, 'MM-dd-yyyy');
    return value;
 }
}

export class ConsulationInfo{
  consultationStatusID:number;
  consultationRemarks:string;
  patientID:number;
  completeDate:string;
}
export class RequestHistoryInfo{
  patientID:number;
  consultationStatusID:number;
  userID:number;
  mode:string;
}

