import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatCalendarCellCssClasses, MatCalendar } from '@angular/material/datepicker';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FullcalendarService } from './fullcalendar.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-fullcalendar',
  templateUrl: './fullcalendar.component.html',
  styleUrls: ['./fullcalendar.component.css'],
})
export class FullcalendarComponent implements OnInit {

  @ViewChild('calendar', {static: false})  calendar: MatCalendar<Date>;
  selectedDate: any;  
  minDate: string | null = null;
  // datesToHighlight = ["2020-08-1", "2020-08-4", "2020-08-6", "2020-08-9", "2020-08-10", "2020-08-11", "2020-08-12", "2020-08-13", "2020-08-14", "2020-08-15", "2020-08-16", "2020-08-17", "2020-08-18", "2020-08-19", "2020-08-20", "2020-08-29", "2020-08-30", "2020-08-31"];
  datesToHighlight=[];
  _CommunicationTypeID:any;

  AvailableDates :AvailableDates[]=[];
  constructor(public dialogRef: MatDialogRef<FullcalendarComponent>,@Inject(MAT_DIALOG_DATA) public data: string,
  private service:FullcalendarService,private _snackBar: MatSnackBar) { 

    this._CommunicationTypeID=this.data;
    
    this.getAvailableDates();

   }

  ngOnInit() {

  
  }
  closeModal(){
    this.dialogRef.close();
  }
  cancel() {

    this.dialogRef.close({ data: 'your data' }) // send data to parent component
  }

  onSelect(event){

    this.selectedDate = event;    
    this.dialogRef.close({ data:this.selectedDate });

  }

  dateClass() {
  
    return (date: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.datesToHighlight
        .map(strDate => new Date(strDate))
        .some(d => d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear());
      
      return highlightDate ? 'special-date' : 'disabled-date';
    };
  }
  getAvailableDates(){

    this.AvailableDates=[];
    this.service.getAvailableDates(this._CommunicationTypeID).subscribe((resp: any) => { 
      console.log('Available date list get : ' +resp.message);
      if(resp.availableDates!=undefined){
        this.AvailableDates.push(...resp.availableDates);
        for(var i=0;i<this.AvailableDates.length;i++){
          this.datesToHighlight.push(this.AvailableDates[i].date);
        }
      }
      else{
        this.dialogRef.close();
        this.openSnackBar('No dates available','');
      }
    });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
export interface AvailableDates {
  date: string;
}
