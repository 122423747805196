import { Component, OnInit,Inject ,ChangeDetectorRef } from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA,MatBottomSheet} from '@angular/material/bottom-sheet';
import {  AddTimeslotedService} from '../add-timeslote/add-timeslote.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-timeslot-details',
  templateUrl: './timeslot-details.component.html',
  styleUrls: ['./timeslot-details.component.css']
})
export class TimeslotDetailsComponent implements OnInit {

  communicationType:string;
  eventDate:string;
  allocatedSlots:any[]=[];

  formatedDate:any;
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,private service:AddTimeslotedService,private changeDetectorRef: ChangeDetectorRef) { 

    this.communicationType=data.communicationType;
    this.eventDate=data.eventDate;
    this.formatedDate=this.FormatDateToString(this.eventDate);
  }

  ngOnInit(): void {
    this.getAllocatedTimeSlotDetails();

  }
  getAllocatedTimeSlotDetails(){
    this.allocatedSlots=[];
    this.service.getAllocatedTimeSlotDetails(this.eventDate,this.communicationType).subscribe((res:any)=>{

      this.allocatedSlots.push(...res.list);
      this.changeDetectorRef.detectChanges();
      console.log(this.allocatedSlots);
    });
  }
  FormatDateToString(value: string) {
    var datePipe = new DatePipe("en-US");
    value = datePipe.transform(value, 'dd MMM yyyy');
    return value;
  }
}
