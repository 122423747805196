import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { LayoutComponentsModule} from './layouts/layout-components/layout-components.module';
import { SecondOpinionLayoutComponent } from './layouts/second-opinion-layout/second-opinion-layout.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';


import { MatButtonModule} from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToastrModule } from 'ngx-toastr';
import {MatDialogModule} from '@angular/material/dialog';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxDropzoneModule } from 'ngx-dropzone';
import {MatExpansionModule} from '@angular/material/expansion';
import { UserRegistrationDialogComponent } from './user-registration-dialog/user-registration-dialog.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';

import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import {ImageMapperComponent } from './image-mapper/image-mapper.component';
import { ConfirmQuestionComponent } from './confirm-question/confirm-question.component';
// import { ThanksComponent } from './common/thanks/thanks.component';
import { BodyMarkerComponent } from './common/body-marker/body-marker.component';
import { FullcalendarComponent } from './fullcalendar/fullcalendar.component';
import { ThanksMobileComponent } from './common/thanks-mobile/thanks-mobile.component';
import { DicomComponent } from './dicom/dicom.component'
import { DicomViewerModule } from 'ng-dicomviewer';
import { _CoalescedStyleScheduler } from '@angular/cdk/table';


import {MatRippleModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';


import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatChipsModule} from '@angular/material/chips';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { MatTabsModule } from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
// import { DicomViewerComponent } from './dicom-viewer/dicom-viewer.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { AddDependentComponent } from './add-dependent/add-dependent.component';
import { DocumentUploadComponent} from './document-upload/document-upload.component';
import { ProfileComponent} from './profile/profile.component';
import { AddAppointmentComponent } from './add-appointment/add-appointment.component';
import { MyDocumentsComponent } from './my-documents/my-documents.component';
import { BookingHistoryComponent } from './booking-history/booking-history.component';
import { DependencyCircleComponent } from './dependency-circle/dependency-circle.component';
import { ThanksComponent } from './common/thanks/thanks.component';

import { PublicComponent } from './frame/public/public.component';


import { ViewPatientDocumentsComponent } from './Doctor/view-patient-documents/view-patient-documents.component';
import { DoctorDashboardComponent } from './Doctor/doctor-dashboard/doctor-dashboard.component';
import { DoctorDicomViewerComponent } from './Doctor/doctor-dicom-viewer/doctor-dicom-viewer.component';
import { PatientListComponent } from './Doctor/patient-list/patient-list.component';
import { AddTimesloteComponent } from './Doctor/add-timeslote/add-timeslote.component';
import { TodayAppointmentComponent } from './Doctor/today-appointment/today-appointment.component';
import { TodayAppointmentDetailsComponent } from './Doctor/today-appointment-details/today-appointment-details.component';
import { NewAppointmentComponent } from './Doctor/new-appointment/new-appointment.component';
import { RequestHistoryComponent } from './Doctor/request-history/request-history.component';
import { RequestHistoryDetailsComponent } from './Doctor/request-history-details/request-history-details.component';

import { FullCalendarModule } from '@fullcalendar/angular'; 
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction';
import { TimeslotDetailsComponent } from './Doctor/timeslot-details/timeslot-details.component'; // a plugin
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { JwPaginationComponent } from 'jw-angular-pagination';
import { FilterPipe } from './filter.pipe';
import { ViewQuestionnaireReportComponent } from './Doctor/view-questionnaire-report/view-questionnaire-report.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';

import { TestComponentComponent } from './test-component/test-component.component';
import { CountdownModule } from 'ngx-countdown';
import { DatePipe } from '@angular/common'
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

import { NgxPayPalModule } from 'ngx-paypal';
import { PaypalComponent } from './paypal/paypal.component';
import { QuestionnaireDialogComponent } from './questionnaire-dialog/questionnaire-dialog.component';
import { PaypalWebviewComponent } from './common/paypal-webview/paypal-webview.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    SecondOpinionLayoutComponent,
    UserRegistrationComponent,
    UserRegistrationDialogComponent,
    QuestionnaireComponent,
    ImageMapperComponent,
    ConfirmQuestionComponent,
    // ThanksComponent,
    BodyMarkerComponent,
    FullcalendarComponent,
    ThanksMobileComponent,
    DicomComponent,
    DashboardComponent,
    ProfileComponent,
    AddDependentComponent,
    DocumentUploadComponent,
    AddAppointmentComponent,
    MyDocumentsComponent,
    BookingHistoryComponent,
    DependencyCircleComponent,
    ThanksComponent,
    

    
    PublicComponent,
    ViewPatientDocumentsComponent,
    DoctorDashboardComponent,
    DoctorDicomViewerComponent,
    PatientListComponent,
    AddTimesloteComponent,
    TodayAppointmentComponent,
    TodayAppointmentDetailsComponent,
    NewAppointmentComponent,
    RequestHistoryComponent,
    RequestHistoryDetailsComponent,
    TimeslotDetailsComponent,

    JwPaginationComponent,
    FilterPipe,
    ViewQuestionnaireReportComponent,
    PrivacypolicyComponent,

    TestComponentComponent,

    PaypalComponent,

    QuestionnaireDialogComponent,

    PaypalWebviewComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    RouterModule,
    HttpClientModule,
    LayoutComponentsModule,
    
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatRadioModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatStepperModule,
    MatCardModule,
    MatRippleModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatChipsModule,
    NgxMaterialTimepickerModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatBottomSheetModule,

    NgxDropzoneModule,
    ToastrModule.forRoot() // ToastrModule added
    ,
    FontAwesomeModule,
    ShowHidePasswordModule,

     DicomViewerModule,
     FullCalendarModule ,
     CountdownModule,
     NgxIntlTelInputModule,

     NgxPayPalModule
  ],
  entryComponents:[
    UserRegistrationDialogComponent,
    ImageMapperComponent,
    ConfirmQuestionComponent,
    FullcalendarComponent,
    DoctorDicomViewerComponent,
    RequestHistoryDetailsComponent,
    TodayAppointmentDetailsComponent,
    ViewQuestionnaireReportComponent,
    QuestionnaireDialogComponent
  ],
  providers: [
    MatDatepickerModule, _CoalescedStyleScheduler,DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
