import { UserRegistrationService } from './../../user-registration/user-registration.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.css']
})
export class PublicComponent implements OnInit {

  currentdate: number;
  constructor(private service: UserRegistrationService) { }

  ngOnInit() {
    
    console.log(this.service.isPatient);
    if (this.service.isPatient) {
      this.service.logout();
    }
    this.currentdate = new Date().getFullYear();
  }

}
