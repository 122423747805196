import { Component, OnInit,ViewChild } from '@angular/core';
import { DICOMViewerComponent } from 'ng-dicomviewer';
import { AddDependentService } from './view-patient-documents.service';
import {MatDialog} from '@angular/material/dialog';
import { DoctorDicomViewerComponent } from '../doctor-dicom-viewer/doctor-dicom-viewer.component';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

declare const cornerstone;
declare const cornerstoneWADOImageLoader;

@Component({
  selector: 'app-view-patient-documents',
  templateUrl: './view-patient-documents.component.html',
  styleUrls: ['./view-patient-documents.component.css']
})
export class ViewPatientDocumentsComponent implements OnInit {

  patientList:any[]=[];
  constructor(private service:AddDependentService,public dialog: MatDialog,private http: HttpClient) {
   
  }
  @ViewChild(DICOMViewerComponent, { static: true }) viewPort: DICOMViewerComponent;

  ngOnInit() {

    this.getDicomUploadedPatients();

    cornerstoneWADOImageLoader.external.cornerstone = cornerstone; // inicializa WADO Image loader

    // configura codecs e web workers
    cornerstoneWADOImageLoader.webWorkerManager.initialize({
        webWorkerPath: './assets/cornerstone/webworkers/cornerstoneWADOImageLoaderWebWorker.js',
        taskConfiguration: {
            'decodeTask': {
                codecsPath: '../codecs/cornerstoneWADOImageLoaderCodecs.js'
            }
        }
    });

    // this.getImage().subscribe((res:File)=>{
    //   console.log(res);
      
    // });


  }
  testFileL:FileList;
   /**
   * Load selected DICOM images
   *
   * @param files list of selected dicom files
   */
  loadDICOMImages(files: FileList) {

    if (files && files.length > 0) {
      let imageList = [];
      const fileList:Array<File> = Array.from(files);
      fileList.sort((a,b) => {
        if ( a.name > b.name ) return 1;
        if ( b.name > a.name ) return -1;
        return 0;
      })
      //cornerstoneWADOImageLoader.wadouri.fileManager.purge();
      cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.purge();

      // loop thru the File list and build a list of wadouri imageIds (dicomfile:)
      for (let i = 0; i < fileList.length; i++) {
        const dicomFile: File = fileList[i];

        const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(dicomFile);
        imageList.push(imageId);
       
      }


      this.viewPort.resetAllTools();

      // now load all Images, using their wadouri
      this.viewPort.loadStudyImages(imageList);

    } else alert('Escolha imagens DICOM a exibir.');
  }

  getDicomUploadedPatients(){

    this.service.getDicomUploadedPatients().subscribe((res:any)=>{

       
        this.patientList.push(...res.list); 
  
    });
  }
  viewDicomImg(doc: any) {
    console.log(doc);//userDocumentDetailID
    console.log(doc.documentPath.replace(environment.url, ''));
    var file;
    //var files: FileList;
    const element = document.querySelector(".image-canvas");
    cornerstone.enable(element);
    
    console.log(environment.url + 'api/download/' + doc.userDocumentDetailID);


    this.http.get(environment.url + 'api/download/' + doc.userDocumentDetailID, { responseType: 'blob' }).subscribe(res => {
      console.log(res);
      //window.open(window.URL.createObjectURL(res));
      //return window.URL.createObjectURL(res);
      console.log(window.URL.createObjectURL(res));
      //cornerstone.loadAndCacheImage("wadouri:" + window.URL.createObjectURL(res)).then(imageData => {
      //  console.log(imageData);
      //  cornerstone.displayImage(element, imageData);
      //}).catch(error => { console.error(error) });
      cornerstone.loadImage("wadouri:" + window.URL.createObjectURL(res)).then(image => {
        cornerstone.displayImage(element, image);
      }).catch(error => {
        console.log(error);
        console.error(error);
      });
    });
   
  
    //this.service.downloadFile(doc.userDocumentDetailID).subscribe((res: any) => {
    //  file = res;
    //  console.log(window.URL.createObjectURL(res));
    //   window.URL.createObjectURL(res);
    //  //files.item.
    //  //this.loadDICOMImages(file);
    //  //cornerstone.displayImage(element, file);
    //  //cornerstone.loadAndCacheImage("wadouri:" + DCMPath).then(imageData => {
    //  //  console.log(imageData);
    //  //  cornerstone.displayImage(element, imageData);
    //  //}).catch(error => { console.error(error) });
    //});
  }
  viewDicom(documentPath,fileName){
    const dialogRef = this.dialog.open(DoctorDicomViewerComponent, {
      width: '800px',
      data: {documentPath,fileName},
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    
    });
  }
  testFile:File;
  getImage() {
    return this.http
      .get("http://94-237-73-65.sg-sin1.upcloud.host:96/Documents/user8/ttfm.dcm", {
        responseType: "arraybuffer"
     })
      .pipe(
        map(response => {
          return new File([response], "ttfm.dcm");
        })
      );
  }


  
}
