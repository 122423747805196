import { Component, OnInit ,Inject} from '@angular/core';
import { RequestHistoryService } from '../../Doctor/request-history/request-history.service';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-today-appointment-details',
  templateUrl: './today-appointment-details.component.html',
  styleUrls: ['./today-appointment-details.component.css']
})
export class TodayAppointmentDetailsComponent implements OnInit {

  patientInfo:PatientInfo=new PatientInfo();
  patientID:number;
  
  constructor(private service:RequestHistoryService,@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<TodayAppointmentDetailsComponent>) {

    
    this.patientID=data;
    console.log(  this.patientID);
   }
  consultationList:any[]=[];
  ngOnInit(): void {
    this.getConsultationStatus();
    this.getPatientDetails();
  }
  getConsultationStatus(){
    this.service.getConsultationStatus().subscribe((res:any)=>{
      this.consultationList=[];
      this.consultationList.push(...res.list);

    console.log(this.consultationList);

 
    });
  }
  getPatientDetails(){
    this.service.getPatientDetails(this.patientID).subscribe((res:any)=>{
   
      this.patientInfo=res.list[0];
      console.log(this.patientInfo);
      console.log(res.list);
      
    });
  }
  closeDialog(){
    this.dialogRef.close();
  }
}
export class PatientInfo{
  patientID:number;
  communicationTypeID:number;
  communicationType:string;
  appointmentFrom:string;
  appointmentTo:string;
  remarks:string;
  firstName:string;
  middleName:string;
  lastName:string;
  age:string;
  gender:string;
  mobileNumber:string;
  officialNumber:string;
  whatsAppNumber:string;
  email:string;
  consultationStatusID:number;
  consultationStatus:string;
  bookedDate:string;
  profile_Image_URL:string;
}