import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import $ from "jquery";
import { QuestionnaireService } from '../questionnaire/questionnaire.service'
import { MatSnackBar } from '@angular/material/snack-bar';
import {ImageMapperComponent } from '../image-mapper/image-mapper.component';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmQuestionComponent } from '../confirm-question/confirm-question.component';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-questionnaire-dialog',
  templateUrl: './questionnaire-dialog.component.html',
  styleUrls: ['./questionnaire-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class QuestionnaireDialogComponent implements OnInit {

  constructor(private service:QuestionnaireService,public _snackBar:MatSnackBar,public dialog: MatDialog,private routeParams: ActivatedRoute,private router: Router) { }

  sectionNumber:number=0;

  qHTML:string='';
  answerarray=new Array();
  stepperArray=[];
  loader:any;
  margin_right:number;
  formID:number;
  UserID: string;
  PatientID: string;
  stepwiseAnswerarray=new Array();

  stepwiseAnswerarray1:StepperObject[]=[];
  stepperArray1:StepperArray[]=[];

  isMobileWindow:any;

  fullName:string;

  PainMedicationList=[
    { tabIndex:0,medication:'',dose:'',frequency:'',approxmity:'',currentlyTaking:'',goodRelief:'',moderatePainRelief:'',noPainRelief:'',sideEffectExperienced:''}];

  PainTreatmentHistoryList=[
        {tabIndex:0,treatmentType:'EPIDURAL STEORID INJ',region:'',proceduresLastYear:'',dateOfLastProcedure:'',amountOfPainRelief:'',sideEffect:'' },
        {tabIndex:1,treatmentType:'FACET JOINT INJECTION',region:'',proceduresLastYear:'',dateOfLastProcedure:'',amountOfPainRelief:'',sideEffect:'' },
        {tabIndex:2,treatmentType:'SACROILIAC JOINT INJECTION',region:'',proceduresLastYear:'',dateOfLastProcedure:'',amountOfPainRelief:'',sideEffect:'' },
        {tabIndex:3,treatmentType:'MEDIAL BRANCH BLOCKS',region:'',proceduresLastYear:'',dateOfLastProcedure:'',amountOfPainRelief:'',sideEffect:'' },
        {tabIndex:4,treatmentType:'RFA',region:'',proceduresLastYear:'',dateOfLastProcedure:'',amountOfPainRelief:'',sideEffect:'' },
        {tabIndex:5,treatmentType:'SPINAL CORD STIMULATOR',region:'',proceduresLastYear:'',dateOfLastProcedure:'',amountOfPainRelief:'',sideEffect:'' },
        {tabIndex:6,treatmentType:'TRIGGER POINTS',region:'',proceduresLastYear:'',dateOfLastProcedure:'',amountOfPainRelief:'',sideEffect:'' },
        {tabIndex:7,treatmentType:'VERTEBROPLASTY/KYPHOPLASTY',region:'',proceduresLastYear:'',dateOfLastProcedure:'',amountOfPainRelief:'',sideEffect:'' }
    ];
  MedicationsList=[
        { tabIndex:0,medicationName:'',dosage:'',medcationfrequency:''}
    ];
  PhysiciansOrSpecialistList=[
        { tabIndex:0, speciality:'Acupuncturist', name:'', treatment:'', painrelief:'',lastinterventiondate:''},
        { tabIndex:1, speciality:'Neurosurgeon', name:'', treatment:'', painrelief:'',lastinterventiondate:''},
        { tabIndex:2, speciality:'Psychiatrist/Psychologist ', name:'', treatment:'', painrelief:'',lastinterventiondate:''},
        { tabIndex:3, speciality:'Chiropractor ', name:'', treatment:'', painrelief:'',lastinterventiondate:''},
        { tabIndex:4, speciality:'Orthopedic Surgeon ', name:'', treatment:'', painrelief:'',lastinterventiondate:''},
        { tabIndex:5, speciality:'Rheumatologist ', name:'', treatment:'', painrelief:'',lastinterventiondate:''},
        { tabIndex:6, speciality:'Internist', name:'', treatment:'', painrelief:'',lastinterventiondate:''},
        { tabIndex:7, speciality:'Physical Therapist', name:'', treatment:'', painrelief:'',lastinterventiondate:''},
        { tabIndex:8, speciality:'Neurologist ', name:'', treatment:'', painrelief:'',lastinterventiondate:''},
    ];
  TestList=[
        { tabIndex:0, investigation:'MRI', region1:'', recentdate1:'', region2:'',recentdate2:'',fileName:''},
        { tabIndex:1, investigation:'CT scan', region1:'', recentdate1:'', region2:'',recentdate2:'',fileName:''},
        { tabIndex:2, investigation:'Xrays ', region1:'', recentdate1:'', region2:'',recentdate2:'',fileName:''},
        { tabIndex:3, investigation:'EMG- NCV ', region1:'', recentdate1:'', region2:'',recentdate2:'',fileName:''},
        { tabIndex:4, investigation:'Vascular studies ', region1:'', recentdate1:'', region2:'',recentdate2:'',fileName:''},
        { tabIndex:5, investigation:'DEXA scan ', region1:'', recentdate1:'', region2:'',recentdate2:'',fileName:''},
        { tabIndex:6, investigation:'Myelogram', region1:'', recentdate1:'', region2:'',recentdate2:'',fileName:''},
        { tabIndex:7, investigation:'Discogram', region1:'', recentdate1:'', region2:'',recentdate2:'',fileName:''},
        { tabIndex:8, investigation:'PET Scan ', region1:'', recentdate1:'', region2:'',recentdate2:'',fileName:''},
    ];
 LocationList=[];


  ngOnInit() {

    this.UserID = this.routeParams.snapshot.params['id1']; 
    this.formID = this.routeParams.snapshot.params['id2']; 
   
    if(this.UserID==undefined){

      this.UserID=localStorage.getItem('consultationFor_Id');
      
    }

    if(this.formID==undefined){
        this.formID=1;
    }

    
    $('.fm-btn-prev').hide();

    this.GetUserProfile();
    this.getLastSavedQuestionSection();
  
    this.isMobileWindow=this.detectMobile();
    this.clearLocalStorage();
    
    
  }

  GetUserProfile(){
      this.service.GetUserProfile(this.UserID).subscribe((res:any)=>{
        this.fullName=res.data.firstName+ ' '+res.data.middleName+ ' '+res.data.lastName;

      });
  }
  clearLocalStorage(){
 
    localStorage.setItem('imgMapper1',null);
    localStorage.setItem('imgMapper2',null);
    localStorage.setItem('imgMapper3',null);
  }
  getLastSavedQuestionSection(){
    this.service.getLastSavedQuestionSection(this.formID, this.UserID, this.PatientID).subscribe((res:any)=>{

        
        this.sectionNumber=res.sectionNumber+1;
        if(this.sectionNumber>1){
            $('.fm-btn-prev').show();
        }
        this.loadQuestions();

      });
  }
  loadQuestions(){

    this.loader=true;

    this.service.LoadQuestionnaire(this.formID,this.sectionNumber, this.UserID,this.PatientID).subscribe((res:any)=>{

      this.buildQuestionnaire(res.result[0]);
      this.loader=false;
    });
  }
  
  buildQuestionnaire(result){

    this.qHTML='';

    this.stepwiseAnswerarray=[];
    if(result==undefined){

        $('.fm-btn-next-save').hide();
        $('.fm-btn-next-exit').hide();
        $('.fm-btn-prev').hide();
        this.qHTML='<div class="custom-row3"><p>You have successfully completed Questionnaire</p></div>';
        $('#QuestionnaireContainer').html(this.qHTML);
        $('.fm-btn-close').show();
        $('.fm-bottom-2').hide();
        return;
    }

    this.sectionNumber=result.sectionNumber.sectionNumber;
    
    this.stepperArray.push(this.sectionNumber);


    if(result.questions.length>0){
     
      var _branchablecss="branchable";
      var answerfield="";

      $('#hdnForms').val('');

      var required = ' required="required"';
      var input_css_class = ' form-control question-form-control ';
      var date_cssclass = ' date-picker ';
      var datetime_cssclass = ' date-time ';
      var chk_css_class = ' rpls_checkbox question-form-control ';
      var rdo_css_class = ' question-form-control ';

      this.answerarray=[];

      const component=this;

      component.qHTML+='<div class="fm-stepper-section" id="stepper-section'+this.sectionNumber+'" >';


      if(this.formID==1){
        if(this.sectionNumber==1){
            component.qHTML+='<p class="fm-hint-section"><span> Answering 60% of the questionnaire (marked in red) is mandatory . If you can fill more, it will help us to have a proper evaluation of your problem </span></p>';
        }

        
        if(this.sectionNumber==2){
        
            var originalState = $("#stepper-section2").clone();
            $("#stepper-section2").replaceWith(originalState.clone());
        }

        if(this.sectionNumber==31){
            $('.fm-btn-next-save').hide();
        }
        else{
            $('.fm-btn-next-save').show();
        }
      }

      if(this.formID==2){
        if(this.sectionNumber==1){
          
            component.qHTML+='<p class="fm-hint-section"><span> This questionnaire has been designed to give us information as to how your neck pain has '+
            'affected your ability to manage in everyday life. Please answer every section and <b class="bold"> mark in each '+
            'section only the one box that applies to you </b>. We realise you may consider that two or more '+
            'statements in any one section relate to you, but please just mark the box that most closely '+
            'describes your problem. </span></p> ';
        }


        if(this.sectionNumber==10){
            $('.fm-btn-next-save').hide();
        }
        else{
            $('.fm-btn-next-save').show();
        }

          $('.card-title').text('Neck Disability Index');
      }


      
      this.stepperArray1=[];


      $.each(result.questions, function (qi, ques) {

       
        var _required = "";
        var _data_attrs = ' data-formfk="' + ques.formFK + '" data-questionid="' + ques.questionID + '" ';
        var _value = component.searchQuestion(ques.questionID);
        var _option = component.searchQuestionOption(ques.questionID);
        // var _comments=component.searchQuestionComments(ques.questionID);

        var _comments=component.sectionNumber;//  storing section number in textarea comment box
        
        var _cntrlid = 'crtl_' + ques.questionID;

        var _cntrlcmtid = 'crtlcmt_' + ques.questionID;
        var _commenthiddencss =" hidden ";
        var _childhiddencss=' childbranch hidden ';
        var _parentQuestionOptionId="0";
        var _parentQuestionId="0";
        var _selectedattribute="";

        answerfield='crtl_' + ques.questionID;
        component.answerarray.push(answerfield);
        component.stepwiseAnswerarray.push(answerfield);

        component.stepperArray1.push({controlValue:answerfield});

        if (ques.isMandatory == true) {
          _required = required;
        }
        if (ques.hasComments == true) {
            _commenthiddencss = "  ";
            ;
        }
        
        if(ques.questionParentBranches.length==0)
        {
            
            _childhiddencss=" " ;
        }
        else {
            _parentQuestionOptionId=ques.questionParentBranches[0].parentQuestionOptionFK;
            _parentQuestionId=ques.questionParentBranches[0].parentQuestionFK;
        }

        //Header
   
        if(ques.sectionHeader!=null&& ques.sectionHeader!=''){
          $('.card-title').text(ques.sectionHeader);
        }
        else{

   
          if(ques.displayControlType==1){ // header display 


            var questionLabelClass=(ques.isMandatory==true)?'fm-lbl-mandatory':'';
            component.qHTML+='<label class="lbl-header '+questionLabelClass+'">'+ques.displayHeader+' </label>';
            
          }
          else if(ques.displayControlType==2){  // table display
            
            if(ques.tableType==1){ //pain severity table

  
                var table6='';
                table6+='<div class="fm-table-frame fm-table-severity"> <table class="table table-bordered table-striped" id="tbl-locationlist">';
                table6+='<thead><tr>'+
                                '<th>Location</th>'+  
                                '<th>Pain now</th>'+
                                '<th>Worst it gets </th>'+
                                '<th>Best it gets </th>'+           
                        '</tr></thead>'; 
                        table6+='<tbody>';
                
     
                var neckExist=false;

                component.LocationList=[];
                

                for(var i=0;i<component.chiefComplaintPainSpotArray.length;i++){
                    if(component.chiefComplaintPainSpotArray[i].description=='NECK'){
                        neckExist=true;
                        break;
                    }
                }
                if(neckExist){
                    component.LocationList=[
                        { tabIndex:0,location:'Neck:',painrightnow:0, theworstitgets:0,thebestitgets:0},
                        { tabIndex:1,location:'Other:',painrightnow:0, theworstitgets:0,thebestitgets:0},
                    ];
                }
                else{
                    for(var i=0;i<component.chiefComplaintPainSpotArray.length;i++){

                        component.LocationList.push({tabIndex:i,
                            location:component.chiefComplaintPainSpotArray[i].description,
                            painrightnow:0,
                            theworstitgets:0,
                            thebestitgets:0
                        });
                    }
                    component.LocationList.push({tabIndex:i,
                        location:'Other ',
                        painrightnow:0,
                        theworstitgets:0,
                        thebestitgets:0
                    });
                }
            
                // var table_row='';
                // $.each(component.LocationList,function(key,val){

                //     table_row+='<tr>';
                //     table_row+='<td><span class="fm-span-tab">'+val.location+'</span></td>';
                //     table_row+='<td class="fm-tab6-row"><input class="fm-tab6-input" id='+val.tabIndex+'_painrightnow  max="10" min="0" value="0" type="range" onchange="this.nextElementSibling.innerText = this.value;"><span>0</span></td>';
                //     table_row+='<td class="fm-tab6-row"><input class="fm-tab6-input" id='+val.tabIndex+'_theworstitgets  max="10" min="0" value="0"  type="range" onchange="this.nextElementSibling.innerText = this.value;"><span>0</span></td>';
                //     table_row+='<td class="fm-tab6-row"><input class="fm-tab6-input" id='+val.tabIndex+'_thebestitgets  max="10" min="0"  value="0" type="range" onchange="this.nextElementSibling.innerText = this.value;"><span>0</span></td>';
                //     table_row+='</tr>';
                // });

                // table6+=table_row;
                table6+='</tbody>';
                table6+='</table></div>';

                component.qHTML+=table6;

            }
            else if(ques.tableType==2){

                var table1='';
                table1+='<div class="fm-table-frame"> <table class="table table-bordered table-striped" id="tbl-painMedication">';
                        
                table1+='<thead><tr>'+
                               
                                '<th>Medication</th>'+
                                '<th>Dose</th>'+
                                '<th>Frequency</th>'+
                                '<th>PERIOD APPROXIAMTELY TAKEN</th>'+
                                '<th>CURRENTLY TAKING</th>'+
                                '<th>GOOD RELIEF</th>'+
                                '<th>MODERATATE PAIN RELAIEF</th>'+
                                '<th>NO PAIN RELIEF</th>'+
                                '<th>SIDE EFFECT EXEPRIENCED</th>'+
                '</tr></thead>'; 
                table1+='<tbody>';
                    var row='<tr>'
                    
                    row+='<td><input type="text" class="fm-tab-input" id="0_medication" value=""></td>';
                    row+='<td><input type="text" class="fm-tab-input" id="0_dose" value=""></td>';
                    row+='<td><input type="text" class="fm-tab-input"  id="0_frequency" value=""></td>';
                    row+='<td><input type="text" class="fm-tab-input" id="0_approxmity" value=""></td>';
                    row+='<td><input type="text" class="fm-tab-input" id="0_currentlyTaking" value=""></td>';
                    row+='<td><input type="text" class="fm-tab-input" id="0_goodRelief" value=""></td>';
                    row+='<td><input type="text" class="fm-tab-input" id="0_moderatePainRelief" value=""></td>';
                    row+='<td><input type="text" class="fm-tab-input" id="0_noPainRelief" value=""></td>';
                    row+='<td><input type="text" class="fm-tab-input" id="0_sideEffectExperienced"></td>'; 
                
                table1+=row;
                table1+='</tbody>';
                table1+='<tfoot><tr><td colspan="10" style="text-align: center;"><span class="material-icons addNewRowPainMedication" >add_circle</span></td></tr></tfoot>';
                table1+='</table></div>';
                component.qHTML+=table1;

            }
            else if(ques.tableType==3){
        
                var table2='';
                table2+='<div class="fm-table-frame"> <table class="table table-bordered table-striped" id="tbl-treatment-history">';
                table2+='<thead><tr>'+
                                
                                '<th>INTERVENTIONAL PAIN TREATMENT TYPE</th>'+
                                '<th>REGION</th>'+
                                '<th>NUMBER OF PROCEDURES LAST YEAR </th>'+
                                '<th>DATE OF THE LAST PROCEDURE </th>'+
                                '<th>AMOUNT OF PAIN RELIEF MILD, MODERATE , EXCELLENT</th>'+
                                '<th>SIDE EFFECT EXEPRIENCED </th>'+
                                
                '</tr></thead>'; 
                table2+='<tbody>';
                var row='';
                $.each(component.PainTreatmentHistoryList,function(key,val){
                    row+='<tr>';
                    
                    row+='<td><span class="fm-span-tab">'+val.treatmentType+'</span></td>';
                    row+='<td><input type="text" class="fm-tab2-input" id='+val.tabIndex+'_region value='+val.region+'></td>';
                    row+='<td><input type="text" class="fm-tab2-input" id='+val.tabIndex+'_proceduresLastYear value='+val.proceduresLastYear+'></td>';
                    row+='<td><input type="date" class="fm-tab2-input" id='+val.tabIndex+'_dateOfLastProcedure value='+val.dateOfLastProcedure+'></td>';
                    row+='<td><input type="text" class="fm-tab2-input" id='+val.tabIndex+'_amountOfPainRelief value='+val.amountOfPainRelief+'></td>';
                    row+='<td><input type="text" class="fm-tab2-input" id='+val.tabIndex+'_sideEffect value='+val.sideEffect+'></td>';
                    row+='</tr>';
                });
                table2+=row;
                table2+='</tbody>';
                table2+='</table></div>';
                component.qHTML+=table2;

            }
            else if(ques.tableType==4){
        
                var table5='';
                table5+='<div class="fm-table-frame"> <table class="table table-bordered table-striped" id="tbl-testlist">';
                table5+='<thead><tr>'+
                                '<th>Investigation</th>'+  
                                '<th>Region</th>'+
                                '<th>Recent dates </th>'+
                                '<th>Region </th>'+
                                '<th>Recent dates </th>'+ 
                                '<th></th>'+            
                        '</tr></thead>'; 
                table5+='<tbody>';
                var row='';
                $.each(component.TestList,function(key,val){
                    row+='<tr>';
                    row+='<td><span class="fm-span-tab">'+val.investigation+'</span></td>';
                    row+='<td><input type="text" class="fm-tab5-input" id='+val.tabIndex+'_region1 value='+val.region1+'></td>';
                    row+='<td><input type="date" class="date-picker fm-tab5-input" id='+val.tabIndex+'_recentdate1 value='+val.recentdate1+'></td>';
                    row+='<td><input type="text" class="fm-tab5-input" id='+val.tabIndex+'_region2 value='+val.region2+'></td>';
                    row+='<td><input type="date" class="date-picker fm-tab5-input" id='+val.tabIndex+'_recentdate2 value='+val.recentdate2+'></td>';
                    row+='<td><input type="file" id='+val.tabIndex+'_myfile class="fm-tab5-file" style="font-size: 11px;"></td>';
                    row+='</tr>';
                });
                table5+=row;
                table5+='</tbody>';
                table5+='</table></div>';
                component.qHTML+=table5;

            }
            else if(ques.tableType==5){
                var table4='';
                table4+='<div class="fm-table-frame"> <table class="table table-bordered table-striped" id="tbl-PhysiciansOrSpecialist">';
                table4+='<thead><tr>'+
                                '<th>Speciality</th>'+  
                                '<th>Name</th>'+
                                '<th>Treatments</th>'+
                                '<th>Pain relief ?</th>'+
                                '<th>Last intervention Dates</th>'+           
                        '</tr></thead>'; 
                table4+='<tbody>';
                var row='';
                $.each(component.PhysiciansOrSpecialistList,function(key,val){
                    row+='<tr>';
                    row+='<td><span class="fm-span-tab">'+val.speciality+'</span></td>';
                    row+='<td><input type="text" class="fm-tab4-input" id='+val.tabIndex+'_name value='+val.name+'></td>';
                    row+='<td><input type="text" class="fm-tab4-input" id='+val.tabIndex+'_treatment value='+val.treatment+'></td>';
                    row+='<td><input type="text" class="fm-tab4-input" id='+val.tabIndex+'_painrelief value='+val.painrelief+'></td>';
                    row+='<td><input type="date" class="date-picker fm-tab4-input" id='+val.tabIndex+'_lastinterventiondate value='+val.lastinterventiondate+'></td>';
                    
                    row+='</tr>';
                });
                table4+=row;
                table4+='</tbody>';
                table4+='</table></div>';
                component.qHTML+=table4;

            }
            else if(ques.tableType==6){
                var table3='';
                table3+='<div class="fm-table-frame"> <table class="table table-bordered table-striped" id="tbl-Medication">';
                
                table3+='<thead><tr>'+
                                
                                '<th>Medication</th>'+
                                '<th>Dosage</th>'+
                                '<th>Frequency</th>'+
                            '</tr></thead>'; 
                table3+='<tbody>';
                    var row='<tr>'
                   
                    row+='<td><input type="text" class="fm-tab3-input" id="0_medicationName" value=""></td>';
                    row+='<td><input type="text" class="fm-tab3-input" id="0_dosage" value=""></td>';
                    row+='<td><input type="text" class="fm-tab3-input"  id="0_medcationfrequency" value=""></td>';
                
                table3+=row;
                table3+='</tbody>';
                table3+='<tfoot><tr><td colspan="10" style="text-align: center;"><span class="material-icons addNewRowMedication" >add_circle</span></td></tr></tfoot>';
                table3+='</table></div>';
                component.qHTML+=table3;
            }
          }
          else if(ques.displayControlType==5){
            var questionLabelClass=(ques.isMandatory==true)?'fm-lbl-mandatory':'';
            component.qHTML+='<label class="lbl-warning '+questionLabelClass+'">'+ques.displayHeader+' </label>';
          }
          else{

            var answerColumnWidth=(ques.isSection==true)?'col-12 col-md-5':'col-7 col-md-5';
            var questionColumnWidth=(ques.isSection==true)?'col-12 fm-qstn-md col-md-4':'col-5 fm-qstn-md col-md-4';
            var hiddenClass=(ques.isSection==true)?'none':'block';

    
            var questionLabelClass=(ques.isMandatory==true)?'fm-lbl-mandatory':'fm-lbl-qstn';

            var _disabled='';
        
            if(component.sectionNumber==1){

                if(ques.questionTitle=='Date'){
                    var _today= component.FormatDate(new Date().toString());
                    _value=_today;
                    _disabled='disabled';
                }
                if(ques.questionTitle=='Full Name'){
                    _value=component.fullName;
                }
            }
            var parentClassCss='';
            if(ques.inputControlType==6 && ques.displayControlType){//
                parentClassCss='parentControlCss';
                
            }

            component.qHTML +=
            '<div class="rpls_form_section_inr questionouter '+_childhiddencss+'" data-pqoid="'+_parentQuestionOptionId+'" data-pqid="'+_parentQuestionId+'" '+_data_attrs+'>' +
                '<div class="row fm-qstn-row">'+
                    '<div class="'+questionColumnWidth+'">' +
                        '<label class="'+questionLabelClass+'">'+ ques.questionTitle + '</label>' +
                        '<span class="colon-span" style="display:'+hiddenClass+'">:</span>'+
                    '</div>' +
                    '<div id="fm-id-4" class='+answerColumnWidth+'  >' +
                        '<span class="'+parentClassCss+'" for="' + _cntrlid + '">'+
                        '{control}' +
                        '</span>'+
                    '</div>'+
                    '<span class="field-validation-valid" data-valmsg-for="' + _cntrlid + '" data-valmsg-replace="true"></span>' +
                    '<textarea name="comments" cols="" placeholder="Comments" rows="" class="rpls_form_comments '+_commenthiddencss+'"  id="' + _cntrlcmtid + '" name="' + _cntrlcmtid + '" placeholder="Comments" ' + _data_attrs + ' >' + _comments + '</textarea>'+
                '</div>'+
            '</div>' +
            '<div class="custom-hr"></div>'; 
            
            switch (ques.inputControlType) {

              case 1://Textbox

                  var _inputdatatype = "text";
                  var _data_steps = "";
                  switch (ques.inputDataType) {
                      case 1:
                          //Number
                          _inputdatatype = "number";
                          break;
                      case 2:
                          //Float
                          _inputdatatype = "number";
                          _data_steps = ' steps="0.1"';
                          break;
                      case 3:
                          //Date
                          _inputdatatype = "date";
                          input_css_class += date_cssclass;
                          break;
                      case 4:
                          //Time
                          _inputdatatype = "time";
                          break;
                      case 5:
                          //DateTime
                          _inputdatatype = "datetime-local";
                          input_css_class += date_cssclass + datetime_cssclass;
                          break;
                      case 6:
                          //Text
                          break;
                      default:
                  }

                  component.qHTML = component.qHTML.replace("{control}",
                              '<input class="' + input_css_class + '" data-val="true" data-val-date="" '+_disabled+' data-val-required="The field is required." id="' + _cntrlid + '" name="' + _cntrlid + '" type="' + _inputdatatype + '" value="' + _value + '" ' + _required + ' ' + _data_attrs + ' autocomplete="on">');// date-picker  data-val-date="The field must be a date."
                  break;

              case 2: //Checkbox
                  var chkoptionslist ='';
                  $.each(ques.questionOptions, function (oi, op) {
                      _selectedattribute="";
                      if(_option.toString()=='')
                      {
                          // chkoptionslist += '<div class="checkbox-wrapper"> <input class="' + chk_css_class +_branchablecss + '" data-val="true" data-val-date="" data-val-required="The field is required." id="' + _cntrlid + '_' + oi + '" name="' + _cntrlid + '" placeholder="' + ques.questionTitle + '" type="checkbox" value="' + op.optionText + '" ' + _required + ' ' + _data_attrs + ' data-optionid="' + op.questionOptionID + '" '+_selectedattribute+'>' + '<span class="fm-check-span">'+op.optionText+'</span></div>' ;
                          chkoptionslist += '<div class="checkbox-wrapper"> <input class="' + chk_css_class +_branchablecss + '" data-val="true" data-val-date="" data-val-required="The field is required." id="' + _cntrlid + '" name="' + _cntrlid + '" placeholder="' + ques.questionTitle + '" type="checkbox" value="' + op.optionText + '" ' + _required + ' ' + _data_attrs + ' data-optionid="' + op.questionOptionID + '" '+_selectedattribute+'>' + '<span class="fm-check-span">'+op.optionText+'</span></div>' ;
                      }
                      if(_option.toString().indexOf(",")==-1)
                      {
                          if(op.questionOptionID==_option)
                          {
                              _selectedattribute=" checked='checked' ";
                              chkoptionslist += '<input class="' + chk_css_class +_branchablecss + '" data-val="true" data-val-date="" data-val-required="The field is required." id="' + _cntrlid + '_' + oi + '" name="' + _cntrlid + '" placeholder="' + ques.questionTitle + '" type="checkbox" value="' + op.optionText + '" ' + _required + ' ' + _data_attrs + ' data-optionid="' + op.questionOptionID + '" '+_selectedattribute+'>' + '<span class="fm-check-span">'+op.optionText+'</span>' ;
                          }
                      } else{
                          var _selectedops=_option.split(",");
                          $.each(_selectedops, function (soi, sop) {      
                              var __o=component.searchQuestionOptionValue(ques.QuestionId,sop,soi);
                              _selectedattribute="  ";
                              
                              if(op.questionOptionID==sop&&__o=="Yes")
                              {
                              _selectedattribute=" checked='checked' ";                                                    
                              }
                              if(op.questionOptionID==sop)
                              {    
                                  chkoptionslist += '<input class="' + chk_css_class +_branchablecss + '" data-val="true" data-val-date="" data-val-required="The field is required." id="' + _cntrlid + '_' + oi + '" name="' + _cntrlid + '" placeholder="' + ques.questionTitle + '" type="checkbox" value="' + op.optionText + '" ' + _required + ' ' + _data_attrs + ' data-optionid="' + op.questionOptionID + '" '+_selectedattribute+'>' + '<span class="fm-check-span">'+op.optionText+'</span>' ;                                                 
                              }                                               
                          });
                      }
                      
                  });
                  component.qHTML = component.qHTML.replace("{control}",
                          chkoptionslist);
                  break;   
              
              case 3: //Radio
                  var roptionslist ='';
                  $.each(ques.questionOptions, function (oi, op) {
                      _selectedattribute="";
                      if(op.questionOptionID==_option)
                      {
                          _selectedattribute=" checked='checked' ";
                      }
                      // roptionslist += '<div class="radio-wrapper"><input class="' + rdo_css_class +_branchablecss + '" data-val="true" data-val-date="" data-val-required="The field is required." id="' + _cntrlid + '_' + oi + '" name="' + _cntrlid + '" placeholder="' + ques.questionTitle + '" type="radio" value="' + op.optionText + '" ' + _required + ' ' + _data_attrs + ' data-optionid="' + op.questionOptionID + '" '+_selectedattribute+'>' +'<span class="fm-check-span">'+op.optionText+'</span></div>' 
                      roptionslist += '<div class="radio-wrapper"><input class="' + rdo_css_class +_branchablecss + '" data-val="true" data-val-date="" data-val-required="The field is required." id="' + _cntrlid + '" name="' + _cntrlid + '" placeholder="' + ques.questionTitle + '" type="radio" value="' + op.optionText + '" ' + _required + ' ' + _data_attrs + ' data-optionid="' + op.questionOptionID + '" '+_selectedattribute+'>' +'<span class="fm-check-span">'+op.optionText+'</span></div>' 
                  });
                  component.qHTML = component.qHTML.replace("{control}",
                          roptionslist);
                  break;
            
              case 4://TextArea
              component.qHTML = component.qHTML.replace("{control}",
                   '<textarea class="' + input_css_class + '" data-val="true" data-val-date="" data-val-required="The field is required." id="' + _cntrlid + '" name="' + _cntrlid + '"  value="" ' + _required + ' ' + _data_attrs + '>' + _value + '</textarea>');
                  break;   

              case 5://Multiple Select
                  var sel = '<select class="' + input_css_class +_branchablecss + '  fm-multi-select  " data-val="true" data-val-date="" data-val-required="The field is required." id="' + _cntrlid + '" name="' + _cntrlid + '" value="" ' + _required + ' ' + _data_attrs + ' multiple>{Options}</select>';
                  var optionslist = '';
                  $.each(ques.questionOptions, function (oi, op) {
                      _selectedattribute="";
                      if(op.questionOptionID==_option)
                      {
                          _selectedattribute=" selected='true' ";
                      }
                      optionslist += '<option class="fm-multi-select-option hiddenClass2" id="multiselect'+op.questionOptionID+'" data-optionid="' + op.questionOptionID + '"'+_selectedattribute+'>' + op.optionText + '</option>';
                  });
                  sel = sel.replace("{Options}",
                      optionslist
                      );
                      component.qHTML = component.qHTML.replace("{control}", sel);
                  break;     
              
              case 6: //Select
                
                var optionslist = '';
      
                if(ques.questionOptions.length==0){ // custom select
                    var sel = '';
                    var sel2 = '';
                    if(ques.inputDataType==6){// for string (age)

                        sel = '<select class="' + input_css_class +_branchablecss + '" data-val="true" data-val-date="" data-val-required="The field is required." id="' + _cntrlid + '" name="' + _cntrlid + '" placeholder="' + ques.questionTitle + '" value="" ' + _required + ' ' + _data_attrs + '>{Options}</select>';

                        for(var i=1;i<=100;i++){
                    
                            optionslist += '<option data-optionid="' + i + '" >' + i + '</option>';
                        }
                    }
                    else{

                        
                        if(ques.displayControlType==3){ // select boxes for height 
                            sel = '<select class="' + input_css_class +_branchablecss + ' width50" data-val="true" data-val-date="" data-val-required="The field is required." id="' + _cntrlid + '" name="' + _cntrlid + '" placeholder="' + ques.questionTitle + '" value="" ' + _required + ' ' + _data_attrs + '>{Options}</select>';   
                            sel2 = '<select class="' + input_css_class +_branchablecss + ' width50" data-val="true" data-val-date="" data-val-required="The field is required." id="' + _cntrlid + '" name="' + _cntrlid + '" placeholder="' + ques.questionTitle + '" value="" ' + _required + ' ' + _data_attrs + '>{Options}</select>';
                            optionslist += '<option   disabled selected >Feet</option>';

                            for(var i=1;i<=10;i++){
                    
                                optionslist += '<option data-optionid="' + i + '" >' + i + ' feet </option>';
                            }
                            sel = sel.replace("{Options}",optionslist);
                            
                            optionslist='';
                            optionslist += '<option   disabled selected >Inches</option>';
                            for(var i=1;i<=12;i++){
                    
                                optionslist += '<option data-optionid="' + i + '" >' + i + ' inch </option>';
                            }
                            sel2 = sel2.replace("{Options}",optionslist);

                        }
                        else if(ques.displayControlType==4){
                            sel = '<select class="' + input_css_class +_branchablecss + ' width50" data-val="true" data-val-date="" data-val-required="The field is required." id="' + _cntrlid + '" name="' + _cntrlid + '" placeholder="' + ques.questionTitle + '" value="" ' + _required + ' ' + _data_attrs + '>{Options}</select>';   
                            sel2 = '<select class="' + input_css_class +_branchablecss + ' width50" data-val="true" data-val-date="" data-val-required="The field is required." id="' + _cntrlid + '" name="' + _cntrlid + '" placeholder="' + ques.questionTitle + '" value="" ' + _required + ' ' + _data_attrs + '>{Options}</select>';
                            optionslist += '<option data-optionid="' + 0 + '"  disabled selected>Hour</option>';

                            for(var i=0;i<=24;i++){
                    
                                optionslist += '<option data-optionid="' + i + '" >' + i + ' hour </option>';
                            }
                            sel = sel.replace("{Options}",optionslist);
                            
                            optionslist='';
                            optionslist += '<option data-optionid="' + 0 + '"  disabled selected >Minutes</option>';
                            for(var i=0;i<=59;i++){
                    
                                optionslist += '<option data-optionid="' + i + '" >' + i + ' mins </option>';
                            }
                            sel2 = sel2.replace("{Options}",optionslist);
                        }
                        else{

                            sel = '<select class="' + input_css_class +_branchablecss + '" data-val="true" data-val-date="" data-val-required="The field is required." id="' + _cntrlid + '" name="' + _cntrlid + '" placeholder="' + ques.questionTitle + '" value="" ' + _required + ' ' + _data_attrs + '>{Options}</select>';
                            for(var i=1;i<=200;i++){
                    
                                optionslist += '<option data-optionid="' + i + '" >' + i + '</option>';
                            }
                        }
                       
                        
                    }
                    
                    sel = sel.replace("{Options}",optionslist);
                    sel=sel+(sel2);
                    component.qHTML = component.qHTML.replace("{control}", sel);

                }
                else{
                    var sel = '<select class="' + input_css_class +_branchablecss + '" data-val="true" data-val-date="" data-val-required="The field is required." id="' + _cntrlid + '" name="' + _cntrlid + '" placeholder="' + ques.questionTitle + '" value="" ' + _required + ' ' + _data_attrs + '>{Options}</select>';


                    $.each(ques.questionOptions, function (oi, op) {
                        _selectedattribute="";
                        if(op.questionOptionID==_option)
                        {
                            _selectedattribute=" selected='true' ";
                        }
                        optionslist += '<option data-optionid="' + op.questionOptionID + '"'+_selectedattribute+'>' + op.optionText + '</option>';
                    });

                    sel = sel.replace("{Options}",
                    optionslist
                    );
                    component.qHTML = component.qHTML.replace("{control}", sel);
                }
                
                break;
              
              case 7:{//File upload
              
                  component.qHTML = component.qHTML.replace("{control}",
                  '<div class="fm-file-row"> <input class="' + input_css_class + ' custom-file-upload" type="file" id="' + _cntrlid + '" name="' + _cntrlid + '" value="" ' + _required + ' ' + _data_attrs + '   multiple></div>');

                 break;   
              }
              case 9: //image marker
              component.qHTML = component.qHTML.replace("{control}",
              '<a class="btnimgMapper" id="imgMapper'+ques.inputDataType+'" data-max-length="'+ques.maxLength+'">Open Image mapper</a> ');
              break;
              default:
            }
          }
        }

      });
      this.stepwiseAnswerarray1.push({SectionNumber:this.sectionNumber,ArrayList:this.stepperArray1 });

      component.qHTML+='</div>';
    }
    
    if (this.qHTML != '') {

       

        if(this.sectionNumber==1){
            $('#QuestionnaireContainer').html(this.qHTML);

        }
        else{
            $('#QuestionnaireContainer').append(this.qHTML);
        }


        if(this.sectionNumber==3){

            this.buildLocationTable();
        }

        $('.'+_branchablecss).trigger("change");
        
        $('.fm-stepper-section').css({'display':'none'});
        $('#stepper-section'+this.sectionNumber).css({'display':'block'});


        $('.arrow-steps').empty();

        var count=0;
        for(var i=0;i<this.sectionNumber;i++){
            count=i+1;
            $('.arrow-steps').append('<div class="step triangleStep" id="stepperArrow'+count+'"> <span>'+count+'</span> </div>');
        }
        $('#stepperArrow'+count).addClass('current');
        this.margin_right+=50;
    
        $('.arrow-steps').animate( { scrollLeft: '+=460' }, 1000);

    }
 

    const component=this;

  $('.btnimgMapper').click(function(e){

    var id=$(this).attr('id');
    component.openDialog(id);
  
  });
 
  $('.triangleStep').click(function(e) {
      
  
    var id=$(this).attr('id');
    
    component.sectionNumber=Number(id.split("w")[1]);
    
    component.stepwiseAnswerarray1=[];
    component.loadQuestions();

  });
 
  $(document).on('change', '.custom-file-upload',function(event){
      
      var crtl_id=$(this).attr('id');
      var myFile = $('#'+crtl_id).prop('files');
      component.files=[];
      var file = myFile[0];

      component.files.push(file);
      component.uploadFileToServer(crtl_id,'','');


  });
  $(document).on('change','.'+_branchablecss,function(){
         
       
    var questionid=$(this).data("questionid");
    var ctrlid=$(this).attr("id");
    var optionselected=$("#"+ctrlid+" option:selected")
    if(optionselected.parent().attr("id")==undefined)
    {
        optionselected=$(this);
    }

    $('.questionouter').each(function () {
        if($(this).data("questionid")==questionid)
        {
         
        }
        if($(this).data("pqoid")==optionselected.data("optionid") &&$(this).data("pqid")==questionid)
        {
            $(this).addClass('selected');
             $(this).removeClass('hidden');
           
           
        }
        else if ($(this).data("pqid")==questionid && $(this).data("pqoid")!=optionselected.data("optionid")){
            $(this).removeClass('selected');
            
            $(this).addClass('hidden');
           
                
        }
    });            
  });
  $.fn.serializeObject = function() {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
        if (o[this.name]) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
  }; 

  $(document).ready(function () {
    $('a[href^="tel"]').addClass("tel");
    getMobileOperatingSystem();
    LoadAttributesBasedOnDevice();
  });
    function LoadAttributesBasedOnDevice() {
    try {
        // 

        if ($("#hdnOs").val() == 'iOS') {


        }
        else if ($("#hdnOs").val() == 'Android') {

        }
        else {
            return;
        }
    } catch (err) {
        console.log('error');
    }
    }
    function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor 
     
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
        $("#hdnOs").val('iOS');

    }
    else if (userAgent.match(/Android/i)) {
        $("#hdnOs").val('Android');

    }
    else {
        $("#hdnOs").val('Unknown');
    }
    }

     //Pain medication table event start

     $('.addNewRowPainMedication').on('click',function() {
        $('#tbl-painMedication tbody').html('');
        component.PainMedicationList.push( { tabIndex:component.PainMedicationList.length,medication:'',dose:'',frequency:'',approxmity:'',currentlyTaking:'',goodRelief:'',moderatePainRelief:'',noPainRelief:'',sideEffectExperienced:''},);
        buildPainMedicationTable();
     });

  $(document).on('blur', '.fm-tab-input', function () {
      var id = $(this).attr('id');
      var index=id.substr(0, 1);
      var arrVal=id.substring(2);

      switch(arrVal){
          case 'medication':
              component.PainMedicationList[index].medication=$('#'+id).val();
              break;
          case 'dose':
              component.PainMedicationList[index].dose=$('#'+id).val();
              break;    
          case 'frequency':
              component.PainMedicationList[index].frequency=$('#'+id).val();
              break;   
          case 'approxmity':
              component.PainMedicationList[index].approxmity=$('#'+id).val();
              break; 
          case 'currentlyTaking':
              component.PainMedicationList[index].currentlyTaking=$('#'+id).val();
              break;  
          case 'goodRelief':
              component.PainMedicationList[index].goodRelief=$('#'+id).val();
              break;  
          case 'moderatePainRelief':
              component.PainMedicationList[index].moderatePainRelief=$('#'+id).val();
              break;  
          case 'noPainRelief':
              component.PainMedicationList[index].noPainRelief=$('#'+id).val();
              break;
          case 'sideEffectExperienced':
              component.PainMedicationList[index].sideEffectExperienced=$('#'+id).val();
              break; 
      }
      buildPainMedicationTable();
      
  });
  function buildPainMedicationTable(){
      $('#tbl-painMedication tbody').html('');
      var row='';
      for(var i=0;i<component.PainMedicationList.length;i++){
          row+='<tr>';
          
          row+='<td><input type="text" class="fm-tab-input" id='+i+'_medication value='+component.PainMedicationList[i].medication+'></td>';
          row+='<td><input type="text" class="fm-tab-input"  id='+i+'_dose value='+component.PainMedicationList[i].dose+'></td>';
          row+='<td><input type="text" class="fm-tab-input"  id='+i+'_frequency value='+component.PainMedicationList[i].frequency+'></td>';
          row+='<td><input type="text" class="fm-tab-input" id='+i+'_approxmity value='+component.PainMedicationList[i].approxmity+'></td>';
          row+='<td><input type="text" class="fm-tab-input" id='+i+'_currentlyTaking value='+component.PainMedicationList[i].currentlyTaking+'></td>';
          row+='<td><input type="text" class="fm-tab-input" id='+i+'_goodRelief value='+component.PainMedicationList[i].goodRelief+'></td>';
          row+='<td><input type="text" class="fm-tab-input" id='+i+'_moderatePainRelief value='+component.PainMedicationList[i].moderatePainRelief+'></td>';
          row+='<td><input type="text" class="fm-tab-input" id='+i+'_noPainRelief value='+component.PainMedicationList[i].noPainRelief+'></td>';
          row+='<td><input type="text" class="fm-tab-input" id='+i+'_sideEffectExperienced value='+component.PainMedicationList[i].sideEffectExperienced+'></td>'; 
          row+='</tr>';
      }                
     
      $('#tbl-painMedication tbody').append(row);
  }
  //Pain medication table event end

  //PAIN TREATMENT HISTORY start
  $(document).on('blur', '.fm-tab2-input', function () {
      var id = $(this).attr('id');
      var index=id.substr(0, 1);
      var arrVal=id.substring(2);
     
      switch(arrVal){
          case 'region':
              component.PainTreatmentHistoryList[index].region=$('#'+id).val();
              break;
          case 'proceduresLastYear':
              component.PainTreatmentHistoryList[index].proceduresLastYear=$('#'+id).val();
              break;    
          case 'dateOfLastProcedure':
              component.PainTreatmentHistoryList[index].dateOfLastProcedure=$('#'+id).val();
              break;   
          case 'amountOfPainRelief':
              component.PainTreatmentHistoryList[index].amountOfPainRelief=$('#'+id).val();
              break; 
          case 'sideEffect':
              component.PainTreatmentHistoryList[index].sideEffect=$('#'+id).val();
              break;  
      }
      buildTreatmentHistoryTable();
  });
  function buildTreatmentHistoryTable(){
      $('#tbl-treatment-history tbody').html('');
      var row='';
      $.each(component.PainTreatmentHistoryList,function(key,val){
          row+='<tr>';
        
          row+='<td><span class="fm-span-tab">'+val.treatmentType+'</span></td>';
          row+='<td><input type="text" class="fm-tab2-input" id='+val.tabIndex+'_region value='+val.region+'></td>';
          row+='<td><input type="text" class="fm-tab2-input" id='+val.tabIndex+'_proceduresLastYear value='+val.proceduresLastYear+'></td>';
          row+='<td><input type="date" class="fm-tab2-input" id='+val.tabIndex+'_dateOfLastProcedure value='+val.dateOfLastProcedure+'></td>';
          row+='<td><input type="text" class="fm-tab2-input" id='+val.tabIndex+'_amountOfPainRelief value='+val.amountOfPainRelief+'></td>';
          row+='<td><input type="text" class="fm-tab2-input" id='+val.tabIndex+'_sideEffect value='+val.sideEffect+'></td>';
          row+='</tr>';              
      });
      $('#tbl-treatment-history tbody').append(row);

  }
  //PAIN TREATMENT HISTORY end

  //Medication table start
  $('.addNewRowMedication').on('click',function(){

      $('#tbl-Medication tbody').html('');
      component.MedicationsList.push( { tabIndex:component.MedicationsList.length,medicationName:'',dosage:'',medcationfrequency:''});
      buildMedicationTable();            
  });
  function buildMedicationTable(){
      $('#tbl-Medication tbody').html('');
      var row='';
      $.each(component.MedicationsList,function(key,val){
          row+='<tr>';
          
          row+='<td><input type="text" class="fm-tab3-input" id='+val.tabIndex+'_medicationName value='+val.medicationName+'></td>';
          row+='<td><input type="text" class="fm-tab3-input" id='+val.tabIndex+'_dosage value='+val.dosage+'></td>';
          row+='<td><input type="text" class="fm-tab3-input" id='+val.tabIndex+'_medcationfrequency value='+val.medcationfrequency+'></td>';
          row+='</tr>';              
      });
      $('#tbl-Medication tbody').append(row);

  }
  $(document).on('blur', '.fm-tab3-input', function () {
      var id = $(this).attr('id');
      var index=id.substr(0, 1);
      var arrVal=id.substring(2);

      switch(arrVal){
          case 'medicationName':
              component.MedicationsList[index].medicationName=$('#'+id).val();
              break;
          case 'dosage':
              component.MedicationsList[index].dosage=$('#'+id).val();
              break;    
          case 'medcationfrequency':
              component.MedicationsList[index].medcationfrequency=$('#'+id).val();
              break;   
          
      }
      buildMedicationTable();
  });
  //Medication table end

  //Physician or specialist table start
  $(document).on('blur', '.fm-tab4-input', function () {
      var id = $(this).attr('id');
      var index=id.substr(0, 1);
      var arrVal=id.substring(2);

      switch(arrVal){
          case 'name':
              component.PhysiciansOrSpecialistList[index].name=$('#'+id).val();
              break;
          case 'painrelief':
              component.PhysiciansOrSpecialistList[index].painrelief=$('#'+id).val();
              break;    
          case 'treatment':
              component.PhysiciansOrSpecialistList[index].treatment=$('#'+id).val();
              break;   
          case 'lastinterventiondate':
              component.PhysiciansOrSpecialistList[index].lastinterventiondate=$('#'+id).val();
              break; 
          
      }
      buildPhysicalOrSpecialityTable();
  });
  function buildPhysicalOrSpecialityTable(){
      $('#tbl-PhysiciansOrSpecialist tbody').html('');
      var row='';
      $.each(component.PhysiciansOrSpecialistList,function(key,val){
          row+='<tr>';
          row+='<td><span class="fm-span-tab">'+val.speciality+'</span></td>';
          row+='<td><input type="text" class="fm-tab4-input" id='+val.tabIndex+'_name value='+val.name+'></td>';
          row+='<td><input type="text" class="fm-tab4-input" id='+val.tabIndex+'_treatment value='+val.treatment+'></td>';
          row+='<td><input type="text" class="fm-tab4-input" id='+val.tabIndex+'_painrelief value='+val.painrelief+'></td>';
          row+='<td><input type="date" class="date-picker fm-tab4-input" id='+val.tabIndex+'_lastinterventiondate value='+val.lastinterventiondate+'></td>';
          row+='</tr>';              
      });
      $('#tbl-PhysiciansOrSpecialist tbody').append(row);

  }
  //Physician or specialist table end

  //Tests table start
  $(document).on('blur', '.fm-tab5-input', function () {
      var id = $(this).attr('id');
      var index=id.substr(0, 1);
      var arrVal=id.substring(2);
      switch(arrVal){
          case 'region1':
              component.TestList[index].region1=$('#'+id).val();
              break;
          case 'recentdate1':
              component.TestList[index].recentdate1=$('#'+id).val();
              break;    
          case 'region2':
              component.TestList[index].region2=$('#'+id).val();
              break;   
          case 'recentdate2':
              component.TestList[index].recentdate2=$('#'+id).val();
              break; 
          
      }
      buildTestTable();
  });
  function buildTestTable(){

      $('#tbl-testlist tbody').html('');
      var row='';
      $.each(component.TestList,function(key,val){
          row+='<tr>';
          row+='<td><span class="fm-span-tab">'+val.investigation+'</span></td>';
          row+='<td><input type="text" class="fm-tab5-input" id='+val.tabIndex+'_region1 value='+val.region1+'></td>';
          row+='<td><input type="date" class="date-picker fm-tab5-input" id='+val.tabIndex+'_recentdate1 value='+val.recentdate1+'></td>';
          row+='<td><input type="text" class="fm-tab5-input" id='+val.tabIndex+'_region2 value='+val.region2+'></td>';
          row+='<td><input type="date" class="date-picker fm-tab5-input" id='+val.tabIndex+'_recentdate2 value='+val.recentdate2+'></td>';
          row+='<td><input type="file" id='+val.tabIndex+'_myfile1  class="fm-tab5-file" style="font-size: 11px;"></td>';
          row+='</tr>';              
      });
      $('#tbl-testlist tbody').append(row);

  }

  $(document).on('change', '.fm-tab5-file',function(){
  
      var crtl_id = $(this).attr('id');
      var index=crtl_id.substr(0, 1);
      component.files=[];
      var file = this.files[0];
      component.files.push(file);
      component.uploadFileToServer(crtl_id,'Table',index);
  });

  //Tests table end

  //Location table start
  $(document).on('change', '.fm-tab6-input', function () {
      var id = $(this).attr('id');
      var index=id.substr(0, 1);
      var arrVal=id.substring(2);

      switch(arrVal){
          case 'location':
              component.LocationList[index].location=$('#'+id).val();
              break;
          case 'painrightnow':
              component.LocationList[index].painrightnow=$('#'+id).val();
              break;    
          case 'thebestitgets':
              component.LocationList[index].thebestitgets=$('#'+id).val();
              break;   
          case 'theworstitgets':
              component.LocationList[index].theworstitgets=$('#'+id).val();
              break; 
          
      }
      component.buildLocationTable();
  });

  //Location table ent



  }
  buildLocationTable(){

    $('#tbl-locationlist tbody').html('');


    var row='';
    $.each(this.LocationList,function(key,val){
        row+='<tr>';
        row+='<td><span class="fm-span-tab">'+val.location+'</span></td>';
        row+='<td class="fm-tab6-row"><input class="fm-tab6-input" id='+val.tabIndex+'_painrightnow  max="10" min="1" step="1" type="range" value='+val.painrightnow+' onchange="this.nextElementSibling.innerText = this.value;"><span>'+val.painrightnow+'</span></td>';
        row+='<td class="fm-tab6-row"><input class="fm-tab6-input" id='+val.tabIndex+'_theworstitgets  max="10" min="1" step="1" type="range" value='+val.theworstitgets+' onchange="this.nextElementSibling.innerText = this.value;"><span>'+val.theworstitgets+'</span></td>';
        row+='<td class="fm-tab6-row"><input class="fm-tab6-input" id='+val.tabIndex+'_thebestitgets  max="10" min="1" step="1" type="range" value='+val.thebestitgets+' onchange="this.nextElementSibling.innerText = this.value;"><span>'+val.thebestitgets+'</span></td>';
        row+='</tr>';              
    });
    $('#tbl-locationlist tbody').append(row);
  
}

  searchQuestion(id){
    var answer = '';
    try {
        var forms = $.parseJSON($('#hdnForms').val());
        $.each(forms[0].SubmissionDetails, function (i, v) {
            if (v.QuestionFk == id) {
                answer = v.Answer;
                return;
            }
        });
    } catch (err) { }
    return answer;
  }
  searchQuestionOption(id) {
    var answer = "";
    try {
        var forms = $.parseJSON($('#hdnForms').val());
        $.each(forms[0].SubmissionDetails, function (i, v) {
            if (v.QuestionFk == id) {
                answer = v.QuestionOptionFk;
                return;
            }
        });
    } catch (err) { }
    return answer;
  }
  searchQuestionComments(id){
    var comments = '';
    try {
        var forms = $.parseJSON($('#hdnForms').val());
        $.each(forms[0].SubmissionDetails, function (i, v) {
            if (v.QuestionFk == id) {
                comments = v.Comments;
                return;
            }
        });
    } catch (err) { }
    return comments;
  }
  searchQuestionOptionValue(id,opid,opi) {
    var answer = "";
    try {
        var forms = $.parseJSON($('#hdnForms').val());
        $.each(forms[0].SubmissionDetails, function (i, v) {
             
            if (v.QuestionFk == id) {
                //var _selectedops=v.QuestionOptionFk.split(",");
                answer = v.Answer.split(",")[opi];
                //alert(answer);
                return;
            }
        });
    } catch (err) { }
    return answer;
  }

  nextButtonClicked(clickMode){


    // this.sectionNumber++;
    // this.loadQuestions();

    if(this.validateSection()){

        $('.fm-btn-prev').show();
        this.SaveQuestionnaire(clickMode);

    }

  }

  previousButtonClicked(){


    if(this.sectionNumber>1){

        $('.fm-btn-next-save').show();

        this.sectionNumber--;
        this.stepwiseAnswerarray1.pop();

        this.loadQuestions();

    }
    if(this.sectionNumber==1){
        $('.fm-btn-prev').hide();
    }

  }

  validateSection(){
    var isValid = true;
    var FirstInvalidField = "";
    
    var isval=true;

    for(var i=0;i<this.stepwiseAnswerarray1.length;i++)
    {

      for(var j=0;j<this.stepwiseAnswerarray1[i].ArrayList.length;j++){
        var item=this.stepwiseAnswerarray1[i].ArrayList[j].controlValue;
        var itemvalue=$('#'+item).val();

        if (typeof $('#'+item).attr("required") !== typeof undefined && $('#'+item).attr("required") !== false) {
                
                
          if( $('#'+item).attr("type")=="checkbox")
          {
              if($('#'+item).prop("checked") == undefined)
              {
                  isval=false;
                  if(FirstInvalidField==""){
                      FirstInvalidField=item;
  
  
                  }
              }
              else if(!$("input[name='"+item+"']:checked").val())
              {
                  isval=false;
                  if($('#'+item).prop("checked") == undefined)
                  {
                      isval=false;
                      if(FirstInvalidField==""){
                          FirstInvalidField=item;
                      }
                  }
              }
          }
          else if($('#'+item).attr("type")=="radio")
          {
              if (!$("input[name='"+item+"']:checked").val()) {
                  isval=false;
                  if($('#'+item).prop("checked") == undefined)
                  {
                      isval=false;
                      if(FirstInvalidField==""){
                          FirstInvalidField=item;
                      }
                  }
               }
  
          
          }
  
          else {
              if(itemvalue==null || itemvalue=="")
              {
                  isval=false;
                  if(FirstInvalidField==""){
                      FirstInvalidField=item;
                  }
              }    
          }
        }

      }



    }

    if(isval==false)
    {
        this.openSnackBar('Please fill required fields','');
        isValid=false;
    }
    if(this.sectionNumber==2){
        
        if($('#imgMapper1').text()=="Open Image mapper"){
            
            this.openSnackBar('Please select chief complaint from image mapper','');
            isValid=false;
        }
    }
    if(this.sectionNumber==8){

        var valueExist=false;
        for(var i=0;i<this.PainMedicationList.length;i++){
            if(this.PainMedicationList[i].medication!=''){
                valueExist=true;
                break;
            }
        }
        if(valueExist){
            isValid=true;
        }
        else{
            this.openSnackBar('Please fill medications list','Required');
            isValid=false;
        }
    }
    if (!isValid) {

        
        if(FirstInvalidField!=''){
            $('#' + FirstInvalidField).focus();
        }
        
    }
     
    return isValid;

  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  ImageID:any;

  chiefComplaintPainSpotArray:any[]=[];

  openDialog(id): void {

    this.ImageID=id;
    const dialogRef = this.dialog.open(ImageMapperComponent, {
      width: '800px',
      data:id,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {

      var ImagesMarked='';
      var ImageMarkedArray=[];
      ImageMarkedArray=JSON.parse(localStorage.getItem(this.ImageID));

      

      if(ImageMarkedArray!=null){
      //if neck selected
      switch(this.ImageID){

        case 'imgMapper1':
            this.chiefComplaintPainSpotArray=[];
            $(".fm-multi-select > option").each(function(){

                var _id='multiselect'+$(this).attr('data-optionid');
                $('#'+_id).addClass('hiddenClass2');
            });
            
            this.chiefComplaintPainSpotArray.push(...ImageMarkedArray);

            for(var i=0;i<ImageMarkedArray.length;i++){

                switch(ImageMarkedArray[i].description){
                    
                    case 'NECK':
                        $(".fm-multi-select > option").each(function(){
                        var _id='multiselect'+$(this).attr('data-optionid');
                            switch(this.value){
    
                            case 'Headache':
                                $('#'+_id).removeClass('hiddenClass2');
                                break;
                            case 'Hand':
                                $('#'+_id).removeClass('hiddenClass2');
                                break;
                            case 'Upper back':
                                $('#'+_id).removeClass('hiddenClass2');
                                break;  
                            }
                        });

                     
                        break;
                    case 'Mid Back':

                        $(".fm-multi-select > option").each(function(){
                            var _id='multiselect'+$(this).attr('data-optionid');

                            switch(this.value){
        
                                case 'Groin':
                                    $('#'+_id).removeClass('hiddenClass2');
                                    break;
                                case 'Foot':
                                    $('#'+_id).removeClass('hiddenClass2');
                                    break;
                                case 'Genitals':
                                    $('#'+_id).removeClass('hiddenClass2');
                                    break;  
                                case 'Front of chest':
                                    $('#'+_id).removeClass('hiddenClass2');
                                    break; 
                            }
                        });
                        break;

                    case 'Lower Back':

                        $(".fm-multi-select > option").each(function(){
                                var _id='multiselect'+$(this).attr('data-optionid');
    
                                switch(this.value){
            
                                    case 'Thighs':
                                        $('#'+_id).removeClass('hiddenClass2');
                                        break;
                                    case 'Arms':
                                        $('#'+_id).removeClass('hiddenClass2');
                                        break;
                                    case 'Buttocks':
                                        $('#'+_id).removeClass('hiddenClass2');
                                        break;  
                                    case 'Legs':
                                        $('#'+_id).removeClass('hiddenClass2');
                                        break; 
                                    case 'Shoulders':
                                        $('#'+_id).removeClass('hiddenClass2');
                                        break;  
                                    case 'Forearms':
                                        $('#'+_id).removeClass('hiddenClass2');
                                        break; 
                                }
                            });
                    break;        
                }

            }

      }

      for(var i=0;i<ImageMarkedArray.length;i++){
        ImagesMarked+= i+1+').'+ ImageMarkedArray[i].description +'.  ';
      }
      $('#'+this.ImageID).text(ImagesMarked);
      }
      else{
        $('#'+this.ImageID).text('Open Image mapper');
      }
      

    });
  }
  openConfirmation():void{
    const dialogRef1 = this.dialog.open(ConfirmQuestionComponent, {
        width: '800px',
        
        disableClose: true
      });
     

  }
  files: File[] = [];
  uploadFileToServer(crtl_id,Type,Index){
 
  const formData = new FormData(); 
  for(var i=0;i<this.files.length;i++){
    formData.append("file[]",this.files[i]);
  }
  // this.mySelfProgress=50;
  formData.append('UserID',this.UserID);  
    
    this.loader=true;
    this.service.UploadDocuments(formData).subscribe(
      (response:any) => {                           //Next callback
          this.loader=false;
 
        if (response !== null) {
         
          if(response.status==1){
            if(Type=='Table'){
                this.TestList[Index].fileName=response.documentPath[0].uri;
            }
            else{
              $('#'+crtl_id).text(response.documentPath[0].uri);
            }
              
          }
        }
      },
      (error) => {                              //Error callback
        
          this.loader=false;
      }
  );

}

SaveQuestionnaire(clickMode){

  $('#hdnIsSaved').val(false);
  if(this.checkreqpro())
  {
    var formData=$('#hdnForms').val();

    if(formData==''){
          
        this.sectionNumber++;
        this.loadQuestions();
        return;
    }
    formData=formData.replace(",,", ",");
    

    var substr = formData.substring(formData.length-5, formData.length);
    if (substr.indexOf(',') > -1)
    {

        formData = formData.substring(0, formData.length-4);
        formData+="]}]";
    }
    else{
       
    }

    var forms = $.parseJSON(formData);

    var imgMapper228=JSON.parse(localStorage.getItem('imgMapper1')); 
    var imgMapper230=JSON.parse(localStorage.getItem('imgMapper2')); 
    var imgMapper231=JSON.parse(localStorage.getItem('imgMapper3')); 

    var spotsArray=[];
     $.each(imgMapper228,function(key,val){
        spotsArray.push({enabled:val.enabled,
        front:val.front,
        pos_X:val.pos_X,
        pos_Y:val.pos_Y,
        questionID:val.QuestionFK,
        description:val.description
    })
    });

    $.each(imgMapper230,function(key,val){
        spotsArray.push({enabled:val.enabled,
        front:val.front,
        pos_X:val.pos_X,
        pos_Y:val.pos_Y,
        questionID:val.QuestionFK,
        description:val.description
    })
    });
    $.each(imgMapper231,function(key,val){
        spotsArray.push({enabled:val.enabled,
        front:val.front,
        pos_X:val.pos_X,
        pos_Y:val.pos_Y,
        questionID:val.QuestionFK,
        description:val.description
        })
    });


    
    var answerArray=[];
    for(var i=0;i<forms[0].SubmissionDetails.length;i++){

        if(forms[0].SubmissionDetails[i].SectionNumber==this.sectionNumber){
            answerArray.push(forms[0].SubmissionDetails[i]);
        }
        
    }

    const body = {
        UserID:this.UserID,
        SectionNumber:this.sectionNumber,
        FormID:forms[0].FormID,
        FormSubmitted:answerArray,
        PainMedicationList:this.PainMedicationList,
        PainTreatmentHistoryList:this.PainTreatmentHistoryList,
        MedicationsList:this.MedicationsList,
        PhysiciansConsultedList:this.PhysiciansOrSpecialistList,
        TestInvestigationList:this.TestList,
        PainLocationList:this.LocationList,
        PainSpotList:spotsArray
      };

      this.loader=true;

      this.service.SaveQuestionnaire(body).subscribe(
        (response:any) => { 

          if(response.status){
            this.loader=false;

            if(clickMode=='next'){
                
                this.sectionNumber++;
                this.loadQuestions();
            }
            else if(clickMode=='exit'){
                if(this.isMobileWindow){
                    this.router.navigate(['thanks-mob']);
                  }
                  else{
                    this.router.navigate(['/secondopinion/thanks']);
                  }
            }

          }
          else{
         
            this.loader=false;
          }
          
          
      },
      (error=>{
        this.loader=false;
      })
  
      )

    //   localStorage.setItem('Q_FormToSubmit',JSON.stringify(body));
    //   this.openConfirmation();
  }
}
checkreqpro() {
  var retval = true;
  var forms = '';

  if ($('.question-form-control').length > 0) {
      forms = '[{';
      $('.question-form-control').each(function (index, data) {
          var controlvalue = "";
          var QuestionOptionFk=0;
          var selected=[];
          var _form='';
          var questionid = $(this).data('questionid');
          var formFK = $(this).data('formfk');
          var include=false;

          
          if($(this).parent().parent().parent().parent().hasClass('childbranch'))
          {
              if($(this).parent().parent().parent().parent().hasClass('selected'))
              {
                  include=true;
              }   
          }
          else {
              include=true;
          }
          if(include)
          {
              if( $(this).attr("type")=="checkbox")
              {
                  if($(this).prop("checked") != undefined)
                  {
                      if($(this).prop("checked")==true)
                      {
                          controlvalue="Yes";
                      }
                      else {
                          controlvalue="No";
                      }
                  }
                  QuestionOptionFk=parseInt($(this).data("optionid"));
              }
              else if( $(this).attr("type")=="radio")
              {
                  if($(this).is(':checked'))
                  {
                      controlvalue= $(this).val();
                      QuestionOptionFk=parseInt($(this).data("optionid"));
                  }
              }
              else if( this.tagName.toLowerCase()=="select")
              {
                  //var optionselected=$("#"+ctrlid+" option:selected")
                  if($(this).attr("multiple")=="multiple")
                  {
                      // var _selectedindex=-1;
                      $('option:selected',this).each(function(_selectedindex,opt){
                          selected[$(this).val()]=$(this).text();
                          _form += '{';
                          _form += '"Answer": "' + $(this).text() + '",';
                          _form += '"QuestionFk": ' + questionid + ',';
                          _form += '"QuestionOptionFk": ' + $(this).data('optionid') + ',';
                          _form += '"SectionNumber": ' +$('#crtlcmt_' + questionid).val() + '';
                          _form += '}';
                          if ($('option:selected',this).length - 1 != _selectedindex) {
                              _form += ',';
                          }

               
                      });
                  }
                  else {
                      controlvalue= $(this).val();
                      if($('option:selected',this).data('optionid')!=undefined)
                      {
                          QuestionOptionFk=parseInt($('option:selected',this).data('optionid'));
                      }
                  }
              
              }
              else if($(this).attr("type")=="file"){

                  var id=$(this).attr("id");
                  controlvalue=$('#'+id).text()
              }
              else
              {
                  controlvalue= $(this).val();
              }
              if (index == 0) {
                  forms += '"FormID": ' + formFK + ',';
                  forms += '"SubmissionDetails": [';
              }
               
              if(controlvalue!='')
              {
                  forms += '{';
                  forms += '"Answer": "' + controlvalue + '",';
                  forms += '"QuestionFk": ' + questionid + ',';
                  if(QuestionOptionFk>0)
                  {
                      forms += '"QuestionOptionFk": ' + QuestionOptionFk + ',';
                  }
                  forms += '"SectionNumber": ' + $('#crtlcmt_' + questionid).val()  + '';
                  forms += '}';
                  
   
                  if ($('.question-form-control').length - 1 != index) {
                      forms += ',';
                  }
              }
              else if(_form!='')
              {   
                  forms+=_form;                     
                  if ($('.question-form-control').length - 1 != index) {
                      forms += ',';
                  }
              }
          }
      });
      forms += ']}]';
     
      $('#hdnForms').val(forms);
      forms = '';
  }
   
  return retval;
}

FormatDate(value: string) {
    var datePipe = new DatePipe("en-US");
     value = datePipe.transform(value, 'dd/MM/yyyy');
     return value;
  }
  detectMobile(){
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i];

      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
 
   }
    closeButtonClicked(){
        this.router.navigate(['thanks-mob']);
        return;
    }
}


export interface StepperArray{

  controlValue:string;
}
export interface StepperObject{

  SectionNumber:number;
  ArrayList:StepperArray[];
}
