import { Routes } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';

import { PatientAuthGurad } from './patient-authguard';
import { DoctorAuthGurad } from './doctor-authguard';

import { UserProfileComponent } from '../user-profile/user-profile.component';
import { AddDependentComponent } from '../add-dependent/add-dependent.component';
import { DocumentUploadComponent} from '../document-upload/document-upload.component';
import { ProfileComponent} from '../profile/profile.component';
import { AddAppointmentComponent } from '../add-appointment/add-appointment.component';
import { MyDocumentsComponent } from '../my-documents/my-documents.component';
import { BookingHistoryComponent } from '../booking-history/booking-history.component';
import { DependencyCircleComponent } from '../dependency-circle/dependency-circle.component';
import { ThanksComponent } from '../common/thanks/thanks.component';

import { ViewPatientDocumentsComponent } from '../Doctor/view-patient-documents/view-patient-documents.component';
import { DoctorDashboardComponent } from '../Doctor/doctor-dashboard/doctor-dashboard.component';
import { PatientListComponent } from '../Doctor/patient-list/patient-list.component';
import { AddTimesloteComponent } from '../Doctor/add-timeslote/add-timeslote.component';
import { TodayAppointmentComponent } from '../Doctor/today-appointment/today-appointment.component';
import { NewAppointmentComponent } from '../Doctor/new-appointment/new-appointment.component';
import { RequestHistoryComponent } from '../Doctor/request-history/request-history.component';

export const SECURE_ROUTES: Routes = [

// Patient
  { path: 'dashboard', component: DashboardComponent, canActivate: [PatientAuthGurad] },//, canActivate: [AdminAuthGuard]
  { path: 'user-profile',   component: UserProfileComponent,canActivate: [PatientAuthGurad]}, 
  { path: 'add_dependent',   component: AddDependentComponent,canActivate: [PatientAuthGurad]},
  { path: 'upload_document',   component: DocumentUploadComponent,canActivate: [PatientAuthGurad]},
  { path: 'profile',   component: ProfileComponent,canActivate: [PatientAuthGurad]},
  { path: 'appointment',   component: AddAppointmentComponent,canActivate: [PatientAuthGurad]},
  { path: 'mydocuments',   component: MyDocumentsComponent,canActivate: [PatientAuthGurad]},
  { path: 'bookinghistory',   component: BookingHistoryComponent,canActivate: [PatientAuthGurad]},
  { path: 'dependencycircle',   component: DependencyCircleComponent,canActivate: [PatientAuthGurad]},
  { path: 'thanks',   component: ThanksComponent,canActivate: [PatientAuthGurad]},

  //Doctor
  { path: 'viewdicom',   component: ViewPatientDocumentsComponent,canActivate: [DoctorAuthGurad]},
  { path: '_dashboard',   component: DoctorDashboardComponent,canActivate: [DoctorAuthGurad]},
  { path: '_patientlist',   component: PatientListComponent,canActivate: [DoctorAuthGurad]},
  { path: '_addtimeslote',   component: AddTimesloteComponent,canActivate: [DoctorAuthGurad]},
  { path: '_todayappointment',   component: TodayAppointmentComponent,canActivate: [DoctorAuthGurad]},
  { path: '_newappointment',   component: NewAppointmentComponent,canActivate: [DoctorAuthGurad]},
  { path: '_requesthistory',   component: RequestHistoryComponent,canActivate: [DoctorAuthGurad]},
];
