import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  baseUrl: any;
  constructor(private http: HttpClient) { 
    this.baseUrl = environment.url;
  }

  GetUserProfile(id) {
    return this.http.get(this.baseUrl + `api/user/get_user_profile?UserID=`+id);
  }
  UpdateProfile(data: any) {

    return this.http.post(this.baseUrl+'api/User/update_user',data).pipe(
      catchError(errorRes=>{
        
        const errorMessage='An Error Occured';
        if (!errorRes.error || !errorRes.error.error) {
          
          return throwError(errorMessage);

        }
      }),
    );
   
  }

}
