import { Component, OnInit } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';


@Component({
    selector: 'app-test-component',
    templateUrl: './test-component.component.html',
    styleUrls: ['./test-component.component.css'],
  })
  export class TestComponentComponent implements OnInit {

    public payPalConfig?: IPayPalConfig;


    ngOnInit(): void {
        this.initConfig();
    }
    private initConfig(): void {
        this.payPalConfig = {
        currency: 'INR',
        clientId: 'Ae6lXv8L4mIlO0PD1rPl47-ST5lA_W7-BP6t3EqgUHlsv5czmdqqIrPwYfD0BHnYW3hx8LJFsgguaPpS',
        createOrderOnClient: (data) => <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'INR',
                value: '9.99',
                breakdown: {
                  item_total: {
                    currency_code: 'INR',
                    value: '9.99'
                  }
                }
              },
              items: [
                {
                  name: 'Enterprise Subscription',
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'INR',
                    value: '9.99',
                  },
                }
              ]
            }
          ]
        },
        advanced: {
          commit: 'true'
        },
        style: {
          label: 'paypal',
          layout: 'vertical'
        },
        onApprove: (data, actions) => {
          console.log('onApprove - transaction was approved, but not authorized', data, actions);
          actions.order.get().then(details => {
            console.log('onApprove - you can get full order details inside onApprove: ', details);
          });
        },
        onClientAuthorization: (data) => {
          console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        //   this.showSuccess = true;
        },
        onCancel: (data, actions) => {
          console.log('OnCancel', data, actions);
        },
        onError: err => {
          console.log('OnError', err);
        },
        onClick: (data, actions) => {
          console.log('onClick', data, actions);
        },
      };
      }

}