// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //  url:'http://localhost:51611/',
  //  url:'http://94-237-73-65.sg-sin1.upcloud.host:96/',
   url:'https://secondopinion.calmoveindia.com/',
  //  paypalClientId:'Ae6lXv8L4mIlO0PD1rPl47-ST5lA_W7-BP6t3EqgUHlsv5czmdqqIrPwYfD0BHnYW3hx8LJFsgguaPpS',

  //staging
   paypalClientId:'AWnuagGj31UCpRvgklv3zLqopfbw2x5elH6IO3EQv9KMJyd3DD9ltv--EuXxTWH1siXfH76EwgdmhjF0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
